import React, {useEffect, useState} from 'react';
import Immutable from 'immutable';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router';
import {ConfirmButton, BackButton, ImageUpload, LogoSegment} from './ui/';

import {
    Segment, Header, Grid, Image, Icon, Button, Input, Form, Loader, Item,
    Container, Visibility, Message, Transition, Dimmer, Modal, TextArea,
    Placeholder,
} from 'semantic-ui-react';

import {
    isAuthed, getSearchGroups, getGroupComments, getGroup, getNewGroup,
    getAuthUserId, isMod, getAuth,
} from '../selectors';

import {
    isPresent, isDefined, formatDate, lifetime, useForceUpdate,
} from '../helpers';

import {
    searchGroups, searchGroupsClear, fetchGroup, fetchNextGroupComments,
    clearGroupComments, saveGroup, redirectTo, clearNewGroup, sendGroupComment,
    deleteGroupComment, deleteGroupCommentError, deleteGroup,
} from '../actions';

const Placeholding = ({active, header=false, image=false, lines=0, children}) => {
    let item;
    const lineItems = [];
    for(let li=0; li<lines; li++){
        lineItems.push(<Placeholder.Line key={li}/>);
    }
    if(header){
        item = <Placeholder.Header image={image}>{lineItems}</Placeholder.Header>;
    } else {
        item = <Placeholder.Paragraph>{lineItems}</Placeholder.Paragraph>;
    }

    return active ? (
        <Placeholder>
            {item}
        </Placeholder>
    ) : <>{children}</>;
};

const NavigationRow = ({groupSlug, group}) => {
    const dispatch = useDispatch();
    const userId = useSelector(getAuthUserId);
    const userIsMod = useSelector(isMod);
    const canAct = isDefined(group) && (userIsMod || group.getIn(["author", "slug"]) === userId);
    return (
        <Grid.Row className="navigation" columns="equal">
            <Grid.Column>
                <BackButton to={'/groups'} />
            </Grid.Column>
            {canAct?<Grid.Column textAlign="right">
                <ConfirmButton icon="trash" basic color="red" title="Löschen"
                    onClick={(e)=>groupSlug && dispatch(deleteGroup(groupSlug))}
                />
                {false?<Button icon="edit" basic color="brown" title="Ändern"/>:null}
            </Grid.Column>: null}
        </Grid.Row>
    );
};

const GroupItem = ({title, slug, published}) => {
    return (
        <Item>
            <Item.Content>
                <Item.Header as={NavLink} to={`/groups/${slug}`}>
                    {(!published) ?
                        <Icon name="protect"
                            title="Muss von Moderatoren freigegeben werden. Nur du kannst die Gruppe sehen."
                        /> : null
                    }{title}
                </Item.Header>
            </Item.Content>
        </Item>
    );
};

const ResultList = ({result, q}) => {
    return (
        <Segment className="transparent">
            {isPresent(q) ? (
                <Header as="h1" className="cuddle profile" textAlign="center">
                    <Header.Subheader>
                        Deine Suchergebnisse für:
                    </Header.Subheader>
                    {`„${q}“`}
                </Header>
            ) : null }
            <Item.Group className="group-list">
                {result.isEmpty() ? (
                    <div className="cd_container">
                        <div className="cd_text cd_center">
                            {isPresent(q) ?
                                `Zum Suchbegriff „${q}“ gab es leider keine Ergebnisse.` :
                                "Es existieren noch keine Gruppen. Sei die erste \
                                 Person, die eine Gruppe eröffnet!"
                            }
                        </div>
                    </div>
                ) : (
                    result.map(g => (
                        <GroupItem
                            slug={g.get('slug')}
                            title={g.get('title')}
                            key={g.get('slug')}
                            published={g.get('published') || false}
                        />
                    ))
                )}
            </Item.Group>
        </Segment>
    );
};

const GroupsIndex = ({}) => {
    const [searchValue, setSearchValue] = useState("");
    const [query, setQuery] = useState(null);
    const dispatch = useDispatch();
    const searchResults = useSelector(getSearchGroups);
    const hasResults = searchResults.get('loaded') && !searchResults.get('result').isEmpty();
    const hasError = searchResults.get('loaded') && isPresent(searchResults.get('error'));
    const isLoading = searchResults.get('loading') || false;
    useEffect(() => {
        //dispatch(searchGroupsClear());
    }, []);
    useEffect(() => {
        dispatch(searchGroups(isPresent(query) ? query : null));
    }, [query]);
    return (
        <div>
            <Segment className="transparent">
                <div className="cd-container">
                    <div className="cd-block cd-center cd-darker">
                        <div className="cd-text">
                            <h1>
                                Wohnzimmer
                            </h1>

                            <p>
                                Willkommen in unserem Wohnzimmer. Hier kannst du
                                private Kuschelgruppen anbieten. Entweder bei dir
                                zu Hause, im Freien, oder ihr geht gemeinsam zu
                                einer Kuschelparty.
                            </p>
                            <p>
                                Wie könnte so ein Gesuch aussehen? Zum Beispiel so:
                            </p>
                            <p>
                                „<b>Betreff: Kuschelige Filmgruppe in XY</b>
                            </p>
                            <p>
                                Hallo! ich suche für dieses Wochenende (Datum) 5 Menschen,
                                die es sich bei mir zu Hause (Ort) gemütlich machen.
                                Vorhanden ist eine große Couch, auf der 6 Menschen Platz
                                haben, Beamer zum Filmschauen, Chips. Gern könnt
                                ihr mitbringen: Eigenes Essen, gute Laune, kuschelige
                                Kleidung. Ort: (Adresse). Bitte schreibt mich hier
                                über den Chat an.“
                            </p>
                            <p>
                                Diese Seite ist wie ein klassisches Forum organisiert.
                                <br />
                                Achtung, das hier ist nicht der Platz für gewerbliche
                                Veranstaltungen.
                            </p>
                        </div>
                    </div>
                    <div className="cd-block cd-center cd-darker attached">
                        <div>
                            <Button as={NavLink} to="/groups/_new" className="cuddle">
                                Kuschelgruppe erstellen
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="search-action-container">
                    <div className="search-pre-text">
                        Suche hier nach Schlagwörtern, wie zum Beispiel
                        „Leipzig“, „Film“, „Draußen“, die dir helfen
                        deine Kuschelgruppe zu finden.
                    </div>
                    <Form onSubmit={(ev)=>setQuery(searchValue)}>
                        <Input className="search group-search cuddle" fluid icon={
                            <Icon name='search' inverted circular link
                                onClick={(ev) => setQuery(searchValue)}
                            />
                            }
                            name="query"
                            onChange={(ev, data) => setSearchValue(data.value)}
                            value={searchValue}
                        />
                    </Form>
                </div>
            </Segment>
            <Segment className="transparent">
                {hasError ? (
                    <Message negative>
                        {searchResults.get('error')}
                    </Message>
                ): null}
                <ResultList result={searchResults.get('result')}
                    q={query}
                />
                <Dimmer active={isLoading} inverted>
                    <Loader active inline='centered' size='large'/>
                </Dimmer>
            </Segment>
        </div>
    );
};

const CommentActions = ({slug, groupSlug, data}) => {
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const userId = useSelector(getAuthUserId);
    const userIsMod = useSelector(isMod);
    const timeAlive = lifetime(data.get('created_at'));
    useEffect(() => {
        let timer;
        if(timeAlive < 300) {
            timer = setTimeout(() => {
                forceUpdate();
            }, (300 - timeAlive) * 1000);
        }
        return () => {
            if(timer !== undefined) {
                clearTimeout(timer);
            }
        };
    }, [timeAlive]);
    if((timeAlive < 300 && data.getIn(["author", "slug"]) !== userId) ||
       (timeAlive >= 300 && !userIsMod)) {
        return null;
    }
    return (
        <Grid.Row>
            <Grid.Column textAlign="right" width={16}>
                {false?<Button basic color="brown" compact size="mini">
                    <Icon name="edit" /> Ändern
                </Button>:null}
                <ConfirmButton basic color="red" compact size="mini"
                    onClick={() => dispatch(deleteGroupComment(groupSlug, slug))}
                    title={timeAlive < 300 ?
                        "Kommentar kann innerhalb von 5 Minuten gelöscht werden" :
                        "[Moderation] Kommentar als gelöscht vermerken"
                    }
                >
                    <Icon name="trash" /> Löschen
                </ConfirmButton>
            </Grid.Column>
        </Grid.Row>
    );
};

const GroupComment = ({groupSlug, data}) => {
    const dispatch = useDispatch();
    if(data.get('deleted', false)) {
        return (
            <Transition transitionOnMount animation="browse">
                <Grid stackable className="profile-comment deleted" width={1}>
                    <Grid.Column>
                        Das Kommentar wurde von Moderatoren gelöscht.
                    </Grid.Column>
                </Grid>
            </Transition>
        );
    }
    const isLoading = data.get('loading') || false;
    const errorMsg = data.get('error');
    const slug = data.get('slug');
    return (
        <Transition transitionOnMount animation="browse">
            <Grid stackable className="profile-comment">
                <Dimmer active={isLoading || isPresent(errorMsg)}>
                    {isPresent(errorMsg) ? <span className="ui inverted red text">
                        {errorMsg} <Button icon="close" color="red" onClick={() =>
                            dispatch(deleteGroupCommentError(groupSlug, slug, null))
                        } size="mini" className="tertiary" />
                    </span> : <Loader />}
                </Dimmer>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <div className="comment-image">
                            <NavLink to={`/profile/${data.getIn(['author', 'slug'])}`}>
                                <Image circular
                                    src={data.getIn(['author', 'profile_image_thumb_url'])}
                                />
                            </NavLink>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={13} className="comment-header">
                        <div className="comment-name">
                            <NavLink to={`/profile/${data.getIn(['author', 'slug'])}`}>
                                {data.getIn(['author', 'nickname'])}
                            </NavLink>
                        </div>
                        <div className="comment-date">{formatDate(data.get('created_at'))}</div>
                        <div className="comment-location">{data.getIn(['author', 'location'])}</div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3} className="offset-column">
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <div className="comment-text">
                            {data.get('body')}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <CommentActions slug={slug} groupSlug={groupSlug} data={data}/>
            </Grid>
        </Transition>
    );
};

const CommentError = ({slug}) => {
    const response = useSelector(state => getGroupComments(state, slug));
    const errorMsg = isDefined(response) && response.get('error');
    if (!isPresent(errorMsg)) return null;
    return(
        <Message negative>
            {errorMsg}
        </Message>
    );
};

const CommentLoader = ({slug, onNewComment}) => {
    const dispatch = useDispatch();
    const response = useSelector(state => getGroupComments(state, slug));
    if (!isPresent(slug)) return null;
    const isLoading = response.get('loading', false);
    const hasMore = response.get('hasMore', true);
    const firstLoad = !response.has('hasMore');
    const showLoadNext = !isLoading && hasMore;
    const hasComments = response.get('result', Immutable.List()).size > 0;

    return (<div className="comment-loader">
        <Loader active={isLoading} inline size="large" />
        {showLoadNext ? (firstLoad ? (
            <Visibility once={true}
                onOnScreen={(a,b) => dispatch(fetchNextGroupComments(slug))}
            />
        ) : (
            <Button className="cuddle" onClick={
                (a,b) => dispatch(fetchNextGroupComments(slug))
            }>Mehr laden</Button>
        )) : ((!hasMore && !hasComments) ? (
            <div className="empty-comments">
                Es gibt hier noch keine Kommentare.
                <Button className="cuddle" onClick={onNewComment}>
                  Kommentar Schreiben
                </Button>
            </div>
        ) : null)}
    </div>);
};

const GroupComments = ({groupSlug, onNewComment}) => {
    const response = useSelector(state => getGroupComments(state, groupSlug));
    const comments = (response && response.get('result')) || Immutable.List();
    return (
        <Segment className="transparent profile-comments-container">
            <Header as="h2" textAlign="center" className="cuddle">
                Kommentare
            </Header>
            <Container className="profile-comments">
                {comments.map(comment =>
                    <GroupComment data={comment} key={comment.get('slug')}
                        groupSlug={groupSlug}
                    />
                )}
            </Container>
            <CommentLoader slug={groupSlug} onNewComment={onNewComment}/>
            <CommentError slug={groupSlug}/>
        </Segment>
    );
};

const DEFAULT_NEW_COMMENT = Immutable.fromJS({
    body: "",
});

const CommentDialog = ({groupSlug, open, onClose}) => {
    const [comment, setComment] = useState(DEFAULT_NEW_COMMENT);
    const [sending, setSending] = useState(false);
    const comments = useSelector(state => getGroupComments(state, groupSlug));
    useEffect(() => {
        if(open){
            setComment(DEFAULT_NEW_COMMENT);
            setSending(false);
        }
    }, [open]);
    useEffect(() => {
        if(open && isDefined(comments)) {
            if(comments.get('loading') && !sending) {
                setSending(true);
            } else if(!comments.get('loading') && sending &&
                !isPresent(comments.get('sendError'))) {
                onClose();
            }
        }
    }, [comments, open, sending]);
    const dispatch = useDispatch();
    const isLoading = isDefined(comments) && comments.get('loading', false);
    const errorMsg = isDefined(comments) && comments.get('sendError');
    return (
        <Modal open={open} onClose={onClose}>
            <Header>Kommentar schreiben</Header>
            <Modal.Content>
                <div className="commenting-section">
                    <Form id="comment-form"
                        onSubmit={(e) => {
                            dispatch(sendGroupComment(groupSlug, comment));
                            e.preventDefault();
                        }}
                    >
                        <Dimmer active={sending} inverted>
                            {isLoading ? (<Loader size="large" />) : (
                                isPresent(errorMsg) ? (
                                    <span className="ui inverted red text">
                                        {errorMsg}
                                    </span>
                                ) : null
                            )}
                        </Dimmer>
                        <div className="comment-input">
                            <TextArea value={comment.get('body')} required
                                autoFocus
                                onChange={(e, data) =>
                                    setComment(comment.set('body', data.value))
                                }
                            />
                        </div>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>
                    Abbrechen
                </Button>
                <Button className="cuddle" form="comment-form" type="submit">
                    Senden
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const GroupShow = ({slug}) => {
    const [commenting, setCommenting] = useState(false);
    const dispatch = useDispatch();
    const group = useSelector((state) => getGroup(state, slug));
    useEffect(() => {
        dispatch(fetchGroup(slug));
        return () => {
            dispatch(clearGroupComments(slug));
        };
    }, [slug]);
    const isLoading = group.get('loading') || false;
    const imageStyle = isLoading ? {
        backgroundColor: 'gray',
    } : (isPresent(group.get('image_url')) ? {
        backgroundImage: `url("${group.get('image_url')}")`,
    } : {
        backgroundImage: `url("/images/main_bg.jpg")`,
    });
    const userSlug = group.getIn(['author', 'slug']);
    return (
        <div>
            <Segment className="cuddle_light">
                <>
                    <Grid className="group-pane">
                        <NavigationRow groupSlug={slug} group={group}/>
                        <Grid.Row>
                            <Grid.Column computer={6} tablet={16}>
                                <div className="group-image" style={imageStyle} />
                            </Grid.Column>
                            <Grid.Column computer={10} tablet={16}>
                                <Grid columns={1} className="group-fields">
                                    <Grid.Row>
                                        <Grid.Column className="group-title">
                                            <Placeholding active={isLoading} lines={1} header>
                                                {(group.get("published") === false) ?
                                                    <Icon name="protect"
                                                        title="Muss von Moderatoren freigegeben werden. Nur du kannst die Gruppe sehen."
                                                    /> : null
                                                }
                                                {group.get('title')}
                                                {(group.get("published") === false) ?
                                                    <div className="subtitle">
                                                        Freigabe ausstehend
                                                    </div>
                                                : null}
                                            </Placeholding>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className="group-organizer">
                                            <Placeholding active={isLoading} lines={1}>
                                                <div className="label">Veranstalter</div>
                                                <NavLink to={`/profile/${userSlug}`}>
                                                    {group.getIn(['author', "nickname"], "Unbekannt")}
                                                </NavLink>
                                            </Placeholding>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className="group-location">
                                            <Placeholding active={isLoading} lines={1}>
                                                <div className="label">Ort</div>
                                                {group.get('location')}
                                            </Placeholding>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className="group-date">
                                            <Placeholding active={isLoading} lines={1}>
                                                <div className="label">Zeit</div>
                                                {group.get('date')}
                                            </Placeholding>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className="group-description">
                                            <Placeholding active={isLoading} lines={5}>
                                                {group.get('description')}
                                            </Placeholding>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div className="group-comment-button">
                                                <Button className="group-big" size="large"
                                                    onClick={() => setCommenting(true)}
                                                >
                                                    Kommentar Schreiben
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                        <CommentDialog open={commenting} groupSlug={slug}
                            onClose={() => setCommenting(false)}
                        />
                    </Grid>
                    <GroupComments groupSlug={slug}
                        onNewComment={() => setCommenting(true)}
                    />
                </>
            </Segment>
        </div>
    );
};

const GroupNew = ({}) => {
    const dispatch = useDispatch();
    const [group, setGroup] = useState(Immutable.fromJS({
        title: "",
        description: "",
        location: "",
        date: "",
        image: "",
        image_url: "",
    }));
    const newGroup = useSelector(getNewGroup);
    const isLoading = newGroup.has('saved') && !newGroup.get('saved') && !newGroup.get('error');
    const errorMsg = newGroup.get('error');
    useEffect(() => {
        if(newGroup.get('saved')) {
            const slug = newGroup.get('slug');
            if (isPresent(slug)) {
                dispatch(redirectTo(`/groups/${slug}`));
            } else {
                dispatch(redirectTo("/groups"));
            }
        }
    }, [newGroup]);
    const imageStyle = isDefined(group) && isPresent(group.get('image_url')) ? {
        backgroundImage: `url("${group.get('image_url')}")`,
    } : {
        backgroundImage: `url("/images/main_bg.jpg")`,
    };
    return (
        <div>
            <Segment className="cuddle_light">
                <Form onSubmit={()=>dispatch(saveGroup(group))}>
                    <Grid className="group-pane group-edit" columns={3} stackable>
                        <NavigationRow />
                        <Grid.Row>
                            <Grid.Column>
                                <ImageUpload withForm={false}
                                    onChange={(imgIds) => imgIds &&
                                        setGroup(group.set('image', imgIds[0]))
                                    }
                                    onPreview={(url) => url &&
                                        setGroup(group.set('image_url', url))
                                    }
                                >
                                    <div className="group-image group-upload"
                                        title="Bild hochladen"
                                        style={imageStyle}
                                    />
                                </ImageUpload>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Grid columns={1} className="group-fields">
                                    <Grid.Row>
                                        <Grid.Column className="group-title">
                                            <Form.Input
                                                className="cuddle-light"
                                                label={{children: "Betreff", className:"cuddle-light"}}
                                                required
                                                fluid
                                                size="large"
                                                value={group.get('title')}
                                                onChange={(ev, data) =>
                                                    setGroup(group.set('title', data.value))
                                                }
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className="group-location">
                                            <Form.Input
                                                className="cuddle-light"
                                                label={{children: "Ort", className:"cuddle-light"}}
                                                required
                                                fluid
                                                size="large"
                                                value={group.get('location')}
                                                onChange={(ev, data) =>
                                                    setGroup(group.set('location', data.value))
                                                }
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className="group-date">
                                            <Form.Input
                                                className="cuddle-light"
                                                label={{children: "Zeit", className:"cuddle-light"}}
                                                required
                                                fluid
                                                size="large"
                                                value={group.get('date')}
                                                onChange={(ev, data) =>
                                                    setGroup(group.set('date', data.value))
                                                }
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className="group-description">
                                            <Form.TextArea value={group.get('description')}
                                                className="cuddle-light"
                                                rows={10}
                                                label={{children: "Beschreibung", className:"cuddle-light"}}
                                                required
                                                onChange={(ev, data) =>
                                                    setGroup(group.set('description', data.value))
                                                }
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div className="group-comment-button">
                                                <Button className="group-big" size="large"
                                                    type="submit"
                                                >
                                                    Gruppe erstellen
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <Dimmer active={isLoading || isDefined(errorMsg)}>
                    {isDefined(errorMsg)?
                        <Message>
                            <Message.Header>Konnte eine Gruppe nicht anlegen</Message.Header>
                            <p>{errorMsg}</p>
                            <p>
                                <Button className="cuddle"
                                    fluid
                                    onClick={()=>dispatch(clearNewGroup())}
                                >
                                    OK
                                </Button>
                            </p>
                        </Message>:
                        <Loader active inline='centered' size='large'/>
                    }
                </Dimmer>
            </Segment>
        </div>
    );
};

const GroupsPage = ({}) => {
    const authed = useSelector(isAuthed);
    const userAuth = useSelector(getAuth);
    const profile_image_url = userAuth.get('profile_image_url');
    const profileImgPresent = isPresent(profile_image_url) && !profile_image_url.includes("unset_avatar.svg");
    
    if (!authed) {
        return <Redirect to="/" />;
    }
    return (<>
        { profileImgPresent ?
            <div>
                <LogoSegment />
                <Switch>
                    <Route path="/groups/_new"
                        render={() => <GroupNew />}
                    />
                    <Route path="/groups/:slug"
                        render={({match: {params: {slug}}}) => <GroupShow slug={slug}/>}
                    />
                    <Route path="/groups/"
                        render={() => <GroupsIndex />}
                    />
                </Switch>
            </div>
            :
            <div>
                <Segment className="transparent">
                    <div className="cd-block cd-darker cd-center">
                        <div className="cd-text">
                            <h3>Fehlendes Profilbild</h3>
                            <p>
                                Um die Suche vollständig nutzen zu können, müssen Sie ein Profilbild hochladen.
                            </p>
                            <Link to="/profile/edit">Bild hochladen</Link>
                        </div>
                    </div>
                </Segment>
            </div>
            }
    </>);
};

export default GroupsPage;
