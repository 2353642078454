import React, {useEffect} from 'react';
import {Route, Switch, NavLink, useHistory} from "react-router-dom";
import { Redirect } from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import {BackButton} from "../ui";

import {
    isPresent, isDefined, formatDate,
} from "../../helpers";

import {
    getModReviews, getFetchedModReview,
} from "../../selectors";
import {
    fetchModReviews, fetchModReview,
} from "../../actions";

import {
    Segment, Table, Header, Icon, Dimmer, Loader,
} from 'semantic-ui-react';

const ReviewIndex = ({}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchModReviews());
    }, []);
    const reviews = useSelector(getModReviews);
    const errorMsg = reviews.get('error');
    const isLoading = reviews.get('loading', false);
    const items = reviews.get('results');
    return (<div className="mod-review">
        <Header as="h1">Benutzerbewertungen</Header>
        <Segment attached="top" textAlign="right" color="violet">
            # Bewertungen: {items.size}
        </Segment>
        <Table celled striped selectable attached="bottom">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell collapsing></Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">ID</Table.HeaderCell>
                    <Table.HeaderCell>Adressat</Table.HeaderCell>
                    <Table.HeaderCell>Bewerter</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map((r, i) => <ReviewRow review={r} key={r.get('slug')} />)}
                <Table.Row>
                    <Table.Cell colSpan="9">
                        <Dimmer active={isPresent(errorMsg) || isLoading} inverted>
                            {isPresent(errorMsg) ? (
                                <Header as='h2' icon>
                                    <Icon name='warning sign' />
                                    Oops! Da ist etwas schief gelaufen.
                                    <Header.Subheader>
                                        {errorMsg}
                                    </Header.Subheader>
                                </Header>
                            ) : (
                                <Loader inverted>Wird geladen...</Loader>
                            )}
                        </Dimmer>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </div>);
};

const ReviewRow = ({review}) => {
    const history = useHistory();
    const url = `/mods/reviews/${review.get('slug')}`;
    return (<>
        <Table.Row onClick={() => history.push(url)}>
            <Table.Cell>
                {review.get("has_mod_body") ?
                    <Icon name="envelope square" title="Hat ein Kommentar an Mods"/>
                : null}
            </Table.Cell>
            <Table.Cell textAlign="right">
                {review.get('id')}
            </Table.Cell>
            <Table.Cell>
                {review.getIn(["user", "nickname"]) ?? review.getIn(["user", "email"])}
            </Table.Cell>
            <Table.Cell>
                {review.getIn(["author", "nickname"]) ?? review.getIn(["author", "email"])}
            </Table.Cell>
        </Table.Row>
    </>);
};

const ReviewDetail = ({slug}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchModReview(slug));
    }, [slug]);
    const review = useSelector(getFetchedModReview);
    const errorMsg = review.get('error');
    const isLoading = review.get('loading', false);
    const item = review.get('result');
    if (isLoading) {
        return (<div className="mod-review">
            <Table attached="bottom">
                <Table.Body>
                    <Table.Row>
                        <Table.Cell textAlign="center">
                            <Loader inverted active>Wird geladen...</Loader>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>);
    }

    if (isPresent(errorMsg)) {
        return (<div className="mod-review">
            <Table attached="bottom">
                <Table.Body>
                    <Table.Row>
                        <Table.Cell textAlign="center">
                            <Header as='h2' icon>
                                <Icon name='warning sign' />
                                Oops! Da ist etwas schief gelaufen.
                                <Header.Subheader>
                                    {errorMsg}
                                </Header.Subheader>
                            </Header>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.Cell colSpan="2">
                            <BackButton floated='right' to="/mods/reviews" />
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>);
    }

    return (<div className="mod-review">
        <Header as="h1">
            {item.getIn(["author", "nickname"], item.getIn(["author", "email"]))
                ?? "???"}
            {" "}bewertet{" "}
            {item.getIn(["user", "nickname"], item.getIn(["user", "email"]))
                ?? "???"}
        </Header>
        <Segment attached="top" textAlign="right" color="violet">
            <i>Abgegeben am {formatDate(item.get("created_at"))}</i>
        </Segment>
        <Table celled striped definition attached="bottom">
            <Table.Body>
                <Table.Row>
                    <Table.Cell collapsing>
                        Von
                    </Table.Cell>
                    <Table.Cell>
                        <NavLink to={`/profile/${item.getIn(["author", "slug"])}`}>
                            {
                                item.getIn(
                                           ["author", "nickname"],
                                           item.getIn(["author", "email"])
                                ) ?? "???"
                            }
                        </NavLink>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        An
                    </Table.Cell>
                    <Table.Cell>
                        <NavLink to={`/profile/${item.getIn(["user", "slug"])}`}>
                            {
                                item.getIn(
                                           ["user", "nickname"],
                                           item.getIn(["user", "email"])
                                ) ?? "???"
                            }
                        </NavLink>
                    </Table.Cell>
                </Table.Row>
                <Table.Row active>
                    <Table.Cell>
                        Text an Moderatoren
                    </Table.Cell>
                    <Table.Cell>
                        {item.get("mod_body") ?? ""}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Bewertung
                    </Table.Cell>
                    <Table.Cell>
                        {item.get("body") ?? "Keine"}
                    </Table.Cell>
                </Table.Row>
                <RatingRow label="Sterne" val={item.get("rating")} />
                <RatingRow label="Absichtslosigkeit" val={item.get("unintention")} />
                <RatingRow label="Einvernehmlichkeit" val={item.get("consent")} />
                <RatingRow label="Wohlbefinden" val={item.get("wellbeing")} />
                <RatingRow label="Redefluss" val={item.get("talkative")} />
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.Cell colSpan="2">
                        <BackButton floated='right' to="/mods/reviews" />
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        </Table>
    </div>);
};

const RatingRow = ({label, val}) => {
    const valNum = (typeof val === "number") ? parseInt(val) + 1 : null;
    return (
        <Table.Row>
            <Table.Cell>
                {label}
            </Table.Cell>
            <Table.Cell>
                {valNum === null ? <span>
                    <Icon name="exclamation circle" />
                    Wert ungültig
                </span> : <span>
                    {valNum} / 10
                </span>}
            </Table.Cell>
        </Table.Row>
    );
};

const ReviewPage = () => {
    return (
        <Switch>
            <Route path="/mods/reviews/:slug"
                render={({match: {params: {slug}}}) =>
                    <ReviewDetail slug={slug} />
                }
            />
            <Route path="/mods/reviews/"
                render={() => <ReviewIndex />}
            />
        </Switch>
    );
};


export default ReviewPage;