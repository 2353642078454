import React, {useContext, useEffect} from 'react';
import {Message, Button, Loader} from 'semantic-ui-react';
import {useSelector, useDispatch} from 'react-redux';

import {EDIT_NAVIGATION_MODE, REGULAR_MODE} from "../../constants";
import {Context as ViewModeContext} from "../../contexts/ViewMode";

import {
    cancelNavigationEdit, saveNavigationEdit, openNavigationEdit,
} from "../../actions";

import {getMenuEdit} from "../../selectors";
import {isPresent} from "../../helpers";

const NavigationAdmin = ({}) => {
    const {mode, setMode} = useContext(ViewModeContext);
    const menuItems = useSelector(getMenuEdit);
    const dispatch = useDispatch();
    useEffect(() => {
        if(menuItems.get('done') === false) {
            setMode(EDIT_NAVIGATION_MODE);
        } else {
            setMode(REGULAR_MODE);
        }
    }, [menuItems]);
    const isSaving = menuItems.get('saving');
    const errorMsg = menuItems.get('error');
    return (
        <div className="navigation-admin">
            {isPresent(errorMsg) ? (
                <Message negative>
                    <p>
                        Es ist ein Fehler aufgetretten:
                    </p>
                    <p>
                        {errorMsg}
                    </p>
                    <div className="navigation-admin-action-panel">
                        <Button onClick={() => !isSaving && dispatch(cancelNavigationEdit())} negative>
                            Änderungen Verwerfen
                        </Button>
                        <Button onClick={() => !isSaving && dispatch(saveNavigationEdit())} loading={isSaving}>
                            Erneut versuchen
                        </Button>
                    </div>
                </Message>
            ) : (mode === REGULAR_MODE ? (
                <Message>
                    Die Menüeinträge können direkt in den jeweiligen Menüleisten editiert werden,
                    wenn der Editiermodus aktiviert ist.
                    <div className="navigation-admin-action-panel">
                        <Button onClick={() => dispatch(openNavigationEdit())}>
                            Editiermodus aktivieren
                        </Button>
                    </div>
                </Message>) : (<Message positive>
                    <p>
                        Die Menüeinträge können nun in den entsprechenden Leisten
                        (oben und unten) angepasst werden.
                    </p>
                    <p>
                        Die Reihenfolge der Einträge kann geändert werden, indem
                        ein Eintrag lang angeklickt und dann horizontal
                        verschoben werden kann.
                    </p>
                   <p>
                        Die Eigenschaften der Menüeinträge können in einen
                        Panel geändert werden, welches mit einem Klick auf den
                        entsprechenden Menüeintrag geöffnet werden kann.
                    </p>
                    <p>
                        Alle Änderungen werden erst dann gespeichert, wenn hier
                        unten der Button "Änderungen Speichern" betätigt wird.
                   </p>
                    <div className="navigation-admin-action-panel">
                        <Button onClick={() => !isSaving && dispatch(cancelNavigationEdit())}>
                            Änderungen Verwerfen
                        </Button>
                        <Button positive onClick={() => !isSaving && dispatch(saveNavigationEdit())} loading={isSaving}>
                            Änderungen Speichern
                        </Button>
                    </div>
                </Message>
            ))}
        </div>
    );
};


export default NavigationAdmin;