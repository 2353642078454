import {createStore, combineReducers, applyMiddleware} from "redux";
import createSagaMiddleware from 'redux-saga'
import * as reducers from "./reducers";
import * as actions from "./actions";
import * as constants from "./constants";
import rootSaga from "./sagas";
import wsMiddleware from "./ws_middleware";

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const crashReporter = store => next => action => {
    try {
        if (action.type === constants.RELOAD_APP) {
            window.location.reload(false);
            return;
        }
        return next(action);
    } catch (err) {
        // TODO: we could send error to server here
        console.error('Redux step failed', err);
        throw err;
    }
}

export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = composeWithDevTools(applyMiddleware(
        wsMiddleware, sagaMiddleware, crashReporter));
    const rootReducer = combineReducers(reducers);
    const store = createStore(rootReducer, middleware);
    sagaMiddleware.run(rootSaga, store.dispatch).toPromise().catch(e => {
        store.dispatch(actions.showModal(
            "Hoppla! Da ist wohl etwas schief gelaufen. Bitte lade die Anwendung neu.",
            "Ich bin abgestürzt",
            [["Neuladen", actions.reloadApp()]]
        ));
        console.error({
            message: e.message,
            source: 'sagaError',
            stacktrace: e.sagaStack
        });
    });
    if(window.Cypress) {
        window.rs = store;
    }
    // sync token between tabs
    window.addEventListener('storage', function(event) {
        if(event.key === "session_token" && event.newValue !== event.oldValue) {
            store.dispatch(actions.setAuthToken(event.newValue, false));
        }
    });
    return store;
}