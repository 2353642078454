import React, {useState, useEffect,} from 'react';
import {DraftailEditor, ENTITY_TYPE, createEditorStateFromRaw} from 'draftail';
import { EditorState } from "draft-js";
import {EntityImage, EntityLink} from "../admin/ContentEditor";
import { createEditorStateFromHTML, isDefined,} from '../../helpers';

import { Loader } from "semantic-ui-react";

const parseState = (content) => {
    try {
        if(typeof content === "object" && isDefined(content) &&
            isDefined(content.blocks)) {
            return createEditorStateFromRaw(content);
        } else if (typeof content === "string"){
            return createEditorStateFromHTML(content);
        }
    } catch (e) {
        console.error("Failed parsing content", e);
    }
    return EditorState.createEmpty();
};

const RenderContent = ({content}) => {
    const [editorState, setEditorState] = useState(null);
    useEffect(() => {
        if(isDefined(content)) {
            setEditorState(parseState(content));
        }
    }, [content]);
    if(editorState === null) return (
        <div style={{position: 'relative'}}>
            <Loader active />
        </div >
    );
    return (
        <DraftailEditor editorState={editorState} readOnly topToolbar={null}
            onChange={(nextState) => setEditorState(nextState)}
            entityTypes={[
                { type: ENTITY_TYPE.IMAGE, block: EntityImage },
                { type: ENTITY_TYPE.LINK, decorator: EntityLink }
            ]}
            enableHorizontalRule
            enableLineBreak
            maxListNesting={2}
        />
    );
};

export default RenderContent;