import React, {useCallback, useEffect} from 'react';
import Immutable from "immutable";
import { useSelector, useDispatch } from 'react-redux'
import {Link} from 'react-router-dom';

import {createAccount, clearRegistration,} from "../actions";
import {getRegistration} from "../selectors";
import {isPresent, isDefined, fieldErr,} from "../helpers";

import {
    Grid, Header, Segment, Form, Button, Message, Dimmer, Loader,
} from "semantic-ui-react";


const RegistrationForm = ({registration}) => {
    const dispatch = useDispatch();
    const onRegister = useCallback((ev, d) => {
        ev.preventDefault();
        const t = ev.target;
        const email = t.email.value;
        const pw = t.pw.value;
        const pwc = t.pwc.value;
        dispatch(createAccount(email, pw, pwc));
        return false;
    });
    const errors = registration.get('errors');
    return (<>
        <Header as='h2' className="cuddle" textAlign="center">
            Ein Profil anlegen
        </Header>
        <Form size="large" onSubmit={onRegister} error={isDefined(errors)}>
            <Form.Input fluid icon="user" iconPosition="left" required
                placeholder="E-Mail" name="email" type="email"
                autoComplete="username" autoFocus
                error={fieldErr(errors, 'email')} />
            <Form.Input fluid icon="lock" iconPosition="left" required
                placeholder="Passwort" type="password"
                name="pw" autoComplete="new-password"
                error={fieldErr(errors, 'password')} />
            <Form.Input fluid icon="lock" iconPosition="left" required
                placeholder="Passwort wiederholen" type="password"
                name="pwc"
                autoComplete="confirm-password"
                error={fieldErr(errors, 'password_confirmation')} />
            {isPresent(errors)?<Message error>{errors}</Message>:null}
            <Button className="cuddle" fluid size="large" data-cy="register">
                Registrieren
            </Button>
        </Form>
        <Message className="cuddle">
            Du hast schon ein Profil? <br />
            <Link to="/login">
                Dann melden dich hier an!
            </Link>
        </Message>
    </>);
};

const Confirmation = ({registration}) => {
    const message = registration.get('message');
    return (
        <Message>
            <Message.Header>E-Mail bestätigen</Message.Header>
            <p>{message}</p>
            <Link to="/">
                {/*Anleitung zur Bestätigung noch mal schicken*/}
                Zurück zur Startseite
            </Link>
        </Message>
    );
};

const RegisterPage = ({}) => {
    const dispatch = useDispatch();
    const registration = useSelector(getRegistration);
    useEffect(() => {
        dispatch(clearRegistration());
    }, []);
    const isLoading = registration.get('loading') || false;
    const isSuccessful = registration.get('success') || false;
    return (
        <Grid textAlign='center'>
            <Grid.Column style={{ maxWidth: 450}}>
                <Segment stacked>
                    {isSuccessful?
                        <Confirmation registration={registration} />:
                        <RegistrationForm registration={registration} />
                    }
                    <Dimmer active={isLoading}>
                        <Loader />
                    </Dimmer>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

RegisterPage.defaultProps = {};
RegisterPage.propTypes = {};

export default RegisterPage;
