import React, {useEffect, useState} from 'react';
import Immutable from 'immutable';
import {Route, Switch, NavLink} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'

import {isPresent} from "../../helpers";

import {getQuotations, getQuotationEdit,} from "../../selectors";
import {
    addQuotation, saveQuotation, fetchQuotations, saveQuotationClear,
    editQuotation, deleteQuotation, redirectTo,
} from "../../actions";

import {
    Table, Button, Icon, Form, Input, TextArea, Dimmer, Loader, Header,
} from 'semantic-ui-react';

const QuotationRow = ({quotation: q}) => {
    const dispatch = useDispatch();
    return (
        <Table.Row>
            <Table.Cell>{q.get('author')}</Table.Cell>
            <Table.Cell>{q.get('body')}</Table.Cell>
            <Table.Cell>
                <Button.Group size='mini' basic>
                    <Button icon title="Ändern"
                        onClick={() => dispatch(editQuotation(q.get('slug')))}
                    >
                        <Icon name='edit' />
                    </Button>
                    <Button icon title="Löschen"
                        onClick={() => dispatch(deleteQuotation(q.get('slug')))}
                    >
                        <Icon name='trash' />
                    </Button>
                </Button.Group>
            </Table.Cell>
        </Table.Row>
    );
};

const QuotationIndex = ({quotations}) => {
    const dispatch = useDispatch();
    const loading = quotations.get('loading', false);
    const errorMsg = quotations.get('error');
    const items = quotations.get('result', Immutable.Map())
        .filterNot((v, k) => k === '_new_').toIndexedSeq().toArray();
    return (<div>
        <Table celled striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Author</Table.HeaderCell>
                    <Table.HeaderCell>Zitat</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map(q => <QuotationRow quotation={q} key={q.get('slug')}/>)}
                <Table.Row>
                    <Table.Cell colSpan="3">
                        <Dimmer active={isPresent(errorMsg) || loading} inverted>
                            {isPresent(errorMsg) ? (
                                <Header as='h2' icon>
                                    <Icon name='settings' />
                                    Oops! Da ist etwas schief gelaufen.
                                    <Header.Subheader>
                                        {errorMsg}
                                    </Header.Subheader>
                                </Header>
                            ) : (
                                <Loader inverted>Wird geladen...</Loader>
                            )}
                        </Dimmer>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan="3">
                        <Button floated='right' icon labelPosition='left' positive
                            size='small' onClick={() => dispatch(addQuotation())}>
                            <Icon name='plus' /> Zitat hinzufügen
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    </div>);
};

const NEW_QUOTATION = Immutable.fromJS({
    slug: null,
    author: "",
    body: ""
});

const QuotationEdit = ({slug}) => {
    const quotations = useSelector(getQuotations);
    const editState = useSelector(getQuotationEdit);
    const loading = editState.get('loading', false);
    const errorMsg = editState.get('error', null);
    const initQuotation = (isPresent(slug) && slug !== "_new_") ?
        quotations.getIn(['result', slug]) : NEW_QUOTATION;
    const [q, setQ] = useState(initQuotation);
    const dispatch = useDispatch();
    const blocked = loading || isPresent(errorMsg);
    return (
        <div>
            <Dimmer.Dimmable as={Form} dimmed={blocked}
                onSubmit={() => !blocked && dispatch(saveQuotation(q.get('slug'), q))}
            >
                <Dimmer inverted active={blocked}
                >
                    {isPresent(errorMsg) ? (
                        <Header as='h2' icon>
                            <Icon name='settings' />
                            Oops! Da ist etwas schief gelaufen.
                            <Header.Subheader>
                                {errorMsg}
                            </Header.Subheader>
                            <Button floated="right"
                                onClick={() => dispatch(saveQuotationClear())}
                            >
                                OK
                            </Button>
                        </Header>
                    ) : (
                        <Loader inverted>Wird gespeichert...</Loader>
                    )}
                </Dimmer>
                <Form.Field control={Input} label="Autor" required
                    value={q.get('author')}
                    onChange={(e, data) => !blocked && setQ(q.set('author', data.value))}
                />
                <Form.Field control={TextArea} label="Zitat" required
                    value={q.get('body')}
                    onChange={(e, data) => !blocked && setQ(q.set('body', data.value))}
                />
                <Form.Group widths="equal">
                    <Form.Field control={Button}
                        onClick={() => dispatch(redirectTo('/admin/quotations/'))}
                    >
                        Zurück
                    </Form.Field>
                    <Form.Field control={Button} positive floated="right" type="submit">
                        Speichern
                    </Form.Field>
                </Form.Group>
            </Dimmer.Dimmable>
        </div>
    );
};

const QuotationAdmin = () => {
    const quotations = useSelector(getQuotations);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchQuotations());
    }, []);
    return (
        <Switch>
            <Route path="/admin/quotations/:slug"
                render={({match: {params: {slug}}}) => <QuotationEdit slug={slug}/>}
            />
            <Route path="/admin/quotations/"
                render={() => <QuotationIndex quotations={quotations} />}
            />
        </Switch>
    );
};


export default QuotationAdmin;
