import {
    call, put, takeEvery, select, delay, fork, all, takeLatest,
} from 'redux-saga/effects'
import Immutable from 'immutable';

import * as constants from "./constants";
import * as actions from "./actions";
import * as selectors from "./selectors";
import {
    isPresent, errTrans, serializePageContent, parsePageContent, searchCompare,
    searchInclude,
} from "./helpers";

function* doRequest(url, method='GET', data=null, options={}) {
    const token = yield select(selectors.getAuthToken);
    const client_aud = yield select(selectors.getClientAud);
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
    };
    if (isPresent(token) && !options.skipAuth) {
        headers["Authorization"] = `Bearer ${token}`;
        headers["JWT_AUD"] = client_aud;
    }
    if (options.addAUD) {
        headers["JWT_AUD"] = client_aud;
    }
    let request = {ok: false};
    try {
        request = yield call(fetch, url, {
            method: method,
            cache: "no-cache",
            credentials: "same-origin",
            headers,
            body: data? JSON.stringify(data) : undefined,
        });
    } catch (err) {
        request.text = () => err.message || err;
    }
    if (request.ok) {
        let result = null;
        if (request.status !== 204) {
            result = Immutable.fromJS(yield request.json());
        }
        let token = request.headers?.get("Authorization");
        if (isPresent(token)) {
            token = token.slice && token.slice(7);
            yield put(actions.setAuthToken(token));
        }
        if (result !== null && "error" in result) {
            const errMsg = result.message || result.error || result;
            if(result && result.error === "authentication error" && !options.skipAuth) {
                yield put(actions.setAuthToken(null));
                yield put(actions.showModal(errTrans(errMsg), "Ungültige Sitzung"));
            }
            throw errMsg;
        } else {
            return [result, request];
        }
    } else {
        let result;
        try {
            result = yield request.clone().json();
        } catch (err) {
            result = yield request.text();
        }
        const errMsg = result.message || result.error || result;
        if(result && result.error === "authentication error" && !options.skipAuth) {
            yield put(actions.setAuthToken(null));
            yield put(actions.showModal(errTrans(errMsg), "Ungültige Sitzung"));
        } else {
            let token = request.headers?.get("Authorization");
            if (isPresent(token)) {
                token = token.slice && token.slice(7);
                yield put(actions.setAuthToken(token));
            }
        }
        throw errMsg;
    }
}

function* getApi(url, options) {
    return yield doRequest(`/api/v1/${url}`, 'GET', options);
}

function* putApi(url, data, options) {
    return yield doRequest(`/api/v1/${url}`, 'PUT', data, options);
}

function* patchApi(url, data, options) {
    return yield doRequest(`/api/v1/${url}`, 'PATCH', data, options);
}

function* postApi(url, data, options) {
    return yield doRequest(`/api/v1/${url}`, 'POST', data, options);
}

function* deleteApi(url, data, options) {
    return yield doRequest(`/api/v1/${url}`, 'DELETE', data, options);
}

function maybeImmutableError(err) {
    let res = Immutable.fromJS(err);
    if(!Immutable.isImmutable(res)) {
        res = errTrans(res);
    }
    return res;
}

export function* preloadImage(url) {
    return yield new Promise(resolve => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = (err) => {
            console.error(errTrans(err), "Bild konnte nicht geladen werden");
            resolve();
        };
        img.src = url;
    });
};

export function* fetchInitialData(action) {
    try {
        let [response, request] = yield getApi('setup/initial');
        response = response.set('pages', response.get('pages')
                           .map(p => parsePageContent(p)));
        yield put(actions.fetchInitialDataDone(response));
        const authed = yield select(selectors.isAuthed);
        if(authed) {
            yield put(actions.connectWs());
        }
        const images = response.get('pages').reduce((urls, page) => {
            const top = page.get('top_image_url');
            const bottom = page.get('bottom_image_url')
            if(isPresent(top)) urls.push(top);
            if(isPresent(bottom)) urls.push(bottom);
            return urls;
        }, []);
        yield all(images.map(url => call(preloadImage, url)));
    } catch (err) {
        console.error('setup failed', err);
        yield put(actions.fetchInitialDataError(errTrans(err)));
    }
}

export function* setup(action){
    yield all({
        data: call(fetchInitialData, action),
        delay: delay(1000),
    });
    yield put(actions.hideLoading());
}

export function* authenticate(action) {
    const {email, password} = action.payload;
    try {
        const [response, request] = yield postApi('login', {
            user: {email, password}
        }, {addAUD: true, skipAuth: true});
        yield put(actions.authenticateDone(response));
        yield put(actions.connectWs());
    } catch (err) {
        yield put(actions.authenticateError(err));
        yield put(actions.showModal(errTrans(err), "Anmeldung fehlgeschlagen"));
    }
}

export function* deauthenticate(action) {
    yield put(actions.disconnectWs());
    let token = yield select(selectors.getAuthToken);
    const client_aud = yield select(selectors.getClientAud);
    if(isPresent(token)){
        token = `Bearer ${token}`;
        const request = yield call(fetch, "/api/v1/logout", {
            method: "DELETE",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
                "JWT_AUD": client_aud,
            },
        });
        if (request.ok){
            yield put(actions.deauthenticateDone());
        } else {
            console.log('error', request);
        }
    } else {
        console.log("cannot log out if not logged in!");
    }
}

export function* createAccount(action) {
    const {email, password, password_confirmation} = action.payload;
    try {
        const [response, request] = yield postApi('signup', {
            user: {email, password, password_confirmation}
        });
        yield put(actions.createAccountDone(response.get('success'),
                                            response.get('message')));
    } catch (err) {
        yield put(actions.createAccountError(maybeImmutableError(err)));
    }
}

export function* sendConfirmation(action) {
    const {token} = action.payload;
    try {
        const [response, request] = yield getApi(`confirmation?confirmation_token=${token}`);
        yield put(actions.sendConfirmationDone(response.get('success'),
                                               response.get('message')));
    } catch (err) {
        yield put(actions.sendConfirmationError(maybeImmutableError(err)));
    }
}

export function* sendReconfirmation(action) {
    const email = yield select(state => state.registration.get('email'));
    try {
        const [response, request] = yield postApi(`confirmation`, {user: {email}});
        yield put(actions.sendReconfirmationDone(response.get('success'),
                                                 response.get('message')));
    } catch (err) {
        yield put(actions.sendReconfirmationError(maybeImmutableError(err)));
    }
}

export function* fetchProfile(action) {
    const {slug} = action.payload;
    const key = isPresent(slug) ? slug : "invalid";
    yield put(actions.fetchProfileLoading(key));
    try {
        const [response, _] = yield getApi(`profiles/${slug}`)
        yield put(actions.fetchProfileDone(response.get("slug"), response));
    } catch (e) {
        yield put(actions.fetchProfileError(key, errTrans(e)));
    }
}

export function* fetchProfileEdit(action) {
    try {
        const [response, _] = yield getApi("profiles")
        yield put(actions.fetchProfileEditDone(response));
    } catch (e) {
        yield put(actions.fetchProfileEditError(errTrans(e)));
    }
}

export function* saveProfile(action) {
    const {data, slug} = action.payload;
    yield put(actions.saveProfileOngoing(slug));
    try {
        const [response, _] = yield putApi('profiles', {user: data});
        yield put(actions.saveProfileDone(response.get("slug"), response));
        yield put(actions.showToast("Profil aktualisiert!", ""));
    } catch (e) {
        yield put(actions.saveProfileError(slug, maybeImmutableError(e)));
    }
}

export function* saveProfileEdit(action) {
    const {data} = action.payload;
    try {
        const [response, _] = yield putApi('profiles', {user: data});
        yield put(actions.saveProfileEditDone(response));
    } catch (e) {
        yield put(actions.saveProfileEditError(maybeImmutableError(e)));
    }
}

export function* fetchPages() {
    try {
        const [response, _] = yield getApi('pages');
        const pages = Immutable.Map().withMutations(map => {
            for (const page of response) {
                map.set(`${page.get('slug')}`, parsePageContent(page));
            }
        });
        yield put(actions.fetchPagesDone(pages));
    } catch (e) {
        yield put(actions.fetchPagesError(errTrans(e)));
    }
}

export function* fetchOnlineUsers() {
    try {
        const [response, _] = yield getApi('online_users');
        yield put(actions.fetchOnlineUsersDone(response.valueSeq().toList()));
    } catch (e) {
        console.error(e);
        yield put(actions.fetchOnlineUsersError(errTrans(e)));
    }
}

async function fetchGeolocation() {
    if(navigator.geolocation) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((pos) => {
                resolve(pos.coords);
            }, (error) => {
                reject(error);
            }, {
                maximumAge:60000
            });
        });
    } else {
        return Promise.reject("Dein Browser unterstützt leider die Geolokalisierung nicht");
    }
}

function* askLocation(nearObj) {
    if(navigator.permissions){
        const res = yield call([navigator.permissions, "query"], {name:'geolocation'});
        if (res.state === "granted" || res.state === "prompt") {
            //console.log(res.state);
            try {
                const pos = yield call(fetchGeolocation);
                //console.log('pos', pos)
                return {...nearObj, lat: pos.latitude, lon: pos.longitude};
            } catch (err) {
                console.error(err);
                yield put(actions.showToast(
                    "kann nicht ermittelt werden. "+
                    "Bitte erlaube Kuscheldate darauf zuzugreifen. Solange "+
                    "wird dein Ort aus deinem Profil verwendet.",
                    "Deine aktuelle Position"));
                return nearObj;
            }
        } else if(res.state === "denied") {
            //console.log('denied');
            return nearObj;
        }
    } else {
        return nearObj;
    }
};

export function* searchUsers(action) {
    try {
        const {query, distance, locId, nearby} = action.payload;
        let nearObj = null;
        if (nearby && distance) {
            nearObj = yield askLocation(nearby);
        }
        const [response, _] = yield postApi("search_users", {
            q: query, nearby: nearObj, distance, loc: locId,
        });
        yield put(actions.searchUsersDone(response));
    } catch (e) {
        console.error(e);
        yield put(actions.searchUsersError(errTrans(e)));
    }
}

export function* searchUsersOnline(action) {
    try {
        const {query} = action.payload;
        const users = yield select(selectors.getOnlineUsers);
        const processedUsers = users.filter(u =>
            isPresent(u.get('nickname')) && u.get('locatable') &&
            (!isPresent(query) || searchInclude(u.get("nickname"), query)
                               || searchInclude(u.get("location"), query)
            )
        ).sort((a,b) => searchCompare(a.get('nickname'), b.get("nickname")));
        yield put(actions.searchUsersDone(Immutable.fromJS({by_presence: processedUsers})));
    } catch (e) {
        console.error(e);
        yield put(actions.searchUsersError(errTrans(e)));
    }
}

export function* searchGroups(action) {
    try {
        const {query} = action.payload;
        const [response, _] = yield postApi("search_groups", {q: query});
        yield put(actions.searchGroupsDone(response));
    } catch (e) {
        console.error(e);
        yield put(actions.searchGroupsError(errTrans(e)));
    }
}

export function* savePage(action){
    try {
        const {slug, page} = action.payload;
        let response, _;
        if (isPresent(slug)) {
            [response, _] = yield putApi(`pages/${slug}`, {page});
        } else {
            [response, _] = yield postApi(`pages/`, {page});
        }
        response = parsePageContent(response);
        yield put(actions.savePageDone(slug, response));
    } catch (e) {
        console.error(e);
        // TODO:
        console.warn("Error state not handled while saving a page");
    }
}

export function* fetchQuotations(action){
    try {
        yield put(actions.fetchQuotationsLoading());
        const [response, _] = yield getApi('quotations');
        yield put(actions.fetchQuotationsDone(response));
    } catch (e) {
        console.error("fetch quotations failed", e);
        yield put(actions.fetchQuotationsError(errTrans(e)));
    }
}

export function* saveQuotation(action){
    const {slug, quotation} = action.payload;
    try {
        if(!isPresent(quotation.get('author')) || !isPresent(quotation.get('body'))) {
            throw "Bitte fülle alle Felder aus!";
        }
        yield put(actions.saveQuotationOngoing());
        let response, _;
        if (isPresent(slug)) {
            [response, _] = yield putApi(`quotations/${slug}`, {quotation});
        } else {
            [response, _] = yield postApi(`quotations/`, {quotation});
        }
        yield put(actions.saveQuotationDone(response.get('slug'), response));
        yield put(actions.redirectTo('/admin/quotations/'));
        yield put(actions.showToast("Das Zitat wurde erfolgreich gespeichert.", ""));
    } catch (e) {
        console.error("save quotation failed", e);
        yield put(actions.saveQuotationError(errTrans(e)));
    }
}

export function* deleteQuotation(action){
    const {slug} = action.payload;
    try {
        const [response, _] = yield deleteApi(`quotations/${slug}`);
        yield put(actions.deleteQuotationDone(slug));
        yield put(actions.showToast("Das Zitat wurde erfolgreich gelöscht.", ""));
    } catch (e) {
        console.error("delete quotation failed", e);
        yield put(actions.showToast(errTrans(e), "Löschen des Zitats gescheitert."));
    }
}

export function* deleteChat(action) {
    const {slug} = action.payload;
    try {
        const [response, _] = yield deleteApi(`delete_chat/${slug}`);
        yield put(actions.showToast("Die Konversation wurde erfolgreich gelöscht.", ""));
    } catch (e) {
        console.error("delete chat failed", e);
        yield put(actions.showToast(errTrans(e), "Das Löschen der Konversation ist fehlgeschlagen."));
    }
}

function* fetchNextProfileComments(action) {
    const {slug} = action.payload;
    try {
        const profile_comments = yield select(
            state => selectors.getProfileComments(state, slug));
        let nextUrl = profile_comments.get('nextUrl');
        const firstId = profile_comments.getIn(['result', 0, 'slug']);
        let response, _;
        if (isPresent(nextUrl)) {
            if (isPresent(firstId)) {
                nextUrl += "&start=" + firstId;
            }
            [response, _] = yield doRequest(nextUrl, 'GET');
        } else {
            let url = 'profiles/' + slug + '/comments?limit=10';
            if (isPresent(firstId)) {
                url += "&start=" + firstId;
            }
            [response, _] = yield getApi(url);
        }
        yield put(actions.fetchNextProfileCommentsDone(slug,
            response.get('comments'), response.get('next_path'),
            response.get('new_comments')));
    } catch (e) {
        console.error(e);
        yield put(actions.fetchNextProfileCommentsError(slug, errTrans(e)));
    }
}

function* fetchNextGroupComments(action) {
    const {groupSlug} = action.payload;
    try {
        const group_comments = yield select(
            state => selectors.getGroupComments(state, groupSlug));
        let nextUrl = group_comments.get('nextUrl');
        const firstId = group_comments.getIn(['result', 0, 'slug']);
        let response, _;
        if (isPresent(nextUrl)) {
            if (isPresent(firstId)) {
                nextUrl += "&start=" + firstId;
            }
            [response, _] = yield doRequest(nextUrl, 'GET');
        } else {
            let url = 'groups/' + groupSlug + '/comments?limit=10';
            if (isPresent(firstId)) {
                url += "&start=" + firstId;
            }
            [response, _] = yield getApi(url);
        }
        yield put(actions.fetchNextGroupCommentsDone(groupSlug,
            response.get('comments'), response.get('next_path'),
            response.get('new_comments')));
    } catch (e) {
        console.error(e);
        yield put(actions.fetchNextGroupCommentsError(groupSlug, errTrans(e)));
    }
}

function* sendProfileComment(action) {
    const {slug, comment} = action.payload;
    try {
        const [response, _] = yield postApi(`profiles/${slug}/comments`, {
            profile_comment: comment,
        });
        yield put(actions.sendProfileCommentDone(slug, response));
        yield put(actions.fetchNextProfileComments(slug));
    } catch (e) {
        console.error(e);
        yield put(actions.sendProfileCommentError(slug, errTrans(e)));
    }
}

function* sendGroupComment(action) {
    const {groupSlug, comment} = action.payload;
    try {
        const [response, _] = yield postApi(`groups/${groupSlug}/comments`, {
            group_comment: comment,
        });
        yield put(actions.sendGroupCommentDone(groupSlug, response));
        yield put(actions.fetchNextGroupComments(groupSlug));
    } catch (e) {
        console.error(e);
        yield put(actions.sendGroupCommentError(groupSlug, errTrans(e)));
    }
}

export function* fetchModTasks(action){
    try {
        yield put(actions.fetchModTasksLoading());
        const [response, _] = yield getApi('mod_tasks');
        yield put(actions.fetchModTasksDone(response));
    } catch (e) {
        console.error("fetch mod tasks failed", e);
        yield put(actions.fetchModTasksError(errTrans(e)));
    }
}

export function* fetchModTask(action){
    const {slug} = action.payload;
    try {
        yield put(actions.fetchModTasksLoading());
        const [response, _] = yield getApi(`mod_tasks/${slug}`);
        yield put(actions.fetchModTaskDone(slug, response));
    } catch (e) {
        console.error("fetch mod task failed", e);
        yield put(actions.fetchModTasksError(errTrans(e)));
    }
}

export function* fetchModReviews(action){
    try {
        const [response, _] = yield getApi('mod_reviews');
        yield put(actions.fetchModReviewsDone(response));
    } catch (e) {
        console.error("fetch mod reviews failed", e);
        yield put(actions.fetchModReviewsError(errTrans(e)));
    }
}

export function* fetchModReview(action){
    const {slug} = action.payload;
    try {
        const [response, _] = yield getApi(`mod_reviews/${slug}`);
        yield put(actions.fetchModReviewDone(response));
    } catch (e) {
        console.error("fetch mod review failed", e);
        yield put(actions.fetchModReviewError(errTrans(e)));
    }
}

export function* sendModTasksAction(action){
    const {action: tasks_action, slugs, extra} = action.payload;
    try {
        yield put(actions.fetchModTasksLoading());
        const [response, _] = yield putApi('mod_tasks/_action', {
            mod_tasks: {
                action: tasks_action, ids: slugs, extra,
            }
        });
        const currentUserId = yield select(selectors.getAuthUserId);
        if(tasks_action === "take" && !response.map(i => i.getIn(["assignee", "slug"])).every(i => i === currentUserId)) {
            yield put(actions.showToast("Einige oder alle Aufgaben konnten nicht übernommen werden", ""));
        }
        yield put(actions.sendModTasksActionDone(response));
    } catch (e) {
        console.error("fetch mod tasks failed", e);
        yield put(actions.fetchModTasksError(errTrans(e)));
    }
}

export function* fetchGroup(action) {
    const {slug} = action.payload;
    const key = isPresent(slug) ? slug : "invalid";
    try {
        const [response, _] = yield getApi(`groups/${slug}`)
        yield put(actions.fetchGroupDone(response.get("slug"), response));
    } catch (e) {
        yield put(actions.fetchGroupError(key, errTrans(e)));
    }
}

export function* saveGroup(action) {
    const {group} = action.payload;
    try {
        const [response, _] = yield postApi(`groups`, {group})
        yield put(actions.saveGroupDone(response));
    } catch (e) {
        yield put(actions.saveGroupError(errTrans(e)));
    }
}

export function* openNavigationEdit(action) {
    const navItems = yield select(selectors.getNavigationItems);
    const entries = Immutable.fromJS({
        top: navItems.filter(m => m.get('location') === 'top'),
        bottom: navItems.filter(m => m.get('location') === 'bottom'),
    });
    yield put(actions.openNavigationEditDone(entries));
}

export function* saveNavigationEdit(action) {
    const menuEdit = yield select(selectors.getMenuEdit);
    const locationEntries = menuEdit.get('entries');
    const entries = locationEntries.toList().flatten(true);
    try {
        const [response, _] = yield postApi(`menu_items`, {menu_items: entries});
        yield put(actions.saveNavigationEditDone(response.get("menu_items")));
    } catch (e) {
        yield put(actions.saveNavigationEditError(errTrans(e)));
    }
}

export function* openPageEdit(action) {
    const {slug} = action.payload;
    try {
        const [response, _] = yield getApi(`pages/${slug}`);
        const sortedBlocks = (response.get('blocks') || Immutable.List()).sortBy(
            b => b.get('order')
        ).map(b => b.set('blocks', b.get('blocks').sortBy(
            b => b.get('order')
        )));
        let page = response.set('blocks', sortedBlocks);
        page = parsePageContent(page);
        yield put(actions.openPageEditDone(slug, page));
    } catch (e) {
        yield put(actions.openPageEditError(slug, errTrans(e)));
    }
}

export function* savePageEdit(action) {
    const pageEdit = yield select(selectors.getPageEdit);
    const page = serializePageContent(pageEdit.get('page'));
    const slug = page.get('slug');
    try {
        let response, _;
        if (isPresent(slug)) {
            [response, _] = yield putApi(`pages/${slug}`, {page});
        } else {
            [response, _] = yield postApi(`pages/`, {page});
        }
        const sortedBlocks = (response.get('blocks') || Immutable.List()).sortBy(
            b => b.get('order')
        ).map(b => b.set('blocks', b.get('blocks').sortBy(
            b => b.get('order')
        )));
        let newpage = response.set('blocks', sortedBlocks);
        newpage = parsePageContent(newpage);
        yield put(actions.savePageEditDone(newpage));
    } catch (e) {
        yield put(actions.savePageEditError(errTrans(e)));
    }
}

export function* deletePage(action) {
    const {slug} = action.payload;
    try {
        if (isPresent(slug)) {
            const [response, _] = yield deleteApi(`pages/${slug}`);
        }
        yield put(actions.deletePageDone(slug));
    } catch (e) {
        yield put(actions.showToast(errTrans(e), "Fehler beim Löschen einer Seite"));
    }
}

export function* fetchUserEdit(action) {
    try {
        const [response, _] = yield getApi(`users`)
        yield put(actions.fetchUserEditDone(response));
    } catch (e) {
        yield put(actions.fetchUserEditError(errTrans(e)));
    }
}

export function* sendUserInvitation(action) {
    const {user} = action.payload;
    try {
        const [response, _] = yield postApi(`invitation`, {user});
        yield put(actions.sendUserInvitationDone(response.get('success', false),
                                                 response.get('message')));
    } catch (e) {
        yield put(actions.sendUserInvitationError(maybeImmutableError(e)));
    }
}

export function* acceptInvitation(action) {
    const {
        token,
        nickname,
        password,
        password_confirmation
    } = action.payload;
    try {
        const [response, _] = yield putApi(`invitation`, {user: {
            nickname, password, password_confirmation, invitation_token: token,
        }});
        yield put(actions.acceptInvitationDone(response.get('success', false),
                                               response.get('message')));
    } catch (e) {
        yield put(actions.acceptInvitationError(maybeImmutableError(e)));
    }
}

function* deleteGroupComment(action) {
    const {slug, groupSlug} = action.payload;
    try {
        const [response, _] = yield deleteApi(`group_comments/${slug}`);
        yield put(actions.deleteGroupCommentDone(groupSlug, slug, response));
    } catch (e) {
        console.error(e);
        yield put(actions.deleteGroupCommentError(groupSlug, slug, errTrans(e)));
    }
}

function* deleteProfileComment(action) {
    const {slug, profileSlug} = action.payload;
    try {
        const [response, _] = yield deleteApi(`profile_comments/${slug}`);
        yield put(actions.deleteProfileCommentDone(profileSlug, slug, response));
    } catch (e) {
        console.error(e);
        yield put(actions.deleteProfileCommentError(profileSlug, slug, errTrans(e)));
    }
}

function* deleteGroup(action) {
    const {slug} = action.payload;
    try {
        const [response, _] = yield deleteApi(`groups/${slug}`);
        yield put(actions.deleteGroupDone(slug));
    } catch (e) {
        console.error(e);
        yield put(actions.deleteGroupError(slug, errTrans(e)));
    }
}

function* searchGeolocations(action) {
    const {query} = action.payload;
    yield delay(1000);
    try {
        const [response, _] = yield getApi(`geolocations?q=${query}`);
        yield put(actions.searchGeolocationsDone(response));
    } catch (e) {
        console.error(e);
        yield put(actions.searchGeolocationsError(errTrans(e)));
    }
}

function* deleteUser(action) {
    const {slug, params} = action.payload;
    try {
        const [response, _] = yield deleteApi(`users/${slug}`, params);
        yield put(actions.deleteUserDone(response.get('success', false),
                                         response.get('message')));
    } catch (e) {
        console.error(e);
        yield put(actions.deleteUserError(slug, errTrans(e)));
    }
}

function* fetchProfileImages(action) {
    try {
        const [response, _] = yield getApi(`profile_images`);
        yield put(actions.fetchProfileImagesDone(
            response.get("images"),
            response.get("current_image_id"),
            response.get("upload_quota"),
        ));
    } catch (e) {
        console.error(e);
        yield put(actions.fetchProfileImagesError(errTrans(e)));
    }
}

function* fetchUserAccountData(action) {
    try {
        const [response, _] = yield getApi('settings');
        yield put(actions.fetchUserAccountDataDone(response));
    } catch (e) {
        console.error(e);
        yield put(actions.fetchUserAccountDataError(errTrans(e)));
    }
}

function* saveUserAccountData(action) {
    const {data} = action.payload;
    try {
        const [response, _] = yield patchApi('settings', {
            settings: data
        });
        yield put(actions.saveUserAccountDataDone(response));
    } catch (e) {
        console.error(e);
        yield put(actions.saveUserAccountDataError(errTrans(e)));
    }
}

function* deleteOwnAccount(action) {
    try {
        const [response, _] = yield deleteApi('signup');
        yield put(actions.deleteOwnAccountDone());
    } catch (e) {
        console.error(e);
        yield put(actions.deleteOwnAccountError(errTrans(e)));
    }
}

function* deleteProfileImage(action) {
    const {sid} = action.payload;
    try {
        const [response, _] = yield deleteApi(`profile_images/${sid}`);
        yield put(actions.deleteProfileImageDone(sid, response.get("upload_quota")));
    } catch (e) {
        let uploadQuota = null;
        try {
            uploadQuota = e.upload_quota ?? null;
        } catch (err) {}
        yield put(actions.showToast("Das Bild konnte nicht gelöscht werden", "Aktion gescheitert!"));
        yield put(actions.deleteProfileImageError(errTrans(e), uploadQuota));
    }
}

function* changePassword(action) {
    const {oldpass, newpass, newpassconfirm} = action.payload;
    try {
        const [response, _] = yield patchApi('signup', {user: {
            password: newpass,
            password_confirmation: newpassconfirm,
            current_password: oldpass,
        }});
        yield put(actions.changePasswordDone());
    } catch (e) {
        console.error(e);
        yield put(actions.changePasswordError(maybeImmutableError(e)));
    }
}

function* sendResetPasswordInstructions(action) {
    const {email} = action.payload;
    try {
        const [response, _] = yield postApi('password', {user: {email}});
        yield put(actions.sendResetPasswordInstructionsDone(response.get('message')));
    } catch (e) {
        console.error(e);
        yield put(actions.sendResetPasswordInstructionsError(maybeImmutableError(e)));
    }
}

function* sendNewPassword(action) {
    const {token, password, password_confirmation} = action.payload;
    try {
        const [response, _] = yield putApi('password', {user: {
            reset_password_token: token,
            password, password_confirmation,
        }}, {addAUD: true});
        yield put(actions.sendNewPasswordDone(response.get("message")));
        yield put(actions.authenticateDone(response.get('auth')));
        yield put(actions.connectWs());
    } catch (e) {
        console.error(e);
        yield put(actions.sendNewPasswordError(maybeImmutableError(e)));
    }
}

//function* messages(){
//    let i = 0;
//    while(true){
//        yield put(actions.showToast("Test #"+i, "Blimblumbam"));
//        i += 1;
//        yield delay(10000 + Math.random() * 20000);
//    }
//}

export default function* rootSaga() {
    yield takeEvery(constants.FETCH_INITIAL_DATA, setup);
    yield takeEvery(constants.AUTHENTICATE, authenticate);
    yield takeEvery(constants.DEAUTHENTICATE, deauthenticate);
    yield takeEvery(constants.CREATE_ACCOUNT, createAccount);
    yield takeEvery(constants.SEND_CONFIRMATION, sendConfirmation);
    yield takeEvery(constants.SEND_RECONFIRMATION, sendReconfirmation);
    yield takeEvery(constants.FETCH_PROFILE, fetchProfile);
    yield takeEvery(constants.FETCH_PROFILE_EDIT, fetchProfileEdit);
    yield takeEvery(constants.SAVE_PROFILE, saveProfile);
    yield takeEvery(constants.SAVE_PROFILE_EDIT, saveProfileEdit);
    yield takeEvery(constants.FETCH_PAGES, fetchPages);
    yield takeEvery(constants.FETCH_ONLINE_USERS, fetchOnlineUsers);
    yield takeEvery(constants.SEARCH_USERS, searchUsers);
    yield takeEvery(constants.SEARCH_USERS_ONLINE, searchUsersOnline);
    yield takeEvery(constants.SEARCH_GROUPS, searchGroups);
    yield takeEvery(constants.SAVE_PAGE, savePage);
    yield takeEvery(constants.FETCH_QUOTATIONS, fetchQuotations);
    yield takeEvery(constants.SAVE_QUOTATION, saveQuotation);
    yield takeEvery(constants.DELETE_QUOTATION, deleteQuotation);
    yield takeEvery(constants.DELETE_CHAT, deleteChat);
    //maybe first?
    yield takeEvery(constants.FETCH_NEXT_PROFILE_COMMENTS, fetchNextProfileComments);
    yield takeEvery(constants.FETCH_NEXT_GROUP_COMMENTS, fetchNextGroupComments);
    yield takeEvery(constants.SEND_PROFILE_COMMENT, sendProfileComment);
    yield takeEvery(constants.SEND_GROUP_COMMENT, sendGroupComment);
    yield takeEvery(constants.FETCH_MOD_TASKS, fetchModTasks);
    yield takeEvery(constants.FETCH_MOD_TASK, fetchModTask);
    yield takeEvery(constants.SEND_MOD_TASKS_ACTION, sendModTasksAction);
    yield takeEvery(constants.FETCH_GROUP, fetchGroup);
    yield takeEvery(constants.OPEN_NAVIGATION_EDIT, openNavigationEdit);
    yield takeEvery(constants.SAVE_NAVIGATION_EDIT, saveNavigationEdit);
    yield takeEvery(constants.OPEN_PAGE_EDIT, openPageEdit);
    yield takeEvery(constants.SAVE_PAGE_EDIT, savePageEdit);
    yield takeEvery(constants.DELETE_PAGE, deletePage);
    yield takeEvery(constants.SAVE_GROUP, saveGroup);
    yield takeEvery(constants.FETCH_USER_EDIT, fetchUserEdit);
    yield takeEvery(constants.SEND_USER_INVITATION, sendUserInvitation);
    yield takeEvery(constants.ACCEPT_INVITATION, acceptInvitation);
    yield takeEvery(constants.DELETE_GROUP_COMMENT, deleteGroupComment);
    yield takeEvery(constants.DELETE_GROUP, deleteGroup);
    yield takeEvery(constants.FETCH_MOD_REVIEWS, fetchModReviews);
    yield takeEvery(constants.FETCH_MOD_REVIEW, fetchModReview);
    yield takeLatest(constants.SEARCH_GEOLOCATIONS, searchGeolocations);
    yield takeLatest(constants.DELETE_USER, deleteUser);
    yield takeLatest(constants.FETCH_PROFILE_IMAGES, fetchProfileImages);
    yield takeEvery(constants.FETCH_USER_ACCOUNT_DATA, fetchUserAccountData);
    yield takeEvery(constants.SAVE_USER_ACCOUNT_DATA, saveUserAccountData);
    yield takeEvery(constants.DELETE_OWN_ACCOUNT, deleteOwnAccount);
    yield takeLatest(constants.CHANGE_PASSWORD, changePassword);
    yield takeEvery(constants.DELETE_PROFILE_IMAGE, deleteProfileImage);
    yield takeEvery(constants.SEND_RESET_PASSWORD_INSTRUCTIONS, sendResetPasswordInstructions);
    yield takeEvery(constants.SEND_NEW_PASSWORD, sendNewPassword);
    yield takeEvery(constants.DELETE_PROFILE_COMMENT, deleteProfileComment);
    //yield fork(messages);
}