
export function connect(consumer, handler=null) {
    return consumer.subscriptions.create("ConversationsChannel", {
        connected() {
            // Called when the subscription is ready for use on the server
            //console.log("WS connected conversations", this, arguments);
            if (handler && handler.connected) {
                handler.connected();
            }
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
            //console.log("WS disconnected conversations");
            if (handler && handler.disconnected) {
                handler.disconnected();
            }
        },

        received(data) {
            // Called when there's incoming data on the websocket for this channel
            //console.log("WS conversations received:", data);
            if (handler && handler.received) {
                handler.received(data);
            }
        },

        createConversation(recipient, message) {
          this.perform('open', {
              with: recipient,
              message,
          });
        }
    });
};
