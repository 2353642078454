import React, {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {Segment} from 'semantic-ui-react';

const LogoSegment = ({hidden=false}) => {
    const location = useLocation();
    const logoBottomRef = useRef(null);
    useEffect(() => {
        const ref = logoBottomRef.current;
        if (!ref) return;
        const container = window;
        const rect = ref.getBoundingClientRect();
        const scrollY = container.pageYOffset || document.documentElement.scrollTop;
        if (scrollY > 100) {
            const y = -100 + rect.top + scrollY;
            try {
                container.scrollTo({top: y, behavior: 'smooth'});
            } catch (e) {
                container.scrollTo(0, y);
            }
        }
    }, [location.pathname]);
    return (
        <>
            {!hidden?<Segment className="logo-segment">
                <img src="/images/kd_logo.svg" />
            </Segment>:null}
            <div ref={logoBottomRef}></div>
        </>
    );
};

export default LogoSegment;
