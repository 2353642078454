import 'core-js';
import 'raf/polyfill';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import App from '../components/App';
import {ViewMode} from "../contexts";

import configureStore from "../store";
const store = configureStore();

class MainComponent extends React.PureComponent {
    render() {
        return (
            <Provider store={store}>
                <ViewMode>
                    <App />
                </ViewMode>
            </Provider>
        );
    }
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Router>
        <Route path="/" component={MainComponent} />
    </Router>,
    document.body.appendChild(document.getElementById('approot')),
  )
})
