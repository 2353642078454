import React, {useRef, useEffect, useState} from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import {Label} from "semantic-ui-react";

import {isPresent, clamp, LiRef} from "../../helpers";

let hasTouch;
function testOutTouch() {
    if (hasTouch === undefined) {
        try {
            document.createEvent('TouchEvent');
            hasTouch = true;
        } catch (e) {
            hasTouch = false;
        }
    }
    return hasTouch;
}

const touchEventMap = {
    'up': 'touchend',
    'down': 'touchstart',
    'move': 'touchmove',
};

const touchEvent = (dir) => {
    if(testOutTouch()) {
        return touchEventMap[dir];
    } else {
        return "mouse" + dir;
    }
};

const touchX = (ev) => {
    if(testOutTouch()) {
        const touches = ev.changedTouches;
        return touches[0].clientX;
    } else {
        return ev.clientX;
    }
};

const Tracks = ({positions, circles, constraints}) => {
    const thumbWidth = `${constraints.thumbWidth}px`;
    const lo = `${constraints.paddingLeft}px`;
    const ro = `${constraints.paddingRight}px`;
    if (circles && positions.length > 0) {
        return positions.map(p => {
            const lerpPos = `(100% - ${lo} - ${ro}) * ${p.ratio}`;
            const style = {
                width: thumbWidth,
                left: `calc(${lo} + ${lerpPos} - ${thumbWidth} * 0.5)`,
            };
            return (<div className="track" style={style} key={p.value}></div>);
        });
    } else {
        return (<div className="track"></div>);
    }
};

const Fill = ({show, constraints, posRatio}) => {
    if (!show) {
        return null;
    }
    const lo = `${constraints.paddingLeft}px`;
    const ro = `${constraints.paddingRight}px`;
    const lerpPos = `(100% - ${lo} - ${ro}) * ${1-posRatio}`;
    return (
        <div className="track-fill" style={{
            left: "0%",
            right: `calc(${lo} + ${lerpPos})`,
        }} />
    );
};

const Thumb = ({posRatio, constraints}) => {
    const refThumb = useRef(null);
    const thumbWidth = `${constraints.thumbWidth}px`;
    const lo = `${constraints.paddingLeft}px`;
    const ro = `${constraints.paddingRight}px`;
    const lerpPos = `(100% - ${lo} - ${ro}) * ${posRatio}`;
    return (
        <div className="thumb" style={{
            left: `calc(${lo} + ${lerpPos} - ${thumbWidth} * 0.5)`,
            right: "auto"
        }} ref={refThumb}></div>
    );
};

const SLabel = ({position, constraints}) => {
    const refLabel = useRef(null);
    const {value, label, ratio} = position;
    const width = refLabel.current !== undefined && refLabel.current !== null ?
        refLabel.current.clientWidth : 0;
    const leftOffset = Math.floor(constraints.paddingLeft + constraints.thumbWidth / 2);
    const rightOffset = Math.floor(constraints.paddingRight + constraints.thumbWidth / 2);
    const lo = `${leftOffset}px`;
    const ro = `${rightOffset}px`;
    const lerpPos = `(100% - ${lo} - ${ro}) * ${position.ratio}`;
    const offR = position.ratio > 0.5 ?
        Math.max(width * 0.5, width - rightOffset) :
        Math.min(width * 0.5, leftOffset);
    const style = {
        //left: `calc(max(0%, min(100% - ${width}px, ${position.ratio * 100}% - ${width}px * 0.5)))`,
        left: `calc(${lo} + ${lerpPos} - (${offR}px))`,
        //marginLeft: (position.ratio < 0.5) ? "0px" : `-${width}px`,
    };
    return (
        <Label style={style} as={LiRef} forwardedRef={refLabel}>
            {label}
        </Label>
    );
};

const Labels = ({positions, constraints}) => {
    if (positions.length > 0) {
        const items = positions.map(
            p => (isPresent(p.label)) ?
                <SLabel key={p.value} constraints={constraints} position={p}/> :
                null
        );
        return (<ul className="auto labels">{items}</ul>);
    }
    return null;
};

const useSlidingInput = (element, positions, value, constraints) => {
    const [touchDown, setTouchDown] = useState(false);
    const [bound, setBound] = useState({left: 0, width: 0});
    const curPosIdx = positions.findIndex(p => p.value === value);
    const [val, setVal] = useState();
    const [focused, setFocused] = useState(false);
    const lo = constraints.paddingLeft;
    const ro = constraints.paddingRight;
    useEffect(() => {
        if (!element.current) return;
        const handleFocus = (ev) => {
            setFocused(true);
        };
        const handleBlur = (ev) => {
            setFocused(false);
        };
        element.current.addEventListener('focus', handleFocus);
        element.current.addEventListener('blur', handleBlur);
        return () => {
            element.current.removeEventListener('focus', handleFocus);
            element.current.removeEventListener('blur', handleBlur);
        };
    }, []);
    useEffect(() => {
        const handleKeyDown = (ev) => {
            if (ev.code === "ArrowLeft") {
                if(curPosIdx !== undefined) {
                    const newPosIdx = Math.max(0, curPosIdx - 1);
                    const newPos = positions[newPosIdx];
                    setVal(newPos.ratio);
                }
            } else if (ev.code === "ArrowRight") {
                if(curPosIdx !== undefined) {
                    const newPosIdx = Math.min(positions.length - 1, curPosIdx + 1);
                    const newPos = positions[newPosIdx];
                    setVal(newPos.ratio);
                }
            }
        };
        element.current.addEventListener('keydown', handleKeyDown);
        return () => {
            element.current.removeEventListener('keydown', handleKeyDown);
        };
    }, [focused, value, positions]);
    useEffect(() => {
        if (!touchDown) {
            return;
        }
        const handleUp = (ev) => {
            if (ev.button !== undefined && ev.button !== 0) return;
            setTouchDown(false);
            ev.preventDefault();
        };
        document.addEventListener(touchEvent('up'), handleUp);
        return () => {
            document.removeEventListener(touchEvent('up'), handleUp);
        };
    }, [touchDown]);
    useEffect(() => {
        if (!element.current) return;
        const handleDown = (ev) => {
            if (!element.current) return;
            if (ev.button !== undefined && ev.button !== 0) return;
            setTouchDown(true);
            const b = element.current.getBoundingClientRect();
            setBound(b);
            const x = touchX(ev);
            const ratio = clamp((x - b.left - lo) / (b.width - lo - ro), 0, 1);
            setVal(ratio);
            element.current.focus();
            ev.preventDefault();
        };
        element.current.addEventListener(touchEvent('down'), handleDown);
        return () => {
            element.current.removeEventListener(touchEvent('down'), handleDown);
        };
    }, [element.current]);
    useEffect(() => {
        if (!touchDown) return;
        const handleMove = (ev) => {
            if (ev.button !== undefined && ev.button !== 0) return;
            const x = touchX(ev);
            const ratio = clamp((x - bound.left - lo) / (bound.width - lo - ro), 0, 1);
            setVal(ratio);
            ev.preventDefault();
        };
        document.addEventListener(touchEvent('move'), handleMove);
        return () => {
            document.removeEventListener(touchEvent('move'), handleMove);
        };
    }, [touchDown, bound]);
    return [val, setVal];
};

const parsePositionComponents = (comps) => {
    return comps.map(c => c.type(c.props)).flat().map(
        (p, i, arr) => ({...p, ratio: i / (arr.length-1)})
    );
};

const findThumbPosition = (positions, smoothValue, value) => {
    let effectivePos, curPos;
    if (smoothValue !== undefined) {
        const ratioStep = 1 / (positions.length - 1);
        effectivePos = positions[Math.round(smoothValue / ratioStep)];
    }
    curPos = positions.find(p => p.value === value);
    return [effectivePos, curPos];
};

const Slider = ({value, readOnly, circles, onChange, showFill=true, children,
                ...args}
) => {
    let classes = "ui slider cuddle";
    if (readOnly) {
        classes += " readonly";
    }
    let positions = parsePositionComponents(children);
    const constraints = {
        thumbWidth: 26,
        paddingLeft: 13,
        paddingRight: 13,
    };
    const refContainer = useRef(null);
    const [smoothValue, setSmoothValue] = readOnly ? [undefined, ()=>{}] :
        useSlidingInput(refContainer, positions, value, constraints);
    const [effectivePos, visiblePos] = findThumbPosition(positions, smoothValue, value);
    useEffect(() => {
        if (effectivePos !== undefined && !readOnly) {
            if(onChange) {
                setSmoothValue(effectivePos.ratio);
                onChange(effectivePos.value);
            }
        }
    }, [smoothValue, readOnly]);
    useEffect(() => {
        const pos = positions.find(p => p.value === value);
        if (pos !== undefined) {
            setSmoothValue(pos.ratio);
        }
    }, [value]);
    const hasLabels = positions.some(p => isPresent(p.label));
    if (hasLabels) classes += " labeled";
    return (
        <div className={classes} tabIndex={readOnly?"-1":"0"} ref={refContainer} {...args}>
            <div className="inner">
                <Tracks {...{circles, positions}} constraints={constraints} />
                <Fill show={showFill && !circles} posRatio={visiblePos.ratio}
                    constraints={constraints}
                />
                <Thumb posRatio={visiblePos.ratio} constraints={constraints} />
            </div>
            <Labels {...{positions}} constraints={constraints}/>
        </div>
    );
}

Slider.Position = ({value, label}) => {
    return [{value, label}];
};

Slider.PositionRange = ({from, to}) => {
    const positions = [];
    for(let i = from; i <= to; i++) {
        positions.push({value: i});
    }
    return positions;
};

Slider.defaultProps = {
    value: 0,
    readOnly: false,
    ticked: false,
    circles: false,
};

Slider.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    ticked: PropTypes.bool,
    circles: PropTypes.bool,
};
export default Slider;

