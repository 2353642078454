import React, { useEffect, useRef, useState, useCallback, } from 'react';
import {useLocation} from 'react-router-dom';
import Immutable from 'immutable';
import moment from 'moment/moment.js';
import {EditorState, KeyBindingUtil,} from 'draft-js';
import {convertFromHTML} from 'draft-convert';
import {ENTITY_TYPE} from 'draftail';
import {
    EDITOR_DESCRIPTIONS, EDITOR_LABELS, EDITOR_KEYBOARD_SHORTCUTS,
} from "../constants";
import platform from "platform";
const { isOptionKeyCommand } = KeyBindingUtil;

moment.locale('de');

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useDebouncedEffect(effect, delay, deps) {
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};

export function useForceUpdate() {
    const [_, updateState] = useState();
    return useCallback(() => updateState({}), []);
}

export function timeSince(date) {
  return moment(date).fromNow();
}

export function formatDate(date, format="L") {
  return moment(date).format(format);
}

export function lifetime(date, unit="seconds") {
  return moment().diff(moment(date), unit);
}

export function forwardRefAs(As) {
  return ({ forwardedRef, ...props }) => (
    <As ref={forwardedRef} {...props} />
  );
}

export const DivRef = forwardRefAs('div');
export const LiRef = forwardRefAs('li');
export const FormRef = forwardRefAs('form');
export const InputRef = forwardRefAs('input');

export function isDefined(v) {
    return v !== null && v !== undefined;
}

export function isPresent(v) {
    return isDefined(v) && v.length > 0;
}

export function isFunction(f) {
    return !!(f && f.constructor && f.call && f.apply);
};

export function errTrans(_msg) {
    if(typeof _msg !== "string") {
        return _msg;
    }
    const msg = `${_msg}`;
    if (msg.match(/.*authenti.*/)) {
        return "Du bist nicht angemeldet."
    } else if(msg.match(/^.*token malformed.*$/) ||
        msg.match(/^.*[Ss]ignature verification raised.*$/)) {
        return "Es gab ein Problem mit der Sitzung. Bitte logge dich erneut "+
               "ein. Deine Sitzung wurde vorsichtshalber beendet. "
    } else if(msg.match(/^.*not authorized.*$/)) {
        return "Dir fehlen Berechtigungen für diese Aktion."
    } else if(msg.match(/^.*[fF]ailed\sto\sfetch.*$/)) {
        return "Der Server ist nicht erreichbar. Versuchs später nochmal."
    } else if(msg.match(/^.*[Ss]ignature\shas\sexpired.*$/)) {
        return "Die Sitzung ist abgelaufen und kann nicht wiederhergestellt "+
               "werden. Bitte logge dich erneut ein."
    } else if(msg.match(/^.*revoked token*$/)) {
        return "Deine Sitzung wurde beendet. Bitte logge dich erneut ein."
    } else {
        return msg;
    }
}

export function clamp(val, min, max) {
    return Math.max(min, Math.min(max, val));
}

export function useSubset(imap, keyArray, setter) {
    return [imap.getIn(keyArray), (v) => setter(imap.setIn(keyArray, v))]
}

export function randomInt(maxInt) {
    return Math.floor(Math.random() * maxInt);
}

export function genId() {
    return Math.random().toString(36).slice(2);
};

export function makeApiListUnique(iList) {
    const indexHash = {};
    const newlist = [];
    for(const item of iList) {
        const slug = item.get('slug');
        if (indexHash.hasOwnProperty(slug)) {
            newlist[indexHash[slug]] = item;
        } else {
            indexHash[slug] = newlist.length;
            newlist.push(item);
        }
    }
    return Immutable.List(newlist);
}

const ROLE_MAP = {
    guest: 0, regular: 10, active: 20, mod: 30, admin: 40,
};
export function canISee(myrole, itemVisibility) {
    return ROLE_MAP[myrole] >= ROLE_MAP[itemVisibility];
}

const HTMLImporterConfig = {
    htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === "a") {
            return createEntity(ENTITY_TYPE.LINK, "MUTABLE", { href: node.href });
        }
        if (nodeName === "img") {
            return createEntity(ENTITY_TYPE.IMAGE, "MUTABLE", {
                src: node.src,
                alt: node.alt,
                width: node.width,
                height: node.height
            });
        }
        if (nodeName === "hr") {
            return createEntity(ENTITY_TYPE.HORIZONTAL_RULE, "IMMUTABLE", {});
        }
        //console.log('parse from html, entity skipped:', nodeName, node);
        return null;
    },
    htmlToBlock: (nodeName) => {
        if (nodeName === "hr" || nodeName === "img") {
            return "atomic";
        }
        //console.log('parse from html, block skipped:', nodeName);
        return null;
    },
};

export function fromHTML(htmlContent) {
    return convertFromHTML(HTMLImporterConfig)(htmlContent);
};

export function createEditorStateFromHTML(htmlContent) {
    if(isPresent(htmlContent)) {
        const contentState = fromHTML(htmlContent);
        return EditorState.createWithContent(contentState);
    } else {
        return EditorState.createEmpty();
    }
}

export function mapPageContent(mapWithBlocks, fn) {
    let blocks = mapWithBlocks.get('blocks');
    if(blocks && blocks.size && blocks.size > 0) {
        blocks = blocks.map(b => {
            let block = b;
            const body = block.get('body');
            block = block.set('body', fn(body));
            return mapPageContent(block, fn);
        });
    }
    return mapWithBlocks.set('blocks', blocks);
}

export function serializePageContent(page) {
    return mapPageContent(page,
        (c) => (typeof c === "object") ? JSON.stringify(c) : c
    );
}

export function parsePageContent(page) {
    return mapPageContent(page,(c) => {
        try {
            return JSON.parse(c)
        } catch (e) {
            return c;
        }
    });
}

export function findBlockPath(entries, ids, includeChildren=false) {
    const result = ids.reduce((path, id) => {
        const idx = entries.getIn(path).findIndex(n=>n.get('slug') === id);
        if(idx >= 0) {
            path.push(idx);
            path.push('blocks');
            return path;
        } else {
            return [];
        }
    }, []);

    if(!includeChildren) {
        // remove last 'blocks' path element, as we want to point to the block
        // itself, not its children blocks
        result.pop();
    }

    return result;
};

export const getButtonLabel = (type, config) => {
    const icon = typeof config === "boolean" ? undefined : config.icon;

    if (typeof config.label === "string") {
        return config.label;
    }

    if (typeof icon !== "undefined") {
        return null;
    }

    return EDITOR_LABELS[type];
};

const hasKeyboardShortcut = (type) => {
    return !!EDITOR_KEYBOARD_SHORTCUTS[type];
};

const IS_MAC_OS = isOptionKeyCommand({ altKey: "test" }) === "test";

const getKeyboardShortcut = (type, isMacOS = IS_MAC_OS) => {
    const shortcut = EDITOR_KEYBOARD_SHORTCUTS[type];
    const system = isMacOS ? "macOS" : "other";

    return (shortcut && shortcut[system]) || shortcut;
};

export const getButtonTitle = (type, config) => {
    const description =
        typeof config === "boolean" || typeof config.description === "undefined"
        ? EDITOR_DESCRIPTIONS[type]
        : config.description;
    const hasShortcut = hasKeyboardShortcut(type);
    let title = description;

    if (hasShortcut) {
        const desc = description ? `${description}\n` : "";
        title = `${desc}${getKeyboardShortcut(type)}`;
    }

    return title;
};

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const fieldErr = (errors, name) => {
    try {
        return errors.get(name).map((v, i) => <p key={i}>{v}</p>).toJS();
    } catch (e) {
        return null;
    }
};

const localeCompareSupportsLocales = () => {
    try {
        "test".localeCompare("seq", "i");
    } catch (e) {
        return e.name === "RangeError";
    }
    return false;
};

export const searchCompare = (a, b) => {
    if (localeCompareSupportsLocales()) {
        return a.localeCompare(b, "de", {sensitivity: "base"});
    } else {
        a.toLowerCase().localeCompare(b.toLowerCase());
    }
}

export const searchInclude = (a, b) => {
    const cmpA = a.toLowerCase();
    const cmpB = b.toLowerCase();
    return cmpA.includes(cmpB);
};

export const uniqBy = (list, key) => {
    return Immutable.OrderedMap(list.map(v=>[v.get(key), v])).valueSeq().toList();
};

export const sortDescBy = (list, key) => {
    return list.sortBy(x=>x.get(key)).reverse();
};

export const compare = (a, b) => {
    if (a === undefined && b === undefined) {
        return 0;
    }

    if (a === undefined) {
        return 1;
    }

    if (b === undefined) {
        return -1;
    }

    return a > b ? 1 : a < b ? -1 : 0;
};

export const sortBy = (list, key) => {
    const kar = Array.isArray(key) ? key : [key];
    return list.sort((a, b) => kar.reduce(
        (val, k) => val === 0 ? compare(a.get(k), b.get(k)) : val, 0
    ));
};

export const genAUD = () => {
    return platform.description || "Unbekanntes System";
};
