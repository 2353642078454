import React, {
    useEffect, useState, lazy, Suspense, useCallback, useRef,
} from 'react';
import Immutable from 'immutable';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router';
import LogoSegment from './ui/LogoSegment';
import {ProfileImageUploadComponent} from "./ProfileEditPage"
const GeoMap = lazy(() => import("./ui/GeoMap"));

import {
    isDefined, isPresent, fieldErr,
} from "../helpers";

import {
    Segment, Header, Form, Loader, Transition, Button, Grid, List, Dimmer, Icon,
} from 'semantic-ui-react';


import {
    fetchProfileEdit, saveProfileEdit, searchGeolocations,
} from '../actions';

import {
    isAuthed, getProfileEdit, getSearchGeolocations,
} from '../selectors';

const NextRequiredInput = ({profile, errors, saveValue}) => {
    //console.log('p', profile.toJS(), errors);
    const dispatch = useDispatch();
    const nickname = profile.get('nickname');
    const geolocation = profile.get('geolocation');
    const profileImageUrl = profile.get('profile_image_url');
    const [shakeToggler, setShakeToggler] = useState(true);
    const [value, setValue] = useState({});
    const [locInput, setLocInput] = useState("");
    const geolocationSearch = useSelector(getSearchGeolocations);
    console.log("geo", geolocationSearch);
    const searchLocation = useCallback((query) => {
        dispatch(searchGeolocations(query));
        setLocInput("")
    }, []);
    const [uploadControls, setUploadControls] = useState({
        ready: false,
        uploadFn: ()=>{}
    });
    let control;
    let missingCount = 0;
    let currentValue = null;
    let currentAction = () => saveValue(value);
    if(!isPresent(nickname)) {
        control = (<>
            <div className="profile-name next-required-input">
                <Transition animation="shake" visible={shakeToggler}>
                    <div>
                        <div className="label cuddle-light">Name</div>
                        <Form.Input fluid value={value["nickname"]} className="cuddle-light"
                            onChange={(ev, data) => setValue({nickname: data.value})}
                            error={fieldErr(errors, "nickname")}
                            data-cy="name-input"
                        />
                    </div>
                </Transition>
            </div>
        </>);
        currentValue = value["nickname"];
        missingCount ++;
    }
    if(!isDefined(geolocation) || !isPresent(geolocation.get("geoid"))) {
        control = control || (
            <Grid stackable className="next-required-input profile-location">
                <Grid.Column width={8} className="location-map">
                    <Suspense fallback={<Loader active />}>
                        <GeoMap locations={isDefined(value.geolocation) ? [{
                            lon: value.geolocation.get("longitude"),
                            lat: value.geolocation.get("latitude"),
                            radius: 4,
                            key: value.geolocation.get("geoid"),
                        }] : []} />
                    </Suspense>
                </Grid.Column>
                <Grid.Column width={8} className="location-search">
                    <Form.Input fluid className="cuddle-light search-input"
                        icon={true}
                        error={fieldErr(errors, "geolocation")}
                    >
                        <input type="text" enterkeyhint="search"
                            onChange={(ev) => setLocInput(ev.target.value)}
                            placeholder="Ort Suchen..."
                            onKeyDown={(ev) => ev.key === "Enter" &&
                                isPresent(locInput) && ev.target.blur()
                            }
                            onBlur={() => isPresent(locInput) && searchLocation(locInput)}
                            data-cy="location-input"
                            value={locInput}
                        />
                        <Icon name="search" />
                    </Form.Input>
                    <Dimmer.Dimmable as={List} className="location-list"
                        divided relaxed data-cy="location-options"
                    >
                        {geolocationSearch.get("result").map(loc =>
                            <List.Item as="a" key={loc.get("geoid")}
                                active={value.geolocation === loc}
                                onClick={() => setValue({geolocation: loc})}
                            >
                                <List.Header>{loc.get("name")}</List.Header>
                                <List.Description>{loc.get("is_in")}</List.Description>
                            </List.Item>
                        )}
                        {geolocationSearch.get("result").size === 0 ? (
                            <List.Item as="a" className="empty">
                                <List.Header>Nichts gefunden...</List.Header>
                                <List.Description>Bitte erneut suchen</List.Description>
                            </List.Item>
                        ) : null}
                        <Dimmer inverted active={geolocationSearch.get("loading")}>
                            <Loader active/>
                        </Dimmer>
                        <Dimmer inverted active={isPresent(geolocationSearch.get("error"))}>
                            {geolocationSearch.get("error")}
                        </Dimmer>
                    </Dimmer.Dimmable>
                </Grid.Column>
            </Grid>
        );
        currentValue = currentValue || value["geolocation"]?.get("geoid");
        missingCount ++;
    }
    if(!isPresent(profileImageUrl) || `${profileImageUrl}`.includes("unset_avatar.svg")){
        control = control || (
            <div className="next-required-input profile-image">
                <ProfileImageUploadComponent
                    onUpload={(sids) => saveValue({profile_image: sids && sids[0]})}
                    controls={(ctrls) => setUploadControls(ctrls)}
                />
            </div>
        );
        currentValue = currentValue || uploadControls.ready && "ready";
        if (uploadControls.ready) {
            currentAction = uploadControls.uploadFn;
        }
        missingCount ++;
    }
    if(!control) {
        control = <Redirect to="/profile/edit" />;
    }
    return (
        <div className="profile-completion">
            {control}
            <Button className="cuddle"
                onClick={() => currentAction()}
                disabled={!isPresent(currentValue)}
                data-cy="next-button"
            >
                Weiter
            </Button>
        </div>
    );
};

const ProfileCompletionPage = () => {
    const authed = useSelector(isAuthed);
    const dispatch = useDispatch();
    useEffect(() => {
        if(authed) {
            dispatch(fetchProfileEdit());
        }
    }, [authed]);
    const profileEdit = useSelector(getProfileEdit);
    if (!authed) {
        return <Redirect to="/" />;
    }
    const errors = profileEdit.get('error');
    const profile = profileEdit.get('result');
    const isLoading = profileEdit.get('loading') || !isDefined(profile);
    return (<>
        <LogoSegment />
        <Header as="h1" className="cuddle profile" textAlign="center">
            Profil vervollständigen
        </Header>
        <Segment className="profile edit" inverted>
            <Form error={isDefined(errors)}>
                {isLoading ?
                    <Loader active />:
                    <NextRequiredInput profile={profile}
                        errors={errors}
                        saveValue={(val) => dispatch(
                            saveProfileEdit(Immutable.fromJS(val))
                        )}
                    />
                }
            </Form>
        </Segment>
    </>);
};

export default ProfileCompletionPage;