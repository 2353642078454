import React from 'react';
import {connect} from "react-redux";
import Page from './Page';
import OnlineUsers from './OnlineUsers';

class LandingPage extends React.PureComponent {
    componentDidMount(){
    }

    render() {
        return (
            <Page slug="home" />
        );
    }
}

LandingPage.defaultProps = {};
LandingPage.propTypes = {
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
