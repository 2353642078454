import React, {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {NavLink} from "react-router-dom";

import {deauthenticate} from "../actions";
import {isAuthed} from "../selectors";

import {Segment, Loader} from "semantic-ui-react";

const LogoutPage = ({}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(deauthenticate())
    }, []);
    const authed = useSelector(isAuthed);
    return (
        <Segment className="cuddle_bright" textAlign="center">
            <h3 className="my-3">Abmelden</h3>
            <Loader size="large" indeterminate active={authed} />
            {!authed && (<>
                <p>
                    Deine Sitzung wurde erfolgreich beendet.
                </p>
                <NavLink to="/">Zurück zur Startseite</NavLink>
            </>)}
        </Segment>
    );
}

LogoutPage.defaultProps = {};
LogoutPage.propTypes = {
};

export default LogoutPage;
