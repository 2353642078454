import React, {useState} from 'react';
import { Button, Icon, } from 'semantic-ui-react';

const ConfirmButton = ({basic, icon, children, onClick, ...props}) => {
    const [clicked, setClicked] = useState(false);
    return (
        <Button {...props}
            basic={clicked?false:basic}
            icon={clicked?null:icon}
            onClick={e=>onClick && (clicked?onClick(e):setClicked(true))}
            onBlur={() => onClick && setClicked(false)}
            onMouseLeave={() => onClick && setClicked(false)}
        >
            {clicked?
                <span className="text red">
                    <Icon name="warning sign" />
                    Bestätigen?
                </span>
                : children
            }
        </Button>
    );
};

export default ConfirmButton;