import React, {useEffect, useState, useRef, useCallback, } from 'react';
import Immutable from "immutable";
import {useSelector, useDispatch} from 'react-redux';
import {NavLink} from "react-router-dom";

import {ConfirmButton} from "../ui";

import {
    fetchUserEdit, sendUserInvitation, showToast, deleteUser,
} from "../../actions";
import {
    getUserEdit, getUserInvite, getUserDelete,
} from "../../selectors";
import { VISIBILITY_OPTIONS } from "../../constants";

import {
    isPresent, fieldErr, FormRef, isDefined, formatDate,
} from "../../helpers";

import {
    Table, Button, Icon, Dimmer, Loader, Header, Modal, Form, Message, Popup,
    Segment, Accordion, Transition,
} from 'semantic-ui-react';

const Date = ({d}) => {
    if (isPresent(d)) {
        return formatDate(d, "LL");
    } else {
        return "-";
    }
};

const Events = ({user}) => {
    return (
        <Table basic compact definition singleLine>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Einladung verschickt am</Table.Cell>
                    <Table.Cell><Date d={user.get('invitation_sent_at')} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Einladung angenommen am</Table.Cell>
                    <Table.Cell><Date d={user.get('invitation_accepted_at')} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Bestätigung verschickt am</Table.Cell>
                    <Table.Cell><Date d={user.get('confirmation_sent_at')} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Bestätigt am</Table.Cell>
                    <Table.Cell><Date d={user.get('confirmed_at')} /></Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

const DeleteUserButton = ({user, onClick}) => {
    const mayDelete = user.get("role") !== "admin";
    return (
        <Button
            className="cuddle"
            data-cy="delete-op"
            disabled={!mayDelete}
            onClick={onClick}
        >
            Löschen
        </Button>
    );
};

const UserEditRow = ({index, user, activeIndex, setIndex, openDeleteDialog}) => {
    const active = activeIndex === index;
    const profileURL = `/profile/${user.get('slug')}`;
    return (<>
        <Accordion.Title as={Table.Row} active={active}
            index={index}
            onClick={(e, data) => setIndex(active ? -1 : index)}
        >
            <Table.Cell singleLine>
                {active ? <Icon name="angle down" /> : <Icon name="angle right" />}
                {user.get('id')}
            </Table.Cell>
            <Table.Cell className="wordBreak">
                <NavLink title={user.get('email')} to={profileURL}>
                    {user.get('email') !== null && user.get('email') !== '' ? (user.get('email')).substring(0, 18)+'...' : ''}
                </NavLink>
            </Table.Cell >
            <Table.Cell className="wordBreak">
                <NavLink title={user.get('nickname')} to={profileURL}>
                    {user.get('nickname') !== null && user.get('nickname') !== '' ? (user.get('nickname')).substring(0, 12)+'...' : ''}
                </NavLink>
            </Table.Cell>
            <Table.Cell >{user.get('location')}</Table.Cell>
            <Table.Cell >{user.get('role')}</Table.Cell>
        </Accordion.Title>
        <Transition visible={active} animation="slide down">
        <Accordion.Content as={Table.Row} active={active}>
            <Table.Cell colSpan={5}>
                <Table compact definition>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Erstellt am</Table.Cell>
                            <Table.Cell><Date d={user.get('created_at')} /></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Ereignisse</Table.Cell>
                            <Table.Cell>
                                <Events user={user} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Anfragen</Table.Cell>
                            <Table.Cell>{user.get('sign_in_count')}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Letzte Anfrage am</Table.Cell>
                            <Table.Cell><Date d={user.get('current_sign_in_at')} /></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Letzte IP-Adresse</Table.Cell>
                            <Table.Cell>{user.get('current_sign_in_ip')}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell colSpan="2">
                                <DeleteUserButton user={user}
                                    onClick={openDeleteDialog}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Table.Cell>
        </Accordion.Content>
        </Transition>
    </>);
};

const DELETE_USER_PARAMS = Immutable.fromJS({
    inform_email: "",
    feedback_text: "",
    user_slug: "",
});

const DeleteUserDialog = ({user, open=false, onClose}) => {
    const dispatch = useDispatch();
    const [params, setParams] = useState(DELETE_USER_PARAMS);
    const onSubmit = useCallback((ev, d) => {
        ev.preventDefault();
        if(isPresent(params.get("user_slug"))){
            const slug = params.get('user_slug');
            const data = params.delete('user_slug');
            dispatch(deleteUser(slug, data));
        }
        return false;
    }, [params]);
    const userDelete = useSelector(getUserDelete);
    useEffect(() => {
        if(userDelete.get('success')) {
            onClose();
            dispatch(showToast(userDelete.get('message'), "Profil gelöscht"));
        }
    }, [userDelete]);
    useEffect(() => {
        if(isDefined(user)){
            setParams(DELETE_USER_PARAMS.set("user_slug", user.get("slug"))
                                        .set("inform_email", user.get("email")));
        }
    }, [user]);
    const isLoading = userDelete.get('loading') || false;
    const errors = userDelete.get('error');
    const formRef = useRef(null);
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Profil löschen</Modal.Header>
            <Modal.Content>
                <p>
                    <span className="ui text red">
                        Diese Operation ist unumkehrbar.
                    </span>
                    <br />
                    <span className="ui text small">
                        In der Datenbank wird der Profileintrag überschrieben.
                        Die Verweise bleiben gültig, aber die personenbezogenen
                        Daten gehen verloren. Hochgeladene Bilder,
                        Profilbewertungen und Gruppenkommentare werden gelöscht.
                        Chatnachrichten werden überschrieben.
                    </span>
                </p>
                <p>
                    <span className="ui text small">
                        Nachdem das Profil gelöscht wurde, wird an die E-Mail
                        (falls angegeben) eine Benachrichtigung geschickt. Wenn
                        die Person einen Grund oder Erklärung für den Wunsch,
                        eigenes Profil zu löschen, angegeben hat, so können
                        diese im Feedbackfeld eingetragen werden.
                    </span>
                </p>
                <Form as={FormRef} forwardedRef={formRef}
                    onSubmit={onSubmit} error={isDefined(errors)}
                >
                    <Form.Input title="E-Mail für die Benachrichtigung"
                        value={params.get('inform_email')}
                        type="email" label="E-Mail"
                        data-cy="receiver-email-input"
                        onChange={(ev, data) =>
                            setParams(params.set('inform_email', data.value))
                        }
                    />
                    <Form.TextArea title="Feedback"
                        value={params.get("feedback_text")}
                        label="Feedback"
                        data-cy="feedback-input"
                        onChange={(ev, data) =>
                            setParams(params.set("feedback_text", data.value))
                        }
                    />
                    {isPresent(errors)?<Message error>{errors}</Message>:null}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} data-cy="dialog-cancel">
                    Abbrechen
                </Button>
                <ConfirmButton className="cuddle" basic color="red"
                    data-cy="dialog-delete-action"
                    title="Löschen"
                    onClick={()=> formRef.current &&
                        formRef.current.dispatchEvent(new Event('submit'))}>
                    Löschen
                </ConfirmButton>
            </Modal.Actions>
            <Dimmer active={isLoading} inverted>
                <Loader active />
            </Dimmer>
        </Modal>
    );
};

const NEW_INVITE_USER = Immutable.fromJS({
     email: "",
     role: "regular",
});

const InviteUserDialog = ({open=false, onClose}) => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(NEW_INVITE_USER);
    const onSubmit = useCallback((ev, d) => {
        // TODO: validate field first maybe?
        // (seems browser is silent sometimes)
        ev.preventDefault();
        dispatch(sendUserInvitation(user));
        return false;
    }, [user]);
    const userInvite = useSelector(getUserInvite);
    useEffect(() => {
        if(userInvite.get('success')) {
            setUser(NEW_INVITE_USER);
            dispatch(showToast(userInvite.get('message'), "Einladung verschickt"));
        }
    }, [userInvite]);
    const isLoading = userInvite.get('loading') || false;
    const errors = userInvite.get('error');
    const formRef = useRef(null);
    const {guest: _guest, ...roleOptions} = VISIBILITY_OPTIONS;
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Eine Person einladen</Modal.Header>
            <Modal.Content>
                <p>
                    Es wird ein inaktives Account für diese Person angelegt und
                    die Person wird per E-Mail eingeladen, ihr Account zu
                    vervollständigen und zu aktivieren.
                </p>
                <Form as={FormRef} forwardedRef={formRef}
                    onSubmit={onSubmit} error={isDefined(errors)}
                >
                    <Form.Input required title="E-Mail" value={user.get('email')}
                        type="email" label="E-Mail"
                        error={fieldErr(errors, 'email')}
                        onChange={(ev, data) =>
                            setUser(user.set('email', data.value))
                        }
                    />
                    <Form.Select scrolling
                        label="Role" required value={user.get('role')}
                        options={Object.values(roleOptions)}
                        onChange={(ev, data) =>
                            setUser(user.set('role', data.value))
                        }
                    />
                    {isPresent(errors)?<Message error>{errors}</Message>:null}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>
                    Abbrechen
                </Button>
                <Button className="cuddle"
                    onClick={()=> formRef.current &&
                        formRef.current.dispatchEvent(new Event('submit'))}>
                    Einladen
                </Button>
            </Modal.Actions>
            <Dimmer active={isLoading} inverted>
                <Loader active />
            </Dimmer>
        </Modal>
    );
};

const UserAdmin = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUserEdit());
    }, []);
    const [activeRow, setActiveRow] = useState(-1);
    const [showInviteDialog, setShowInviteDialog] = useState(false);
    const [deleteDialogUser, setDeleteDialogUser] = useState(null);
    const userEdit = useSelector(getUserEdit);
    const errorMsg = userEdit.get('error');
    const isLoading = userEdit.get('loading', false);
    const items = userEdit.get('users');
    return (<div className="admin-users">
        <Header as="h1">Benutzerverwaltung</Header>
        <Segment attached="top" textAlign="right" color="teal">
            # Benutzerkonten: {items.size}
        </Segment>
        <Accordion as={Table} celled striped className="users-list" attached="bottom">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>E-Mail</Table.HeaderCell>
                    <Table.HeaderCell>Nickname</Table.HeaderCell>
                    <Table.HeaderCell>Ort</Table.HeaderCell>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map((u, i) => <UserEditRow
                    index={i}
                    user={u}
                    activeIndex={activeRow}
                    setIndex={setActiveRow}
                    openDeleteDialog={() => setDeleteDialogUser(u)}
                    key={u.get('slug')}
                />)}
                <Table.Row>
                    <Table.Cell colSpan="9">
                        <Dimmer active={isPresent(errorMsg) || isLoading} inverted>
                            {isPresent(errorMsg) ? (
                                <Header as='h2' icon>
                                    <Icon name='settings' />
                                    Oops! Da ist etwas schief gelaufen.
                                    <Header.Subheader>
                                        {errorMsg}
                                    </Header.Subheader>
                                </Header>
                            ) : (
                                <Loader inverted>Wird geladen...</Loader>
                            )}
                        </Dimmer>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.Cell colSpan="9">
                        <Button floated='right' icon labelPosition='left'
                            className="cuddle"
                            size='small' onClick={() => setShowInviteDialog(true)}
                            title="per E-Mail einladen"
                        >
                            <Icon name='plus' /> Einladen
                        </Button>
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        </Accordion>
        <InviteUserDialog open={showInviteDialog}
            onClose={() => setShowInviteDialog(false)}
        />
        <DeleteUserDialog user={deleteDialogUser}
            open={deleteDialogUser !== null}
            onClose={() => setDeleteDialogUser(null)}
        />
    </div>);
};


export default UserAdmin;
