import React, {useState, useEffect, useCallback} from 'react';
import Immutable from 'immutable';
import {
    sortableContainer, sortableElement,
} from "react-sortable-hoc";
import {useSelector, useDispatch} from "react-redux";

import { Menu, Popup, Form, Button, } from "semantic-ui-react";

import {getMenuEdit, getPages} from "../../selectors";
import {
    moveNavigationItem, changeNavigationItem, newNavigationItem,
} from "../../actions";

import {
    LOCATION_OPTIONS, VISIBILITY_OPTIONS,
} from "../../constants";


const NavEdit = ({item, onChange}) => {
    const pages = useSelector(getPages);
    return (
        <Form>
            <Form.Checkbox toggle label="Aktiviert" tabIndex={0} checked={item.get('active')}
                onChange={(e, data) => onChange(item.set('active', data.checked))}
            />
            <Form.Checkbox toggle label="Löschen" tabIndex={0} checked={item.get('_delete')}
                disabled={item.get('internal')}
                onChange={(e, data) => onChange(item.set('_delete', data.checked))}
                title="Wird beim Speichern gelöscht"
            />
            <Form.Input label="Text" required value={item.get('title')}
                onChange={(e) => onChange(item.set('title', e.target.value))}
            />
            <Form.Input label="Pfad" required value={item.get('path')}
                placeholder="/p/pagename"
                onChange={(e) => onChange(item.set('path', e.target.value))}
            />
            <Form.Select options={Object.values(LOCATION_OPTIONS)} label="Position"
                value={item.get('location')} required
                onChange={(e, data) => onChange(item.set('location', data.value))}
            />
            <Form.Select options={
                pages.valueSeq().map(p => ({
                    key: p.get('slug'),
                    text: p.get('title'),
                    value: p.get('slug'),
                })).toArray()} label="Seite" value={item.get('page')} required
                onChange={(e, data) => onChange(item.set('page', data.value))}
            />
            <Form.Select options={Object.values(VISIBILITY_OPTIONS)} label="Sichtbarkeit"
                value={item.get('visibility')} required
                onChange={(e, data) => onChange(item.set('visibility', data.value))}
            />
        </Form>
    );
};

const NavItem = ({item, onChange, editDirection}) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <Popup
            trigger={
                <Menu.Item name={item.get('title')} className="nav-sort-item">
                    {item.get('title')}
                </Menu.Item>
            }
            content={<NavEdit item={item} onChange={onChange}/>}
            on='click'
            open={isOpen}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            position={editDirection}
        />
    );
};

const SortableItem = sortableElement(
    ({item, onChange}) => <NavItem item={item} onChange={onChange}/>
);
const SortableContainer = sortableContainer(
    ({children}) => <Menu secondary>{children}</Menu>
);

const AdminNavItems = ({location="top"}) => {
    const dispatch = useDispatch();
    const menuEdit = useSelector(getMenuEdit);
    const items = menuEdit.getIn(['entries', location]);
    return (
        <Menu.Item className="nav-root-item">
            <SortableContainer onSortEnd={({oldIndex, newIndex}) =>
                    dispatch(moveNavigationItem(oldIndex, newIndex, location))
                }
                axis="x" lockAxis="x" pressDelay={200}
                shouldCancelStart={() => menuEdit.get('saving')}
            >
                {items.map((m, i) =>
                    <SortableItem key={m.get('slug')}
                        item={m} index={i}
                        editDirection={location === "top" ? "bottom center" : "top center"}
                        onChange={(it) => dispatch(changeNavigationItem(i, it, location))}
                    />
                )}
                <Button icon="add" className="tertiary"
                    onClick={() => dispatch(newNavigationItem(location))}
                />
            </SortableContainer>
        </Menu.Item>
    )
};

export default AdminNavItems;