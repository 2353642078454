import Immutable from 'immutable';
import * as constants from "./constants";
import * as actions from "./actions";
import {isPresent, genId} from "./helpers";

import { createConsumer } from "@rails/actioncable"
import {connect as connectConversations} from "./channels/conversations_channel";
import {connect as connectMessages} from "./channels/messages_channel";
import {connect as connectNotification} from "./channels/notification_channel";
import {connect as connectGlobal} from "./channels/global_channel";

import {getAuthToken} from "./selectors";

const createConversationsHandler = (dispatch) => {
    return {
        connected: () => {
            dispatch(actions.setChatOnline(true));
        },
        disconnected: () => {
            dispatch(actions.setChatOnline(false));
        },
        received: (msg) => {
            let result = null;
            switch (msg.msg_type) {
            case 'list':
                result = Immutable.fromJS(msg.conversations);
                return dispatch(actions.fetchConversationsDone(result));
            case 'open':
                result = Immutable.fromJS(msg.conversation);
                return dispatch(actions.syncConversationOpen(msg.id, result));
            default:
                console.error("Unknown websocket message on conversation channel received:", msg);
            }
        }
    };
};

const createMessagesHandler = (dispatch, conversationId) => {
    return {
        connected: () => {
        },
        disconnected: () => {
        },
        received: (msg) => {
            let result = null;
            switch (msg.msg_type) {
            case 'list':
                result = Immutable.fromJS(msg.messages);
                return dispatch(
                    actions.syncMessages(conversationId, result));
            case 'new':
                result = Immutable.fromJS(msg.message);
                return dispatch(actions.syncNewMessage(
                    conversationId, result, msg.key));
            case 'last_seen':
                // TODO: handle lastSeen response
                break;
            default:
                console.error("Unknown websocket message on message channel received:", msg);
            }
        }
    };
};

const createNotificationHandler = (dispatch) => {
    return {
        connected: () => {
        },
        disconnected: () => {
        },
        received: (msg) => {
            let result = null;
            //console.log('notification handler msg type', msg.msg_type, msg);
            switch (msg.msg_type) {
            case 'unread_message_count':
                result = Immutable.fromJS(msg.message_count);
                return dispatch(
                    actions.syncChatCountNotification(result));
            case 'unhandled_mod_tasks_count':
                result = msg.mod_tasks_count;
                return dispatch(actions.syncModTasksCount(result.count));
            default:
                console.error("Unknown websocket message on notification channel received:", msg);
            }
        }
    };
};

const createGlobalHandler = (dispatch) => {
    return {
        connected: () => {
        },
        disconnected: () => {
        },
        received: (msg) => {
            let result = null;
            //console.log('global handler msg type', msg.msg_type, msg);
            switch (msg.msg_type) {
            case 'online_users':
                result = Immutable.fromJS(msg.users);
                return dispatch(actions.fetchOnlineUsersDone(result));
            default:
                console.error("Unknown websocket message on global channel received:", msg);
            }
        }
    };
};

const wsMiddleware = () => {
    let connection = null;
    let conversationsChannel = null;
    let notificationChannel = null;
    let globalChannel = null;
    let messagesChannels = {};

    return store => next => action => {
        const {slug, message, recipient} = action.payload || {};
        switch (action.type) {
        case constants.WS_CONNECT:
            if(connection !== null){
                connection.disconnect();
            }
            connection = createConsumer(
                ()=> `/api/v1/cable?token=${getAuthToken(store.getState())}`
            );
            conversationsChannel = connectConversations(connection,
                createConversationsHandler(next));
            notificationChannel = connectNotification(connection,
                createNotificationHandler(next));
            globalChannel = connectGlobal(connection,
                createGlobalHandler(next));
            break;
        case constants.WS_DISCONNECT:
            if(connection !== null){
                connection.disconnect();
            }
            connection = null;
            break;
        case constants.SEND_CHAT_MESSAGE:
            const {
                text, conversation: conversationId, transitKey
            } = action.payload;
            if(!isPresent(text)) break;
            const key = transitKey || genId();
            if(connection !== null) {
                if(messagesChannels[conversationId]) {
                    messagesChannels[conversationId].sendMessage(text, key);
                }
            }
            next(actions.storeTransitMessage(
                conversationId, key, action.payload.text));
            break;
        case constants.OPEN_CHAT_CONVERSATION:
            if (!slug) break;
            if(messagesChannels[slug]) {
                messagesChannels[slug].unsubscribe();
                connection.subscriptions.remove(messagesChannels[slug]);
            }
            messagesChannels[slug] = connectMessages(connection, slug,
                createMessagesHandler(next, slug));
            break;
        case constants.CLOSE_CHAT_CONVERSATION:
            if (!slug) break;
            if(messagesChannels[slug]) {
                connection.subscriptions.remove(messagesChannels[slug]);
            }
            delete messagesChannels[slug];
            break;
        case constants.CREATE_CHAT_CONVERSATION:
            conversationsChannel.createConversation(recipient, message);
            break;
        case constants.UPDATE_LAST_SEEN_MESSAGE:
            if(connection !== null) {
                if(messagesChannels[action.payload.conversationId]) {
                    messagesChannels[action.payload.conversationId].
                        update_last_seen_message(action.payload.msgId);
                }
            }
            break;
        default:
            return next(action);
        }
    };
};

export default wsMiddleware();
