import React, {useEffect, useState, useCallback, useRef, lazy, Suspense} from 'react';
import Immutable from "immutable";
import { Route, Switch, NavLink, Link, Redirect, useHistory, } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import LogoSegment from './ui/LogoSegment';
import Page from './Page';
import {Slider} from "./ui";
const GeoMap = lazy(() => import("./ui/GeoMap"));

import {
    Segment, Header, Grid, Image, Icon, Button, Input, Form, Loader, Dropdown,
    Message, Placeholder, Modal, Menu,
} from 'semantic-ui-react';

import {
    isAuthed, getOnlineUsers, getSearchUsers, getAuth, getAuthUserName,
} from '../selectors';

import {
    isPresent, useQuery, useDebouncedEffect, isDefined, uniqBy, sortBy,
    InputRef,
} from '../helpers';

import {
    searchUsers, searchUsersClear, searchUsersNearby, searchUsersOnline,
} from '../actions';

const DEFAULT_DISTANCE = 50;

const ProfileItem = ({item}) => {
    const slug = item.get('slug');
    const name = item.get('nickname');
    const image_url = item.get("profile_image_thumb_url");
    const distance = item.get("distance");
    const is_mod = item.get("mod") || false;
    const classes = ["profile-item"];
    if (is_mod) {
        classes.push("mod");
    }
    return (
        <NavLink className={classes.join(" ")} to={`/profile/${slug}`} >
            <div className="profile-image">
                <Image src={image_url} circular loading="lazy"/>
            </div>
            {isDefined(distance)?(
                <div className="profile-distance">
                    {Math.floor(distance)}km
                </div>
            ):null}
            <div className="profile-name">
                {name}
            </div>
        </NavLink>
    );
};

const PlaceholderItem = ({}) => {
    return (
        <div className="profile-item">
            <div className="profile-image">
                <Placeholder />
            </div>
            <div className="profile-name">
                <Placeholder>
                    <Placeholder.Line />
                </Placeholder>
            </div>
        </div>
    );
};

const SearchTitleOnline = ({q}) => {
    return (
        <Header as="h1" className="cuddle profile" textAlign="center">
            {isPresent(q) ? <>
                <Header.Subheader>
                    Deine Suchergebnisse für:
                </Header.Subheader>
                „{q}“
            </> :
                "Derzeit Online"
            }
        </Header>
    );
};

const SearchTitleNearby = ({q, d}) => {
    return (
        <Header as="h1" className="cuddle profile" textAlign="center">
            {isPresent(q) ? <>
                <Header.Subheader>
                    Deine Suchergebnisse für:
                </Header.Subheader>
                „{q}“ im Umkreis von {d}km
            </> :
                `Alle im Umkreis von ${d}km`
            }
        </Header>
    );
};

const SearchTitleAll = ({q, d}) => {
    return (
        <Header as="h1" className="cuddle profile" textAlign="center">
            {isPresent(q) ? <>
                <Header.Subheader>
                    Deine Suchergebnisse für:
                </Header.Subheader>
                „{q}“
            </> :
                null
            }
        </Header>
    );
};

const ResultContainer = ({resultcount, isLoading, errMsg, children}) => {
    if (isLoading) {
        const count = resultcount || 3;
        return (
            <div className="search_results">
                {Array(count).fill().map((v,i) => <PlaceholderItem key={i}/>)}
            </div>
        );
    }

    return (<>
        {isPresent(errMsg) ? (
            <div className="cd-block cd-darker cd-center">
                <div className="cd-text">
                    <h3>Fehler:</h3>
                    {errMsg}
                </div>
            </div>
        ) : null}
        <div className="search_results">
            {children}
            {resultcount === 0 ? (
                <div className="cd-block cd-center cd-lighter">
                    <div className="cd-text">
                        <h3><Icon name="search" size="big" /></h3>
                        <p>Es wurden keine Übereinstimmungen mit deiner Suchanfrage gefunden.</p>
                    </div>
                </div>
            ) : null}
        </div>
    </>);
};

const SearchResults = ({}) => {
    const searchResults = useSelector(getSearchUsers);
    let users = searchResults.get("result")?.filter(
        (v, k) => k.indexOf("by_") === 0
    ).flatten(true).valueSeq() ?? Immutable.List();
    users = sortBy(uniqBy(users, "slug"), "distance");
    const errMsg = searchResults.get('loaded') ? isPresent(searchResults.get('error')) : null;
    const isLoading = searchResults.get('loading', false);

    return (
        <ResultContainer resultcount={users.size} isLoading={isLoading} errMsg={errMsg}>
            {users.map(user => <ProfileItem
                key={user.get('slug')}
                item={user}
            />)}
        </ResultContainer>
    );
};

const LocationChooser = ({locations, selected}) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(selected);
    const history = useHistory();
    const search = new URLSearchParams(history.location.search);
    const onClose = useCallback(() => {
        setOpen(false);
    }, []);
    const onOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const onSubmit = useCallback((v) => {
        setOpen(false);
        search.set('loc', v);
        history.push({
            search: search.toString()
        });
    }, [history]);
    const select = useCallback((id) => {
        setValue(id);
    }, []);

    if (locations.size <= 1) {
        return null;
    }
    return (<>
        <Button size="small" className="tertiary cuddle" onClick={onOpen}
            data-cy="alt-locations"
        >
            Ähnliche Orte ({locations.size - 1})
        </Button>
        <Modal open={open} onClose={onClose} size="fullscreen">
            <Header>Anderswo suchen</Header>
            <Modal.Content>
                <Grid stackable>
                    <Grid.Column width={8}>
                        <Suspense fallback={<Loader active />}>
                            <GeoMap locations={locations.map(loc=>({
                                lon: loc.get('longitude'),
                                lat: loc.get('latitude'),
                                radius: value === loc.get('geoid') ? 4 : 0,
                                key: loc.get('geoid'),
                            })).toArray()}/>
                        </Suspense>
                    </Grid.Column>
                    <Grid.Column width={8}
                        className="search-group-locations"
                    >
                        <Menu vertical fluid secondary>
                            {locations.map(loc => {
                                const id = loc.get("geoid");
                                search.set('loc', id);
                                return (<Menu.Item key={id} active={value===id}
                                    onClick={() => select(id)}
                                >
                                    {loc.get("name")}
                                </Menu.Item>);
                            })}
                        </Menu>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} data-cy="alt-locations-cancel">
                    Abbrechen
                </Button>
                <Button onClick={() => onSubmit(value)} className="cuddle"
                    data-cy="alt-locations-submit"
                >
                    Suchen
                </Button>
            </Modal.Actions>
        </Modal>
    </>);
};

const ResultGroup = ({title, items, locations, groupcount, locationId}) => {
    const orderedItems = sortBy(items, ["distance", "nickname"]);
    return (<>
        <div className="search-group-header" >
            <div className="search-group-title">
                {title}
            </div>
            {isDefined(locations)? <div className="search-group-actions">
                <LocationChooser locations={locations} selected={locationId} />
            </div>: null}
        </div>
        {orderedItems.map(item => <ProfileItem
            key={item.get('slug')}
            item={item}
        />)}
    </>);
};

const SearchResultsAll = ({q}) => {
    const searchResults = useSelector(getSearchUsers);
    let result = searchResults.get("result");
    const errMsg = searchResults.get('loaded') ? isPresent(searchResults.get('error')) : null;
    const isLoading = searchResults.get('loading', false);
    const found = new Set();
    let groups = [];
    let group;
    let collection = result.get("by_name");
    if (collection && collection.size > 0) {
        group = {title: "Ergebnisse nach Name", items: Immutable.List(), key: "name"};
        collection.forEach(u => {
            const id = u.get('slug');
            if(!found.has(id)) {
                group.items = group.items.push(u);
                found.add(id);
            }
        });
        groups.push(group);
    }
    collection = result.get("by_location")
    const curLoc = result.get('current_location');
    if (collection && (collection.size > 0 || curLoc)) {
        let locTitle = "???";
        if(curLoc) {
            locTitle = curLoc.get("name");
            if (isPresent(curLoc.get("is_in"))) {
                locTitle += ` - ${curLoc.get("is_in")}`;
            }
        }
        const locOptions = result.get('found_locations');
        group = {title: `Ergebnisse im Umkreis von ${locTitle}`,
            locationId: curLoc?.get("geoid"),
            items: Immutable.List(), key: "location", locations: locOptions};
        collection.forEach(u => {
            const id = u.get('slug');
            if(!found.has(id)) {
                group.items = group.items.push(u);
                found.add(id);
            }
        });
        groups.push(group);
    }

    if(!isPresent(q)) {
        return (
            <div className="cd-block cd-center cd-lighter">
                <div className="cd-text">
                    <p>Hier kann direkt der Name eines Kuschlers
                        oder ein Ort eingegeben werden.</p>
                    <p>Die Entfernung bestimmt den Suchradius um den Ortszentrum,
                        in dem gesucht wird.</p>
                    <p>Anstelle eines Ortes kann manchmal auch die
                        Postleitzahl verwendet werden</p>
                </div>
            </div>
        );
    }
    return (
        <ResultContainer resultcount={found.size} isLoading={isLoading}
            errMsg={errMsg}
        >
            {groups.map(
                (params) => <ResultGroup {...params} groupcount={groups.size} />
            )}
        </ResultContainer>
    );
};

const SearchInput = ({}) => {
    const [searchValue, setSearchValue] = useState("");
    const inputRef = useRef(null);
    const history = useHistory();
    const urlquery = useQuery();
    const q = urlquery?.get("q");
    const submit = useCallback((query) => {
        const search = new URLSearchParams(history.location.search);
        search.delete("loc");
        if(isDefined(query)) {
            search.set("q", query);
        }
        try {
            inputRef.current.inputRef.current.blur();
        } catch (e) {}
        history.push({
            search: search.toString()
        });
    }, [history]);
    useEffect(() => {
        setSearchValue(q ?? "");
    }, [q]);
    return (
        <div className="search-action-container">
            <Form onSubmit={(ev)=> submit(searchValue)}>
                <Input className="search profile-search cuddle" fluid icon={
                    <Icon name='search' inverted circular link data-cy="submit-search"
                        onClick={(ev) => submit(searchValue)}
                    />
                    }
                    name="query"
                    onChange={(ev, data) => setSearchValue(data.value)}
                    value={searchValue}
                    ref={inputRef}
                />
                <NavLink as="a" className="ui button circular cuddle"
                    to="/search/" exact data-cy="switch-all"
                    onClick={() => setSearchValue("")}
                >
                    Alle
                </NavLink>
                <NavLink as="a" className="ui button circular cuddle"
                    to="/search/nearby" exact data-cy="switch-near"
                    onClick={() => setSearchValue("")}
                >
                    Deine Nähe
                </NavLink>
                <NavLink as="a" className="ui button circular cuddle"
                    to="/search/current" exact data-cy="switch-online"
                    onClick={() => setSearchValue("")}
                >
                    Derzeit Online
                </NavLink>
            </Form>
        </div>
    );
};

const SearchDistanceInput = ({defDist=DEFAULT_DISTANCE}) => {
    const urlquery = useQuery();
    const [distance, setDistance] = useState(parseInt(urlquery?.get("d") ?? defDist));
    const history = useHistory();
    useDebouncedEffect(() => {
        const search = new URLSearchParams(history.location.search);
        const oldD = search.get("d");
        if(!(oldD === null && distance === DEFAULT_DISTANCE) && oldD !== distance) {
            search.set("d", distance);
            history.push({
                search: search.toString()
            });
        }
    }, 500, [history, distance]);
    return (
        <div className="search-action-container">
            <Slider value={distance} circles onChange={(v) => setDistance(v)}>
                <Slider.Position value={10} label="10km" />
                <Slider.Position value={30} label="30km" />
                <Slider.Position value={50} label="50km" />
                <Slider.Position value={70} label="70km" />
                <Slider.Position value={100} label="100km" />
            </Slider>
        </div>
    );
};

const UnlocatablePage = ({}) => {
    return (
        <div className="cd-block cd-darker cd-center">
            <div className="cd-text">
                <h3><Icon name="compass outline" size="big" /></h3>
                <p>
                    Damit die Suche funktioniert,
                    musst du einen gültigen Ort in deinem Profil angeben.
                </p>
                <Link to="/profile/edit">Profil vervollständigen</Link>
            </div>
        </div>
    );
};

const OnlinePage = ({}) => {
    const urlquery = useQuery();
    const dispatch = useDispatch();
    const query = urlquery?.get("q");
    useEffect(() => {
        dispatch(searchUsersOnline(query));
    }, [query]);

    return (
        <div className="search-container">
            <Segment className="transparent">
                <SearchTitleOnline q={query} />
                <SearchResults />
            </Segment>
        </div>
    );
};

const NearbyPage = ({}) => {
    const urlquery = useQuery();
    const dispatch = useDispatch();
    const query = urlquery?.get("q");
    const distance = urlquery?.get("d") ?? DEFAULT_DISTANCE;
    const userAuth = useSelector(getAuth);
    if (!userAuth.get("locatable")) {
        return <Redirect to="/search/unlocatable" />;
    }
    useEffect(() => {
        dispatch(searchUsersNearby(query, distance));
    }, [query, distance]);

    return (
        <div className="search-container">
            <Segment className="transparent">
                <SearchTitleNearby q={query} d={distance} />
                <SearchDistanceInput />
                <SearchResults />
            </Segment>
        </div>
    );
};

const AllPage = () => {
    const urlquery = useQuery();
    const dispatch = useDispatch();
    const query = urlquery?.get("q");
    const distance = urlquery?.get("d") ?? 10;
    const locId = urlquery?.get("loc");
    const userAuth = useSelector(getAuth);
    if (!userAuth.get("locatable")) {
        return <Redirect to="/search/unlocatable" />;
    }
    useEffect(() => {
        if(isPresent(query) && query.length >= 2) {
            dispatch(searchUsers(query, distance, locId));
        }
    }, [query, distance, locId]);
    return (
        <div className="search-container">
            <Segment className="transparent">
                <SearchTitleAll q={query} d={distance} />
                <SearchDistanceInput defDist={10}/>
                <SearchResultsAll q={query}/>
            </Segment>
        </div>
    );
};

const ProfileSearchPage = () => {
    const authed = useSelector(isAuthed);
    const userAuth = useSelector(getAuth);
    const username = useSelector(getAuthUserName);
    const profile_image_url = userAuth.get('profile_image_url');
    const profileImgPresent = isPresent(profile_image_url) && !profile_image_url.includes("unset_avatar.svg");
    if (!authed) {
        return <Redirect to="/" />;
    }
    return (<>
        <Page slug="search" quotes={false}/>
        <SearchInput />
        {isPresent(username) && profileImgPresent ?
        <Segment className="transparent">
            <Switch>
                <Route exact path="/search/" component={AllPage} />
                <Route exact path="/search/current" component={OnlinePage} />
                <Route exact path="/search/nearby" component={NearbyPage} />
                <Route exact path="/search/unlocatable" component={UnlocatablePage} />
            </Switch>
        </Segment> :
        isPresent(username) && !profileImgPresent ?
        <Segment className="transparent">
            <div className="cd-block cd-darker cd-center">
                <div className="cd-text">
                    <h3>Fehlendes Profilbild</h3>
                    <p>
                        Um die Suche vollständig nutzen zu können, müssen Sie ein Profilbild hochladen.
                    </p>
                    <Link to="/profile/edit">Bild hochladen</Link>
                </div>
            </div>
        </Segment> :
        <Segment className="transparent">
            <div className="cd-block cd-darker cd-center">
                <div className="cd-text">
                    <h3>Profil unvollständig</h3>
                    <p>
                        Damit du die Suche vollständig verwenden kannst, musst
                        du einen Namen und einen Ort in deinem Profil eintragen.
                    </p>
                    <Link to="/profile/edit">Profil vervollständigen</Link>
                </div>
            </div>
        </Segment>}
    </>);
}

export default ProfileSearchPage;
