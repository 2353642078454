import React, {useEffect, useState, useMemo, useCallback, useRef} from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import loadImage from 'blueimp-load-image';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router';
import LogoSegment from './ui/LogoSegment';
import ImageUpload, {readImage, ImageUploadProxy} from './ui/ImageUpload';
import BackButton from "./ui/BackButton";

import {
    Segment, Header, Grid, TextArea, Input, Form, Checkbox, Button, Dimmer, Loader,
    Dropdown, Popup, Modal, Icon, Image, Transition, Container
} from 'semantic-ui-react';

import {
    isAuthed, getAuthUserId, getProfile, getSearchGeolocations,
    getProfileImages,
} from '../selectors';

import {
    fetchProfile, saveProfile, searchGeolocations, searchGeolocationsClear,
    fetchProfileImages, showModal, deleteProfileImage,
} from '../actions';

import Slider from "./ui/Slider";

import {
    isPresent, useSubset, isDefined, fieldErr, uniqBy, sortDescBy,
} from "../helpers";

const LocationFinder = ({value, onChange, error}) => {
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const geolocations = useSelector(getSearchGeolocations);
    const isLoading = geolocations.get("loading");
    const isLoaded = geolocations.get("loaded");
    const errMsg = geolocations.get("error");
    let result = geolocations.get("result");
    const resultWithCurrent = isDefined(value) ? result.unshift(value) : result;
    const locations = sortDescBy(uniqBy(resultWithCurrent, "geoid"), "population");
    const options = locations.map(l => (
        {key: l.get("geoid"), value: l.get("geoid"), text: l.get("name")}
    )).toJS();
    let activeLocation = locations.find(
        x => x.get("geoid") === value?.get("geoid")
    );
    if(!isDefined(activeLocation) && locations.size > 0) {
        activeLocation = result.first();
    }
    let locAddition = activeLocation?.get("is_in");
    if(!isPresent(locAddition)) {
        locAddition = activeLocation?.get("postal_codes");
        if(isPresent(locAddition)) {
            locAddition = `PLZ: ${locAddition}`;
        }
    }
    return (<>
        <Form.Dropdown
            className="cuddle-light"
            search={(options) => options}
            options={options}
            loading={isLoading}
            noResultsMessage={isLoaded ? "Unbekannter Ort" : null}
            onSearchChange={(ev, data) =>
                    dispatch(searchGeolocations(data.searchQuery))
            }
            onChange={(ev, data) => {
                const loc = result.find(
                    x => x.get("geoid") === data.value
                );
                onChange && onChange(loc);
            }}
            onOpen={() => setShowPopup(true)}
            onClose={() => setShowPopup(false)}
            selection
            value={value?.get("geoid")}
            error={isPresent(errMsg)?errMsg:error}
        />
        <div className={`location-details${isPresent(locAddition)?" visible":""}`}
            tabIndex="0"
        >
            {locAddition}
        </div>
    </>);
};

const AvatarDialogImage = ({data, onClick, active=false}) => {
    const dispatch = useDispatch();
    const [failed, setFailed] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const id = data.get("signed_id");
    const isReviewed = data.get('reviewed');
    const selectable = loaded;
    return (
        <a className={`avatar-dialog-image ${active?"active":""} ${selectable?"":"disabled"}`}
            onClick={(ev) => {
                ev.preventDefault();
                if (selectable) {
                    onClick(id);
                }
            }}
            tabIndex={0}
        >
            <Icon name="close" link onClick={(ev) => {
                ev.preventDefault();
                dispatch(showModal("Bild löschen?", "", [
                    ["Nein", -1],
                    ["Ja", deleteProfileImage(id)],
                ]));
            }} tabIndex={0} />
            <div className={`image${loaded ? " loaded" : (failed ? " error" : " loading")}`}>
                {failed ? <Icon.Group>
                    <Icon name="file image" />
                    <Icon name="exclamation triangle" />
                </Icon.Group> :
                    <Image inline src={data.get("thumb_url")}
                        loading="lazy"
                        onLoad={() => setLoaded(true)}
                        onError={() => setFailed(true)}
                    />
                }
                {!isReviewed ? <div className="image_message">
                    Erwartet Freigabe durch die Moderation
                </div> : null}
                {failed ? <div className="image_message">
                    Bild konnte nicht geladen werden
                </div> : null}
            </div>
        </a>
    );
};

const ImageUploadItem = ({onClick, onRetry, uploadState, quota, showQuota=true}) => {
    const {uploading, progress, error} = uploadState || {};
    return ( error ?
        <div className="avatar-dialog-image avatar-upload-retry"
            data-cy="upload-retry"
            onClick={onRetry}
        >
            <div className="image">
                <Icon name="redo" />
                Gescheitert. <br />
                Erneut versuchen!
            </div>
        </div> : (uploading ?
        <div className="avatar-dialog-image avatar-upload-ongoing"
            data-cy="upload"
            style={{"--progress": progress}}
        >
            <div className="image">
                <Icon name="upload" />
                Wird übertragen…
            </div>
        </div> :
        <a className="avatar-dialog-image avatar-upload"
            data-cy="upload"
            onClick={onClick}
        >
            <div className="image">
                <Icon name="upload" />
                Bild Hochladen
                {showQuota && quota >= 0 ? <>
                    <br />
                    <small>{quota} Upload{quota>1?"s":""} verfügbar</small>
                </> : null}
            </div>
        </a>)
    );
};

const ImageUploadsDepleted = ({}) => {
    return (
        <div className="avatar-dialog-image avatar-upload-depleted disabled"
            data-cy="upload-depleted"
        >
            <div className="image">
                <Icon name="lock" />
                Tagesgrenze erreicht!
            </div>
        </div>
    );
};

const AvatarDialogListConfig = ({
    uploadState, userImagesState: state, onChange, uploaderRef, onClose,
    onUploadRetry, uploadonly, preview,
}) => {
    const isLoading = state.get("loading");
    const errorMsg = state.get("error");
    const images = uploadonly ? Immutable.List() : state.get("images");
    const uploadQuota = state.get("uploadQuota", 1);
    const [selection, setSelection] = useState(null);
    useEffect(() => {
        const current_sid = state.get("currentImageId");
        const selection_present = !isDefined(selection) ||
            isDefined(images.find(i => i.get("signed_id") === selection));
        if(isPresent(current_sid) && (selection === null || !selection_present)) {
            setSelection(current_sid);
        } else if(!isPresent(current_sid) && !selection_present) {
            // current image was just deleted
            setSelection(null);
        }
    }, [images]);
    const selUrl = useMemo(() => {
        if(!isDefined(selection) || images.isEmpty()) return null;
        const image = images.find(img => img.get("signed_id") === selection);
        return image?.get("thumb_url");
    }, [selection, images]);
    if(!uploadState?.uploading && !uploadState?.error && uploadonly && preview) {
        return (<div className="avatar-dialog-preview">
            <Image src={preview} />
        </div>);
    }
    return (<>
        {onClose ? <Icon name="close" className="close" link onClick={onClose} /> : null}
        {uploadonly ? null : <Modal.Header>
            Meine Profilbilder
        </Modal.Header>}
        <Modal.Content className="avatar-dialog-view"
            image scrolling
            data-cy="avatar-dialog-view"
        >
            {uploadQuota > 0 ? <ImageUploadItem
                uploadState={uploadState}
                onClick={() => uploaderRef.current?.click()}
                onRetry={onUploadRetry}
                quota={uploadQuota}
                showQuota={!uploadonly}
            /> : <ImageUploadsDepleted />}
            {images.map(imgData => (
                <AvatarDialogImage data={imgData}
                    key={imgData.get('signed_id')}
                    onClick={(sid)=>setSelection(sid)}
                    active={imgData.get('signed_id') === selection}
                />
            ))}
            <Dimmer active={isLoading || isPresent(errorMsg)} inverted>
                {isPresent(errorMsg) ? <div>
                    Ups! Konnte keine Bilder laden. Grund: <br />{errorMsg}
                </div> :
                    <Loader active />
                }
            </Dimmer>
        </Modal.Content>
        {uploadonly ? null : <Modal.Actions data-cy="avatar-dialog-actions">
            {onClose ? <Button data-cy="cancel"
                onClick={onClose}
            >
                Schließen
            </Button> : null}
            <Button className="cuddle" data-cy="choose"
                disabled={selection === null}
                onClick={() => selection !== null && onChange(selection, selUrl)}
            >
                Auswählen
            </Button>
        </Modal.Actions>}
    </>);
};

const imageMime = "image/jpeg";

const canvasToURL = (canvas) => {
    return canvas.toDataURL(imageMime);
};

const canvasToBlob = (canvas, onSuccess) => {
    canvas.toBlob((blob) => onSuccess(blob), imageMime);
};

const AvatarDialogCropConfig = ({file, onComplete, onCancel, controls=null}) => {
    const [crop, setCrop] = useState({ aspect: 1, width: 400 });
    const [fullImage, setFullImage] = useState(null);
    const [cropImage, setCropImage] = useState(null);
    const userId = useSelector(getAuthUserId);
    const uploadImage = useCallback((crop) => {
        if (!cropImage) {
            return;
        }
        const scaleX = cropImage.naturalWidth / cropImage.width;
        const scaleY = cropImage.naturalHeight / cropImage.height;
        loadImage(file, {
            canvas: true,
            orientation: true,
            meta: true,
            top: crop.y * scaleY,
            left: crop.x * scaleX,
            sourceWidth: crop.width * scaleX,
            sourceHeight: crop.height * scaleY,
            imageSmoothingQuality: 'high',
            crop: true,
        }).then((data) => {
            canvasToBlob(data.image, (blob) => {
                blob.name = `${userId}.jpg`;
                onComplete(blob);
            });
        }).catch((err) => {
            // TODO: better error handling (show in ui)
            console.error('failed loading image:', err);
        });
    }, [file, userId, cropImage]);
    useEffect(() => {
        setCropImage(null);
        loadImage(file, {
            canvas: true,
            orientation: true,
            meta: true,
        }).then((data) => {
            const url = canvasToURL(data.image);
            setFullImage({
                image: data.image,
                url: url,
            });
        }).catch((err) => {
            // TODO: better error handling (show in ui)
            console.error('failed loading image', err);
        });
    }, [file]);
    useEffect(() => {
        if (controls && crop.unit) {
            controls({
                ready: true,
                uploadFn: () => uploadImage(crop)
            });
        }
    }, [uploadImage, crop]);
    return (<>
        {controls === null ? <Icon name="close" className="close" link onClick={onCancel} /> : null}
        <Modal.Header>
            Bildausschnitt wählen
        </Modal.Header>
        <Modal.Content className="avatar-dialog-view"
            data-cy="avatar-dialog-view"
        >
            {(fullImage !== null)?<ReactCrop src={fullImage?.url} crop={crop}
                keepSelection
                minWidth={200}
                ruleOfThirds
                circularCrop
                onImageLoaded={(image) => setCropImage(image)}
                onChange={newCrop => {
                    setCrop(newCrop);
                }}
            />:null}
        </Modal.Content>
        {controls === null ?
        <Modal.Actions data-cy="avatar-dialog-actions">
            <Button data-cy="cancel"
                onClick={onCancel}
            >
                Abbrechen
            </Button>
            <Button className="cuddle" data-cy="upload"
                onClick={() => uploadImage(crop)}
            >
                Hochladen
            </Button>
        </Modal.Actions> : null}
    </>);
};

const AvatarDialogContent = ({onChange, onUpload, onClose=null, active=false,
                             uploadonly=false, showPreview=false, controls=null}) => {
    const dispatch = useDispatch();
    const [uploadData, setUploadData] = useState(null);
    const [preview, setPreview] = useState(null);
    const [cropData, setCropData] = useState({
        blob: null,
    });
    useEffect(() => {
        if(active) {
            dispatch(fetchProfileImages());
        }
    }, [active]);
    useEffect(() => {
        if(uploadData?.uploading && uploadData?.finished) {
            dispatch(fetchProfileImages());
            onUpload && onUpload(uploadData.uploadSids);
            //setCropData({
            //    blob: null,
            //});
        }
    }, [uploadData?.finished]);
    useEffect(() => {
        let url = null;
        if (showPreview && cropData?.blob) {
            url = URL.createObjectURL(cropData.blob);
            setPreview(url);
        }
        return () => {
            if (url) {
                URL.revokeObjectURL(url);
                setPreview(null);
            }
        };
    }, [cropData]);
    const state = useSelector(getProfileImages);
    const uploaderRef = useRef(null);
    //console.log('uploadData', JSON.stringify(uploadData));
    //console.log('cropData', JSON.stringify(cropData));
    return (<>
        {(uploadData?.canCrop) ? <AvatarDialogCropConfig
            file={uploadData?.file}
            controls={controls}
            onComplete={(imageBlob) => {
                setUploadData({...uploadData,
                    file: null,
                    canCrop: false,
                });
                setCropData({
                    blob: imageBlob,
                });
                uploadData?.upload([imageBlob]);
            }}
            onCancel={() => setUploadData({
                ...uploadData,
                file: null,
                canCrop: false,
            }) && setCropData({
                blob: null,
            })}
        /> : <AvatarDialogListConfig
            userImagesState={state}
            onChange={onChange}
            onClose={onClose}
            onUploadRetry={() => {
                if(cropData?.blob) {
                    uploadData?.upload([cropData?.blob]);
                } else {
                    setUploadData({
                        file: null,
                        canCrop: false,
                    });
                }
            }}
            uploadState={uploadData}
            uploaderRef={uploaderRef?.current?.inputRef}
            uploadonly={uploadonly}
            preview={preview}
        />}
        <ImageUploadProxy
            purpose="profile"
            onChange={setUploadData}
            inputRef={uploaderRef}
        />
    </>);
};

const AvatarDialog = ({open, onClose, onUpload, onChange}) => {
    return (
        <Modal open={open} onClose={onClose} data-cy="avatar-dialog">
            <AvatarDialogContent active={open} onClose={onClose}
                onUpload={onUpload} onChange={onChange}
            />
        </Modal>
    );
};

export const ProfileImageUploadComponent = ({onUpload, controls}) => {
    return <AvatarDialogContent active uploadonly showPreview
        onUpload={onUpload} controls={controls}
    />;
};

const AvatarImage = ({profileImageUrl, onChange, error,}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    return (<>
        <Segment inverted className="image-bg" data-cy="avatar-dialog-trigger" style={{"marginBottom": '0px'}}>
            {isPresent(profileImageUrl)?
                <img className="image-preview"
                    src={profileImageUrl} />
                    : null}
            <Button className="avatar-select" onClick={()=>setDialogOpen(true)}>
                Bild auswählen
            </Button>
        </Segment>
        <Form.Input style={{"display": 'none'}} error={error} />

        <AvatarDialog open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onChange={(sid, url) => {
                setDialogOpen(false);
                onChange(sid, url);
            }}
        />
    </>);
};

const ProfileEditPage = () => {
    const [shakeToggler_Image, setShakeToggler_Image] = useState(true);
    const [shakeToggler_Name, setShakeToggler_Name] = useState(true);
    const [shakeToggler_Geolocation, setShakeToggler_Geolocation] = useState(true);

    const authed = useSelector(isAuthed);
    const slug = useSelector(getAuthUserId);
    const dispatch = useDispatch();
    useEffect(() => {
        if(authed) {
            dispatch(fetchProfile(slug));
        }
    }, [slug, authed]);
    const profile = useSelector(state => getProfile(state, slug));
    const [profileData, setProfileData] = useState(profile);
    useEffect(() => {
        setProfileData(profile);

        if(profile) {
            const errors = profile.get('error');
            if(errors) {
                document.getElementById('profil-header').scrollIntoView();

                if(fieldErr(errors, "nickname")) {
                    setShakeToggler_Name((v) => !v);
                }
                if(fieldErr(errors, "image")) {
                    setShakeToggler_Image((v) => !v);
                }
                if(fieldErr(errors, "geolocation")) {
                    setShakeToggler_Geolocation((v) => !v);
                }
            }
        }
    }, [profile])
    if (!authed) {
        return <Redirect to="/" />;
    }
    if(!profile?.get || !profileData?.get){
        return null;
    }
    if(profile.get("saved")) {
        return <Redirect to="/profile/" />;
    }
    const errors = profile.get('error');
    const isLoading = profile.get('loading');
    const cuddling_positions = Immutable.Map({0:"", 1:"", 2:"", 3:"", 4:"", 5:""})
        .merge(profileData.get("cuddling_positions") ?? Immutable.Map())
        .takeWhile((v, k) => k >= 0 && k < 6);
    const [desc, setDesc] = useSubset(profileData, ["description"], setProfileData);
    const [nickname, setNickname] = useSubset(profileData, ["nickname"], setProfileData);
    const [geolocation, setGeolocation] = useSubset(profileData, ["geolocation"], setProfileData);
    const [cudlType, setCudlType] = useSubset(profileData, ["cuddling_type"], setProfileData);
    const [cudlTouch, setCudlTouch] = useSubset(profileData, ["cuddling_touch"], setProfileData);
    const [cudlTalk, setCudlTalk] = useSubset(profileData, ["cuddling_talk"], setProfileData);
    const [cudlSpace, setCudlSpace] = useSubset(profileData, ["cuddling_space"], setProfileData);
    const [imageId, setImageId] = useSubset(profileData, ["profile_image"], setProfileData);
    const [imageUrl, setImageUrl] = useSubset(profileData, ["profile_image_url"], setProfileData);
    return (<>
        <LogoSegment />
        <Header as="h1" className="cuddle profile" textAlign="center" id="profil-header">
            Dein Profil
        </Header>
        <Segment className="profile edit" inverted>
            <Form error={isDefined(errors)}>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={5} className="profile-image">
                        <Transition animation="shake" visible={shakeToggler_Image}>
                            <Container>
                                <div className="label cuddle-light">Profilbild</div>
                                <AvatarImage profileImageUrl={imageUrl}
                                    onChange={(imgId, url) => {
                                        setProfileData(profileData.
                                                    set("profile_image", imgId).
                                                    set("profile_image_url", url));
                                    }}
                                    error={fieldErr(errors, "image")}
                                />
                            </Container>
                        </Transition>
                    </Grid.Column>
                    <Grid.Column width={11} className="profile-description">
                        <div className="label cuddle-light">Kurze Selbstbeschreibung</div>
                        <TextArea value={desc} className="cuddle-light"
                            data-cy="profile-description"
                            onChange={(ev, data) => setDesc(data.value)}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5} className="profile-name-location">
                        <div className="profile-name">
                            <Transition animation="shake" visible={shakeToggler_Name}>
                                <Container>
                                    <div className="label cuddle-light">Name</div>
                                    <Form.Input fluid value={nickname} className="cuddle-light"
                                        onChange={(ev, data) => setNickname(data.value)}
                                        error={fieldErr(errors, "nickname")}
                                    />
                                </Container>
                            </Transition>
                        </div>
                        <div className="profile-location">
                            <Transition animation="shake" visible={shakeToggler_Geolocation}>
                                <Container>
                                    <div className="label cuddle-light">Ort</div>
                                    <LocationFinder value={geolocation}
                                        onChange={value => setGeolocation(value)}
                                        error={fieldErr(errors, "geolocation")}
                                    />
                                </Container>
                            </Transition>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={11} className="profile-positions">
                        <div className="label cuddle-light">Meine Lieblingspositionen</div>
                        {cuddling_positions.entrySeq().map(
                            ([key, pos]) => <Input value={pos} key={key}
                                className="cuddle-light"
                                data-cy={`profile-position-${key}`}
                                onChange={(ev, data) => setProfileData(
                                    profileData.setIn(["cuddling_positions", key],
                                    data.value)
                                )}
                            />
                        )}
                        <p>
                            <a
                                href="https://cuddlers.net/de/kuschelpositionen/"
                                target="_blank"
                                title="Liste einiger Kuschelpositionen"
                            >Hier</a> findest du einige Kuschelpositionen.
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5} className="profile-cuddling-space">
                        <div className="label cuddle-light">Kuscheln geht</div>
                        <Checkbox
                            label="Bei mir zuhause"
                            name="cuddling_space"
                            value="myhome"
                            data-cy="profile-cuddling-myhome"
                            checked={cudlSpace?.get("myhome", false)}
                            onChange={(ev, data) => {
                                setCudlSpace(cudlSpace.set(data.value, data.checked))
                            }}
                        />
                        <Checkbox
                            label="Draussen"
                            name="cuddling_space"
                            value="outside"
                            data-cy="profile-cuddling-outside"
                            checked={cudlSpace?.get("outside", false)}
                            onChange={(ev, data) => {
                                setCudlSpace(cudlSpace.set(data.value, data.checked))
                            }}
                        />
                        <Checkbox
                            label="Lieber bei dir"
                            name="cuddling_space"
                            value="yourhome"
                            data-cy="profile-cuddling-yourhome"
                            checked={cudlSpace?.get("yourhome", false)}
                            onChange={(ev, data) => {
                                setCudlSpace(cudlSpace.set(data.value, data.checked))
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={11} className="profile-cuddling-type">
                        <div className="label cuddle-light">Was mag ich beim Kuscheln?</div>
                        <Slider circles onChange={(v) => setCudlType(v)}
                            value={cudlType} data-cy="profile-cuddle-type"
                        >
                            <Slider.Position value={0} label="Ruhig" />
                            <Slider.PositionRange from={1} to={8} />
                            <Slider.Position value={9} label="Aktiv" />
                        </Slider>
                        <Slider circles onChange={(v) => setCudlTouch(v)}
                            value={cudlTouch} data-cy="profile-cuddle-touch"
                        >
                            <Slider.Position value={0} label="Streicheln" />
                            <Slider.PositionRange from={1} to={8} />
                            <Slider.Position value={9} label="Kneten" />
                        </Slider>
                        <Slider circles onChange={(v) => setCudlTalk(v)}
                            value={cudlTalk} data-cy="profile-cuddle-talk"
                        >
                            <Slider.Position value={0} label="Schweigen" />
                            <Slider.PositionRange from={1} to={8} />
                            <Slider.Position value={9} label="Viel Reden" />
                        </Slider>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <BackButton to={"/profile"} />
                        <Button floated="right" className="cuddle"
                            data-cy="profile-save"
                            onClick={()=>dispatch(saveProfile(profileData))}
                        >
                            Speichern
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Dimmer active={isLoading} inverted>
                <Loader active />
            </Dimmer>
            </Form>
        </Segment>
    </>);
};
export default ProfileEditPage;
