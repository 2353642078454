import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import { getRandomQuote } from "../../selectors";

const Quotation = () => {
    const randomQuotation = useSelector(getRandomQuote);
    const [quotation, setQuotation] = useState(null);
    useEffect(() => {
        if(quotation === null && randomQuotation !== undefined &&
            randomQuotation !== null) {
            setQuotation(randomQuotation);
        }
    }, [randomQuotation]);
    if (quotation === null) return null;
    return (
        <div className="quotation">
            <div className="text">
                {quotation.get('body')}
            </div>
            <div className="author">
                {quotation.get('author')}
            </div>
        </div>
    );
};

export default Quotation;
