import React, {useState, useEffect, useContext} from 'react';
import Immutable from 'immutable';
import {Route, Switch, NavLink} from "react-router-dom";
import {Redirect} from 'react-router';
import {useSelector, useDispatch} from 'react-redux'
import {
    Dimmer, Header, Form, TextArea, Button, Segment, Input, Message, Table,
    Loader, Icon, Popup, Label,
} from 'semantic-ui-react';
import { ConfirmButton } from "../ui";

import {
    EDIT_PAGE_MODE, REGULAR_MODE, VISIBILITY_OPTIONS,
} from "../../constants";
import {Context as ViewModeContext} from "../../contexts/ViewMode";

import {getPages, getPageEdit} from "../../selectors";
import {savePage, deletePage, createNewPage, openPageEdit} from "../../actions";
import {isPresent} from "../../helpers";

const PageRow = ({page}) => {
    const dispatch = useDispatch();
    const slug = page.get('slug');
    const access = VISIBILITY_OPTIONS[page.get('authorized_role')];
    return (
        <Table.Row>
            <Table.Cell>{page.get('title')}</Table.Cell>
            <Table.Cell width="1" collapsing>
                <Popup content={access ?
                        `Alle, die ${access.description}` :
                        `Alle, die ${access.text}`
                    }
                    trigger={<Label>{access ? access.text : "?"}</Label>}
                />
            </Table.Cell>
            <Table.Cell width="1" collapsing textAlign="right">
                <Button icon title="Ändern" size="mini" basic
                    onClick={() => dispatch(openPageEdit(slug))}>
                    <Icon name='edit' /> Ändern
                </Button>
                <ConfirmButton icon title="Löschen" color="red" basic
                    size="mini"
                    onClick={() => dispatch(deletePage(slug))}
                >
                    <Icon name='trash' /> Löschen
                </ConfirmButton>
            </Table.Cell>
        </Table.Row>
    );
};

const PageIndex = ({pages}) => {
    const dispatch = useDispatch();
    const realPages = pages.filter(p => !p.has("_internal"));
    const errorMsg = "";
    const loading = false;
    return (<>
        <Header as='h2'>Seiten</Header>
        <Table celled striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Titel</Table.HeaderCell>
                    <Table.HeaderCell>Zugriff</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {realPages.map(p => <PageRow page={p} key={p.get('slug')}/>)}
                <Table.Row>
                    <Table.Cell colSpan="3">
                        <Dimmer active={isPresent(errorMsg) || loading} inverted>
                            {isPresent(errorMsg) ? (
                                <Header as='h2' icon>
                                    <Icon name='settings' />
                                    Oops! Da ist etwas schief gelaufen.
                                    <Header.Subheader>
                                        {errorMsg}
                                    </Header.Subheader>
                                </Header>
                            ) : (
                                <Loader inverted>Wird geladen...</Loader>
                            )}
                        </Dimmer>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan="3">
                        <Button floated='right' icon labelPosition='left' positive
                            size='small' onClick={() => dispatch(createNewPage())}>
                            <Icon name='plus' /> Neue Seite
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    </>);
};


const PageEdit = ({slug}) => {
    const {mode, setMode} = useContext(ViewModeContext);
    const dispatch = useDispatch();
    const pageEdit = useSelector(getPageEdit);
    useEffect(() => {
        if(pageEdit.get('done')) {
            setMode(REGULAR_MODE);
        } else {
            setMode(EDIT_PAGE_MODE);
        }
    }, [pageEdit]);
    const isSaving = false;
    const errorMsg = '';
    if(pageEdit.get('done')) {
        return <Redirect to="/admin/pages" />;
    }
    return (
        <div className="page-admin-edit">
            Uuups, konnte nicht in den Editiermodus wechseln.
        </div>
    );
};

//        <Form onSubmit={() => {dispatch(savePage(initSlug, page)); return false;}}>
//            <Form.Input label="Bezeichnung" value={page.get('title')}
//                onChange={(v) => setPage(page.set('title', v.target.value))}
//            />
//            <Form.Group widths="equal">
//                <ImageUpload withForm={false}
//                    onChange={(imgIds) => setImage(setPage, page, "top", imgIds)}>
//                    <Segment>
//                        <label>Bild oben</label>
//                        <Button as="a" className="upload image-upload" style={{
//                            backgroundImage: `url(${page.get('top_image_url')})`
//                        }}
//                        >
//                            <div className="text">Hochladen</div>
//                        </Button>
//                    </Segment>
//                </ImageUpload>
//                <ImageUpload withForm={false}
//                    onChange={(imgIds) => setImage(setPage, page, "bottom", imgIds)}>
//                    <Segment>
//                        <label>Bild unten</label>
//                        <Button as="a" className="upload image-upload" style={{
//                            backgroundImage: `url(${page.get('bottom_image_url')})`
//                        }}
//                        >
//                            <div className="text">Hochladen</div>
//                        </Button>
//                    </Segment>
//                </ImageUpload>
//            </Form.Group>
//            <Segment textAlign='right' basic>
//                <Form.Field>
//                    <Button type="button" onNClick={() => {
//                        const newBlocks = page.get('blocks').push(emptyBlock(blocks));
//                        setPage(page.set('blocks', newBlocks));
//                        return false;
//                    }}>
//                        Neues Block
//                    </Button>
//                    <Button type="submit" positive>
//                        Speichern
//                    </Button>
//                </Form.Field>
//            </Segment>
//        </Form>

const PageAdmin = () => {
    const pageMap = useSelector(getPages);
    const pages = pageMap.valueSeq();
    return (
        <Switch>
            <Route path="/admin/pages/:slug"
                render={({match: {params: {slug}}}) => <PageEdit slug={slug} />}
            />
            <Route path="/admin/pages/"
                render={() => <PageIndex pages={pages} />}
            />
        </Switch>
    );
};


export default PageAdmin;
