import React, {useEffect, useCallback, useState} from 'react';
import Immutable from 'immutable';
import {Route, Switch, NavLink} from "react-router-dom";
import { Redirect } from 'react-router';
import {useSelector, useDispatch} from 'react-redux'

import {
    isPresent, isDefined, timeSince,
} from "../../helpers";

import {
    getModTasks, getAuthUserId,
} from "../../selectors";
import {
    fetchModTasks, redirectTo, takeModTasks, sendModTasksAction, showToast,
    fetchModTask,
} from "../../actions";

import {
    Table, Button, Icon, Form, Input, TextArea, Dimmer, Loader, Header, Image,
    Message,
} from 'semantic-ui-react';

const STATUS_ICON = {
    assigned: 'tag',
    new: 'asterisk',
    declined: 'ban',
    done: 'checkmark'
};

const STATUS_TEXT = {
    assigned: 'Zugewiesen',
    new: 'Neu',
    declined: 'Abgelehnt',
    done: 'Abgeschlossen'
};

const ReviewProfileImageType = ({task}) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const userName = task.getIn(["user", "nickname"], "Unbenannt");
    const userSlug = task.getIn(["user", "slug"]);
    const imageUrl = task.get("image_url");
    return (<>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell colSpan="2">Profilbild überprüfen</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <Table.Row>
                <Table.Cell colSpan="2">
                    <Image src={imageUrl} />
                    {!isPresent(imageUrl) ? <Message negative>
                        <Message.Header>Bild gelöscht</Message.Header>
                    </Message>: null}
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    Handlung
                </Table.Cell>
                <Table.Cell>
                    Benutzer*in {' '}
                    <NavLink to={`/profile/${userSlug}`}>
                        {userName}
                    </NavLink>
                    {' '} hat {timeSince(task.get("created_at"))} ein neues
                    Profilbild hochgeladen. <br />
                    Ist das Bild in Ordnung?
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    Ablehnungsgrund
                </Table.Cell>
                <Table.Cell>
                    <TextArea value={message}
                        rows={6}
                        style={{width: "100%"}}
                        onChange={(e, data) => setMessage(data.value)}
                        placeholder="Wenn du hier etwas schreibst, wird es in
                                     der Mail bei Ablehnung als Grund eingetragen."
                    />
                </Table.Cell>
            </Table.Row>
        </Table.Body>
        <Table.Footer fullWidth>
            <Table.Row>
                <Table.HeaderCell colSpan="2">
                    <Button floated="right" icon labelPosition="left" positive
                        data-cy="accept-task"
                        onClick={() => dispatch(sendModTasksAction([task.get('slug')], "accept"))}
                    >
                        <Icon name="check circle outline" /> Akzeptieren
                    </Button>
                    <Button floated="left" icon labelPosition="left" negative
                        data-cy="decline-task"
                        onClick={() => dispatch(
                            sendModTasksAction([task.get('slug')], "decline", {message})
                        )}
                    >
                        <Icon name="ban" /> Ablehnen
                    </Button>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    </>);
};

const ReviewGroupType = ({task}) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const imageUrl = task.getIn(["group", "image_url"]);
    const group = task.get('group');
    const deleted = !isDefined(group);
    const userName = task.getIn(["user", "nickname"], "Unbenannt");
    const userSlug = task.getIn(["user", "slug"]);
    return (<>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell colSpan="2">Gruppe überprüfen</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {deleted ? <>
                <Table.Row>
                    <Table.Cell colSpan={2}>Dies Gruppe wurde bereits gelöscht.</Table.Cell>
                </Table.Row>
            </> : <>
                <Table.Row>
                    <Table.Cell>Bild</Table.Cell>
                    <Table.Cell>
                        {isPresent(imageUrl) ?
                            <Image src={imageUrl} /> :
                                "Nicht gesetzt"
                        }
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Titel</Table.Cell>
                    <Table.Cell>{task.getIn(["group", "title"])}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Ort</Table.Cell>
                    <Table.Cell>{task.getIn(["group", "location"])}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Zeit</Table.Cell>
                    <Table.Cell>{task.getIn(["group", "date"])}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Beschreibung</Table.Cell>
                    <Table.Cell>{task.getIn(["group", "description"])}</Table.Cell>
                </Table.Row>
            </>}
            <Table.Row>
                <Table.Cell>
                    Handlung
                </Table.Cell>
                <Table.Cell>
                    Benutzer*in {' '}
                    <NavLink to={`/profile/${userSlug}`}>
                        {userName}
                    </NavLink>
                    {' '} hat {timeSince(task.get("created_at"))} eine neue
                    Gruppe eröffnet. <br />
                    Ist die Gruppe in Ordnung?
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    Ablehnungsgrund
                </Table.Cell>
                <Table.Cell>
                    <TextArea value={message}
                        rows={6}
                        style={{width: "100%"}}
                        onChange={(e, data) => setMessage(data.value)}
                        placeholder="Wenn du hier etwas schreibst, wird es in
                                     der Mail bei Ablehnung als Grund eingetragen."
                    />
                </Table.Cell>
            </Table.Row>
        </Table.Body>
        <Table.Footer fullWidth>
            <Table.Row>
                <Table.HeaderCell colSpan="2">
                    {deleted ?<>
                        <Button floated="right" icon labelPosition="left" negative
                            data-cy="delete-task"
                            onClick={() => dispatch(
                                sendModTasksAction([task.get('slug')], "delete")
                            )}
                        >
                            <Icon name="trash" /> Aufgabe löschen
                        </Button>
                    </> : <>
                        <Button floated="right" icon labelPosition="left" positive
                            data-cy="accept-task"
                            onClick={() => dispatch(
                                sendModTasksAction([task.get('slug')], "accept")
                            )}
                        >
                            <Icon name="check circle outline" /> Freigeben
                        </Button>
                        <Button floated="left" icon labelPosition="left" negative
                            data-cy="decline-task"
                            onClick={() => dispatch(
                                sendModTasksAction([task.get('slug')], "decline", {message})
                            )}
                        >
                            <Icon name="ban" /> Löschen
                        </Button>
                    </>}
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    </>);
};

const UnknownType = ({task}) => {
    return (
        <Table.Body>
            <Table.Row>
                <Table.Cell>Ich kann diese Aufgabe nicht darstellen.</Table.Cell>
            </Table.Row>
        </Table.Body>
    );
};

const TaskReview = ({tasks, slug}) => {
    let TaskType;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchModTask(slug));
    }, [slug]);
    if (!isDefined(tasks) || tasks.get('loading', true)) {
        return (<Loader />);
    }
    const task = tasks.getIn(["result", slug]);
    if (!isDefined(task) || task.get('status') === "deleted") {
        dispatch(showToast(
            "Gelöscht",
            "Neuer Aufgabenstatus"
        ));
        return (<Redirect to="/mods/tasks/" />);
    }
    if (task.get('status') === "done" || task.get('status') === "declined") {
        dispatch(showToast(
            STATUS_TEXT[task.get('status')],
            "Neuer Aufgabenstatus"
        ));
        return (<Redirect to="/mods/tasks/" />);
    }
    switch (task.get('kind')) {
    case "profile_image_review":
        TaskType = ReviewProfileImageType;
        break;
    case "group_review":
        TaskType = ReviewGroupType;
        break;
    default:
        TaskType = UnknownType;
    }
    return (<Table celled className="mod-tasks">
        <TaskType task={task} />
    </Table>);
};

const genTitle = (task) => {
    const kind = task.get('kind');
    if (kind === "profile_image_review") {
        const userName = task.getIn(['user', 'nickname']);
        const userMail = task.getIn(['user', 'email']);
        const userSlug = task.getIn(['user', 'slug']);
        return <span>
            Profilbild von {' '}
            <NavLink to={`/profile/${userSlug}`}>
                {userName ?? userMail ?? userSlug}
            </NavLink> prüfen
        </span>;
    } else if (kind === "group_review") {
        const userName = task.getIn(['user', 'nickname'], "Unbenannt");
        const userMail = task.getIn(['user', 'email']);
        const userSlug = task.getIn(['user', 'slug']);
        return <span>
            Gruppe von {' '}
            <NavLink to={`/profile/${userSlug}`}>
                {userName ?? userMail ?? userSlug}
            </NavLink> prüfen
        </span>;
    } else {
        return `Aufgabe vom Typ "${kind}"`;
    }
};

const TaskRow = ({task: t}) => {
    const dispatch = useDispatch();
    const userId = useSelector(getAuthUserId);
    const assigneeId = t.getIn(['assignee', 'slug']);
    const status= t.get('status', 'new');
    return (
        <Table.Row className={`${status}`}>
            <Table.Cell className="wordBreak">{genTitle(t)}</Table.Cell>
            <Table.Cell collapsing textAlign="center">
                <Icon name={STATUS_ICON[status]} title={STATUS_TEXT[status]}/>
            </Table.Cell>
            <Table.Cell collapsing>{t.getIn(['assignee', 'nickname'], '-')}</Table.Cell>
            <Table.Cell collapsing>
                <Button.Group size='mini' basic>
                    {isDefined(assigneeId) && status === "assigned" ? (
                        (userId === assigneeId) ? (
                            <Button icon title="Prüfen" as={NavLink} data-cy="check-task"
                                to={`/mods/tasks/${t.get('slug')}`}
                            >
                                <Icon name='eye' />
                            </Button>
                        ) : null
                    ) : (status === "new") ? (
                        <Button icon title="Übernehmen" data-cy="take-task"
                            onClick={() => dispatch(takeModTasks([t.get('slug')]))}
                        >
                            <Icon name='hand paper' />
                        </Button>
                    ) : null }
                </Button.Group>
            </Table.Cell>
        </Table.Row>
    );
};

const TaskIndex = ({tasks}) => {
    const dispatch = useDispatch();
    const loading = tasks.get('loading', false);
    const errorMsg = tasks.get('error');
    const items = tasks.get('result', Immutable.Map())
        .filter(i=>i.get('status') !== "deleted").toIndexedSeq().toArray();
    return (<div>
        <Table celled striped className="mod-tasks">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Aufgabe</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Zugewiesen an</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map(t => <TaskRow task={t} key={t.get('slug')}/>)}
                <Table.Row>
                    <Table.Cell colSpan="3">
                        <Dimmer active={isPresent(errorMsg) || loading} inverted>
                            {isPresent(errorMsg) ? (
                                <Header as='h2' icon>
                                    <Icon name='settings' />
                                    Oops! Da ist etwas schief gelaufen.
                                    <Header.Subheader>
                                        {errorMsg}
                                    </Header.Subheader>
                                </Header>
                            ) : (
                                <Loader inverted>Wird geladen...</Loader>
                            )}
                        </Dimmer>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </div>);
};

//Moderationsaufgaben
const TaskPage = () => {
    const tasks = useSelector(getModTasks);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchModTasks());
    }, []);

    return (
        <Switch>
            <Route path="/mods/tasks/:slug"
                render={({match: {params: {slug}}}) => <TaskReview tasks={tasks} slug={slug} />}
            />
            <Route path="/mods/tasks/"
                render={() => <TaskIndex tasks={tasks} />}
            />
        </Switch>
    );
};


export default TaskPage;
