import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Icon, } from 'semantic-ui-react';

const BackButton = ({to, ...props}) => {
    return (
        <Button as={NavLink} to={to} basic className="back-button" exact
            {...props}
        >
            <Icon name="chevron left" />
            Zurück
        </Button>
    );
};

export const BackIcon = ({to, ...props}) => {
    return (
        <Button as={NavLink} to={to} basic className="back-button tertiary" exact
            {...props} circular icon
        >
            <Icon name="chevron left" />
        </Button>
    );
};

export default BackButton;
