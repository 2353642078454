import React, {useEffect, useState, useRef, useCallback,} from 'react';
import { NavLink, Switch, Route, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router';
import {BackIcon, LogoSegment} from './ui';

import {
    Segment, Header, Grid, Menu, Button, Form, Responsive, Transition,
    Container, Modal, Message, Icon, Checkbox,
} from 'semantic-ui-react';

import {
    isAuthed, getUserAccountData,
} from '../selectors';

import {
    fetchUserAccountData, deleteOwnAccount, changePassword,
    resetUserAccountPasswordData, saveUserAccountData,
} from '../actions';

import {
    fieldErr, isDefined, isPresent, FormRef,
} from "../helpers";


const LoginSettingsView = () => {
    const dispatch = useDispatch();
    const state = useSelector(getUserAccountData);
    const errors = state.getIn(["password", "error"]);
    const isLoading = state.getIn(["password", "loading"]);
    const [oldpass, setOldpass] = useState("");
    const [newpass, setNewpass] = useState("");
    const [newpassConfirm, setNewpassConfirm] = useState("");
    const [shakeToggler, setShakeToggler] = useState(true);
    const [successOp, setSuccessOp] = useState(false);
    const onSubmit = useCallback((ev, d) => {
        ev.preventDefault();
        if(isLoading) return false;
        dispatch(changePassword(oldpass, newpass, newpassConfirm));
        return false;
    });
    useEffect(() => {
        if(state.getIn(["password", "success"])) {
            setOldpass("");
            setNewpass("");
            setNewpassConfirm("");
            setSuccessOp(true);
        } else if(isDefined(state.getIn(["password", "error"]))) {
            setShakeToggler(x => !x);
        }
    }, [state]);
    useEffect(() => {
        if(isPresent(oldpass) || isPresent(newpass) || isPresent(newpassConfirm)) {
            setSuccessOp(false);
        }
    }, [oldpass, newpass, newpassConfirm]);
    useEffect(() => {
        return () => {
            dispatch(resetUserAccountPasswordData());
        };
    }, []);
    return (<Form
            onSubmit={onSubmit}
            error={isDefined(errors)}
        >
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as={React.Fragment}>
            <BackIcon to="/settings/" floated="left" />
        </Responsive>
        <Header as="h2" className="page-header">
            Passwort ändern
        </Header>
        <Form.Input type="password"
            required
            autoComplete="current-password"
            label="Aktuelles Passwort"
            name="oldpass"
            disabled={isLoading}
            error={fieldErr(errors, "current_password")}
            value={oldpass}
            onChange={(ev, data) => setOldpass(data.value)}
        />
        <Form.Input type="password"
            required
            autoComplete="new-password"
            label="Neues Passwort"
            name="newpass"
            disabled={isLoading}
            error={fieldErr(errors, "password")}
            value={newpass}
            onChange={(ev, data) => setNewpass(data.value)}
        />
        <Form.Input type="password"
            required
            autoComplete="new-password"
            label="Neues Passwort wiederholen"
            name="newpassconfirm"
            disabled={isLoading}
            error={fieldErr(errors, "password_confirmation")}
            value={newpassConfirm}
            onChange={(ev, data) => setNewpassConfirm(data.value)}
        />
        {isPresent(errors)?<Message error>
            Fehlgeschlagen! Grund: {errors}
        </Message>:null}
        <div className="form-actions">
            <Transition animation="shake" visible={shakeToggler}>
                <Button className="cuddle" inverted floated="right"
                    type="submit" data-cy="submit-password"
                    loading={isLoading}
                >
                    {successOp ? <span>
                        <Icon name="check" /> Änderungen gespeichert!
                    </span>: <span>
                        Passwort ändern
                    </span>}
                </Button>
            </Transition>
        </div>
    </Form>);
};

const AccountSettingsView = () => {
    const [quitModalOpen, setQuitModalOpen] = useState(false);
    const dispatch = useDispatch();
    return (<Form>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as={React.Fragment}>
            <BackIcon to="/settings/" floated="left" />
        </Responsive>
        <Header as="h2" className="page-header">
            Kontoaktionen
        </Header>
        <Segment textAlign="center" className="transparent">
            <Modal open={quitModalOpen} onClose={() => setQuitModalOpen(false)}
                style={{border: "4px solid #ab4742"}}
                data-cy='confirmation-dialog'
            >
                <Modal.Header>
                    Löschung bestätigen
                </Modal.Header>
                <Modal.Content>
                    <p><span className="ui red text"><b>Vorsicht!</b></span></p>
                    <p>
                        Dieser Schritt ist unumkehrbar!
                    </p>
                    <p>
                        Nachdem du diesen Dialog bestätigt hast, wird dein Konto,
                        sowie alle deine Nachrichten, Bewertungen und Gruppen
                        unwiederruflich entfernt. Es werden auch
                        alle deine Sitzungen geschlossen, wodurch du keine weiteren
                        Handlungen auf Kuscheldate mehr durchführen kannst.
                    </p>
                    <p>
                        Deine E-Mail Adresse wird dadurch freigegeben und es kann
                        ein neues Profil unter der gleichen E-Mail Adresse
                        registriert werden.
                    </p>
                    <p>
                        Nachdem dein Konto gelöscht wurde, erhälst du eine
                        Bestätigung per E-Mail zugesandt.
                    </p>
                    <p>
                        Bist du dir sicher, dass du dein Kuscheldate Konto löschen möchtest?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="cuddle danger" inverted
                        onClick={() => dispatch(deleteOwnAccount())}
                        data-cy='confirm-button'
                    >
                        Konto entgültig löschen
                    </Button>
                </Modal.Actions>
            </Modal>
            <Button className="cuddle danger" inverted
                onClick={() => setQuitModalOpen(true)}
                data-cy='delete-button'
            >
                Konto löschen
            </Button>
        </Segment>
    </Form>);
};

const NotificationFeature = ({label, value, onChange, bitOffset, cyid}) => {
    const bitValue = 1 << bitOffset;
    return (
        <div className="row notification" data-cy={cyid}>
            <div className="column">{label}</div>
            <div className="column">
                <Checkbox toggle
                    checked={value & bitValue}
                    onChange={(ev, data) => onChange(data.checked ?
                        value | bitValue : value ^ bitValue
                    )}
                />
            </div>
        </div>
    );
};

const NotificationSettingsView = () => {
    const dispatch = useDispatch();
    const state = useSelector(getUserAccountData);
    const errors = state.get('error');
    const [successOp, setSuccessOp] = useState(false);
    const isLoading = state.get('loading');
    const stateValue = state.get('notifications');
    const [shakeToggler, setShakeToggler] = useState(true);
    const [value, setValue] = useState(stateValue);

    useEffect(() => {
        if(value !== stateValue) {
            setValue(stateValue);
        } else {
            setSuccessOp(true);
        }
    }, [stateValue]);

    useEffect(() => {
        setSuccessOp(false);
    }, [value]);

    const onSubmit = useCallback((ev, d) => {
        ev.preventDefault();
        if(isLoading || stateValue === value) return false;
        dispatch(saveUserAccountData({
            notifications: value
        }));
        return false;
    });

    return (<Form
            onSubmit={onSubmit}
            error={isDefined(errors)}
        >
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as={React.Fragment}>
            <BackIcon to="/settings/" floated="left" />
        </Responsive>
        <Header as="h2" className="page-header">
            Benachrichtigung
        </Header>
        <div className="description-list">
            <NotificationFeature
                label="Benachrichtige mich, wenn ich neue ungelesene
                       Chat Nachrichten habe"
                value={value} onChange={setValue} bitOffset={0}
                cyid="chat_messages"
            />
            <NotificationFeature
                label="Benachrichtige mich, wenn eine Bewertung in
                       meinem Profil abgegeben wurde"
                value={value} onChange={setValue} bitOffset={1}
                cyid="profile_messages"
            />
            <NotificationFeature
                label="Benachrichtige mich, wenn mein hochgeladenes Profilbild
                       geprüft wurde"
                value={value} onChange={setValue} bitOffset={2}
                cyid="profile_image_reviewed"
            />
            <NotificationFeature
                label="Benachrichtige mich, wenn meine Kuschelgruppe geprüft
                       wurde"
                value={value} onChange={setValue} bitOffset={3}
                cyid="group_reviewed"
            />
            <NotificationFeature
                label="Benachrichtige mich, wenn in meiner Kuschelgruppe ein
                       Kommentar verfasst wurde"
                value={value} onChange={setValue} bitOffset={4}
                cyid="group_messages"
            />
        </div>
        {isPresent(errors)?<Message error>
            Fehlgeschlagen! Grund: {errors}
        </Message>:null}
        <div className="form-actions">
            <Transition animation="shake" visible={shakeToggler}>
                <Button className="cuddle" inverted floated="right"
                    type="submit" data-cy="submit-notification-config"
                    loading={isLoading}
                >
                    {successOp ? <span>
                        <Icon name="check" /> Änderungen gespeichert!
                    </span>: <span>
                        Speichern
                    </span>}
                </Button>
            </Transition>
        </div>
    </Form>);
};

const Categories = ({className}) => {
    return (
        <Menu vertical fluid inverted className={`cuddle ${className}`}>
            <Menu.Item as={NavLink} to="/settings/account">
                Konto
            </Menu.Item>
            <Menu.Item as={NavLink} to="/settings/login">
                Passwort
            </Menu.Item>
            <Menu.Item as={NavLink} to="/settings/notifications">
                Benachrichtigung
            </Menu.Item>
        </Menu>
    );
};

const Content = ({className}) => {
    return (<Container {...{className}}>
        <Switch>
            <Route path="/settings/account"
                render={()=><AccountSettingsView {...{className}} />}
            />
            <Route path="/settings/login"
                render={() => <LoginSettingsView {...{className}} />}
            />
            <Route path="/settings/notifications"
                render={() => <NotificationSettingsView {...{className}} />}
            />
            <Redirect to="/settings/account" />
        </Switch>
    </Container>);
};

const MobileStackView = () => {
    return (<div className="mobile-stack">
        <Route path="/settings/:category"
            children={({match}) => (
                <Transition visible={match !== null} animation="slide left">
                    <Content />
                </Transition>
            )}
        />
        <Route path="/settings/" exact
            children={({match}) => (
                <Transition visible={match !== null} animation="slide right">
                    <Categories />
                </Transition>
            )}
        />
    </div>);
};

const SettingsPage = () => {
    const authed = useSelector(isAuthed);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUserAccountData());
    }, []);
    if (!authed) {
        return <Redirect to="/" />;
    }
    return (<>
        <LogoSegment />
        <Segment className="transparent settings-page">
            <Header as="h1" className="cuddle profile" textAlign="center">
                Einstellungen
            </Header>
            <Segment inverted className="cuddle">
                <Grid>
                    <Grid.Row stretched>
                        <Responsive
                            minWidth={Responsive.onlyTablet.minWidth}
                            as={Grid.Column}
                            width={5}
                        >
                            <Categories />
                        </Responsive>
                        <Responsive
                            minWidth={Responsive.onlyTablet.minWidth}
                            as={Grid.Column}
                            width={11}
                        >
                            <Content />
                        </Responsive>
                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}
                            as={Grid.Column}
                            width={16}
                        >
                            <MobileStackView />
                        </Responsive>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Segment>
    </>);
};

export default SettingsPage;