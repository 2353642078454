import React, {useEffect, useState, useRef} from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router';
import {LogoSegment, RenderContent, ConfirmButton} from './ui';

import {
    Segment, Header, Grid, Image, Icon, Button, Container, Comment,
    Loader, Modal, TextArea, Visibility, Transition, Divider, Responsive,
    Message, Form, Dimmer, Accordion,
} from 'semantic-ui-react';

import {
    isAuthed, getAuthUserId, getProfile, getProfileComments, isMod,
    getOnlineUsers,
} from '../selectors';

import {
    fetchProfile, fetchNextProfileComments, clearProfileComments,
    sendProfileComment, deleteProfileComment,
} from '../actions';

import Slider from "./ui/Slider";

import {
    isPresent, isDefined, isFunction, formatDate, useForceUpdate, lifetime,
} from "../helpers";

const PROFILE_CUDDLING_SPACES = {
    "myhome": "Bei mir zuhause",
    "outside": "Draussen",
    "yourhome": "Lieber bei dir"
};

const ModHeader = (params) => {
    return (
        <Header as='h4' {...params}>
            Versteckte Bewertung für Moderatoren
        </Header>
    );
};

const DEFAULT_NEW_COMMENT = Immutable.fromJS({
    rating: 3,
    body: "",
    mod_body: "",
    talkative: 3,
    unintention: 3,
    wellbeing: 3,
    consent: 3,
    recommendation: false,
});

const CommentDialog = ({profileSlug, open, onClose}) => {
    const [comment, setComment] = useState(DEFAULT_NEW_COMMENT);
    const [sending, setSending] = useState(false);
    const comments = useSelector(state => getProfileComments(state, profileSlug));
    useEffect(() => {
        if(open){
            setComment(DEFAULT_NEW_COMMENT);
            setSending(false);
        }
    }, [open]);
    useEffect(() => {
        if(open && isDefined(comments)) {
            if(comments.get('loading') && !sending) {
                setSending(true);
            } else if(!comments.get('loading') && sending &&
                !isPresent(comments.get('sendError'))) {
                onClose();
            }
        }
    }, [comments, open, sending]);
    const dispatch = useDispatch();
    const isLoading = isDefined(comments) && comments.get('loading', false);
    const errorMsg = isDefined(comments) && comments.get('sendError');
    return (
        <Modal open={open} onClose={onClose}>
            <Header>Bewertung schreiben</Header>
            <Modal.Content>
                <div className="commenting-section">
                    <Form id="comment-form"
                        onSubmit={(e) => {
                            dispatch(sendProfileComment(profileSlug, comment));
                            e.preventDefault();
                        }}
                    >
                        <Dimmer active={sending}>
                            {isLoading ? (<Loader size="large" />) : (
                                isPresent(errorMsg) ? (
                                    <span className="ui inverted red text">
                                        {errorMsg}
                                    </span>
                                ) : null
                            )}
                        </Dimmer>
                        <div className="comment-stars">
                            <div className="text">
                                Bewertung:
                            </div>
                            <Stars size="large" of={5} count={comment.get('rating')}
                                onChange={(val) =>
                                    setComment(comment.set('rating', val + 1))
                                }
                            />
                        </div>
                        <div className="comment-input">
                            <TextArea value={comment.get('body')} required
                                placeholder="Bewertungstext"
                                onChange={(e, data) =>
                                    setComment(comment.set('body', data.value))
                                }
                            />
                        </div>
                        <Responsive minWidth={Responsive.onlyTablet.minWidth} as={React.Fragment}>
                            <Divider horizontal>
                                <ModHeader />
                            </Divider>
                        </Responsive>
                        <Responsive {...Responsive.onlyMobile} as={React.Fragment}>
                            <ModHeader textAlign="center" />
                        </Responsive>
                        <div className="comment-stars">
                            <div className="text">
                                Absichtslosigkeit:
                            </div>
                            <Stars size="large" of={5} count={comment.get('unintention')}
                                onChange={(val) =>
                                    setComment(comment.set('unintention', val + 1))
                                }
                            />
                        </div>
                        <div className="comment-stars">
                            <div className="text">
                                Einvernehmlichkeit:
                            </div>
                            <Stars size="large" of={5} count={comment.get('consent')}
                                onChange={(val) =>
                                    setComment(comment.set('consent', val + 1))
                                }
                            />
                        </div>
                        <div className="comment-stars">
                            <div className="text">
                                Wohlbefinden:
                            </div>
                            <Stars size="large" of={5} count={comment.get('wellbeing')}
                                onChange={(val) =>
                                    setComment(comment.set('wellbeing', val + 1))
                                }
                            />
                        </div>
                        <div className="comment-stars">
                            <div className="text">
                                Redefluss:
                            </div>
                            <Stars size="large" of={5} count={comment.get('talkative')}
                                onChange={(val) =>
                                    setComment(comment.set('talkative', val + 1))
                                }
                            />
                        </div>
                        <div className="comment-input">
                            <TextArea value={comment.get('mod_body')}
                                placeholder="Text an Moderatoren"
                                onChange={(e, data) =>
                                    setComment(comment.set('mod_body', data.value))
                                }
                            />
                        </div>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>
                    Abbrechen
                </Button>
                <Button className="cuddle" form="comment-form" type="submit">
                    Senden
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const Stars = ({count=0, of=5, onChange, ...params}) => {
    const stars = [];
    if (isFunction(onChange)) {
        params['onClick'] = (e, data) => {
            onChange(parseInt(e.target.getAttribute('data-value')));
        };
        // TODO: keypress is deprecated, use keydown instead
        params['onKeyPress'] = (e) => {
            if(e.key === " " || e.key === "Enter") {
                onChange(parseInt(e.target.getAttribute('data-value')));
            }
        };
        //params['className'] = (params['className'] || '') + " clickable";
        params['tabIndex'] = '0';
    }
    for (let i = 0; i < of; i++) {
        //const name = `star${i >= count ? " outline" : ((i + 0.5) >= count) ? " half" : ""}`;
        stars.push(
            <Icon.Group key={i} data-value={i} {...params}>
                <Icon name="star outline" data-value={i} />
                {((i + 0.5) < count) ? <Icon name="star" data-value={i}/> :
                         (i < count) ? <Icon name="star half" data-value={i}/> :
                        null
                }
            </Icon.Group>
        );
    }
    return (
        <div className="stars">
            {stars}
        </div>
    );
};

const ProfileComment = ({data, profileSlug}) => {
    const [modOpen, setModOpen] = useState(false);
    const iAmMod = useSelector(isMod);
    if(data.get('deleted', false)) {
        return (
            <Transition transitionOnMount animation="browse">
                <Grid stackable className="profile-comment deleted" width={1}>
                    <Grid.Column>
                        Das Kommentar wurde von Moderatoren gelöscht.
                    </Grid.Column>
                </Grid>
            </Transition>
        );
    }
    return (
        <Transition transitionOnMount animation="browse">
            <Grid stackable className="profile-comment">
                <Grid.Row>
                    <Grid.Column width={3}>
                        <div className="comment-image">
                            <NavLink to={`/profile/${data.getIn(['author', 'slug'])}`}>
                                <Image src={
                                    data.getIn(['author', 'profile_image_thumb_url'])
                                } circular width="100px" height="100px" />
                            </NavLink>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={13} className="comment-header">
                        <div className="comment-name">
                            <NavLink to={`/profile/${data.getIn(['author', 'slug'])}`}>
                                {data.getIn(['author', 'nickname'])}
                            </NavLink>
                        </div>
                        <div className="comment-date">{formatDate(data.get('created_at'))}</div>
                        <div className="comment-location">{data.getIn(['author', 'location'])}</div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3} className="offset-column">
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <div className="comment-stars">
                            <div className="text">
                                Bewertung in Sternen
                            </div>
                            <Stars count={data.get('rating')} of={5} />
                        </div>
                        <div className="comment-text">
                            {data.get('body')}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                {iAmMod ? <Grid.Row>
                    <Grid.Column width={3} className="offset-column">
                    </Grid.Column>
                    <Grid.Column width={13} className="comment-mod-section">
                        <Accordion>
                            <Accordion.Title active={modOpen}
                                onClick={() => setModOpen(!modOpen)}
                            >
                                <Icon name='dropdown' />
                                Information für Moderatoren
                            </Accordion.Title>
                            <Transition visible={modOpen} animation="fade down">
                                <Accordion.Content active={modOpen}>
                                    <div className="comment-stars">
                                        <div className="text">
                                            Absichtslosigkeit
                                        </div>
                                        <Stars count={data.get('unintention')} of={5} />
                                    </div>
                                    <div className="comment-stars">
                                        <div className="text">
                                            Einvernehmlichkeit
                                        </div>
                                        <Stars count={data.get('consent')} of={5} />
                                    </div>
                                    <div className="comment-stars">
                                        <div className="text">
                                            Wohlbefinden
                                        </div>
                                        <Stars count={data.get('wellbeing')} of={5} />
                                    </div>
                                    <div className="comment-stars">
                                        <div className="text">
                                            Redefluss
                                        </div>
                                        <Stars count={data.get('talkative')} of={5} />
                                    </div>
                                    <div className="comment-text">
                                        {data.get('mod_body')}
                                    </div>
                                </Accordion.Content>
                            </Transition>
                        </Accordion>
                    </Grid.Column>
                </Grid.Row> : null }
                <CommentActions slug={data.get('slug')} profileSlug={profileSlug} data={data}/>
            </Grid>
        </Transition>
    );
};

const CommentError = ({slug}) => {
    const dispatch = useDispatch();
    const response = useSelector(state => getProfileComments(state, slug));
    const errorMsg = isDefined(response) && response.get('error');
    if (!isPresent(errorMsg)) return null;
    return(
        <Message negative>
            {errorMsg}
        </Message>
    );
};

const CommentLoader = ({slug, onNewComment}) => {
    const dispatch = useDispatch();
    const response = useSelector(state => getProfileComments(state, slug));
    const ownSlug = useSelector(getAuthUserId);
    if (!isPresent(slug)) return null;
    const isLoading = response && response.get('loading');
    const hasMore = !isDefined(response) || response.get('hasMore');
    const firstLoad = !isDefined(response) || (hasMore && response.get('result').size === 0);
    const showLoadNext = !isLoading && hasMore;
    const hasComments = isDefined(response) && response.get('result', Immutable.List()).size > 0;
    const ownProfile = slug === ownSlug;

    return (<div className="comment-loader">
        <Loader active={isLoading} inline size="large" />
        {showLoadNext ? (firstLoad ? (
            <Visibility once={true}
                onOnScreen={(a,b) => dispatch(fetchNextProfileComments(slug))}
            />
        ) : (
            <Button className="cuddle" onClick={
                (a,b) => dispatch(fetchNextProfileComments(slug))
            }>Mehr laden</Button>
        )) : ((!hasMore && !hasComments) ? (
            <div className="empty-comments">
                Es gibt hier noch keine Bewertungen.
                {!ownProfile ? (
                    <Button className="cuddle" onClick={onNewComment}>
                        Gib eine Bewertung ab!
                    </Button>
                ) : null}
            </div>
        ) : null)}
    </div>);
};

const CommentActions = ({slug, profileSlug, data}) => {
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const userId = useSelector(getAuthUserId);
    const userIsMod = useSelector(isMod);
    const timeAlive = lifetime(data.get('created_at'));
    useEffect(() => {
        let timer;
        if(timeAlive < 300) {
            timer = setTimeout(() => {
                forceUpdate();
            }, (300 - timeAlive) * 1000);
        }
        return () => {
            if(timer !== undefined) {
                clearTimeout(timer);
            }
        };
    }, [timeAlive]);
    const canDeleteOwn = timeAlive < 300 && data.getIn(["author", "slug"]) === userId;
    const canDeleteMod = timeAlive >= 300 && userIsMod;
    if(!canDeleteOwn && !canDeleteMod) {
        return null;
    }
    return (
        <Grid.Row>
            <Grid.Column textAlign="right" width={16}>
                <ConfirmButton basic color="red" compact size="mini"
                    onClick={() => dispatch(deleteProfileComment(profileSlug, slug))}
                    title={canDeleteOwn ?
                        "Kommentar kann innerhalb von 5 Minuten gelöscht werden" :
                        "[Moderation] Kommentar als gelöscht vermerken"
                    }
                >
                    <Icon name="trash" /> Löschen
                </ConfirmButton>
            </Grid.Column>
        </Grid.Row>
    );
};


const ProfileComments = ({profileSlug, onNewComment}) => {
    const response = useSelector(state => getProfileComments(state, profileSlug));
    const comments = (response && response.get('result')) || Immutable.List();
    return (
        <Segment className="transparent profile-comments-container">
            <Header as="h2" className="cuddle profile" textAlign="center">
                Bewertungen
            </Header>
            <Container className="profile-comments">
                {comments.map(comment =>
                    <ProfileComment data={comment} key={comment.get('slug')}
                        profileSlug={profileSlug}
                    />
                )}
            </Container>
            <CommentLoader slug={profileSlug} onNewComment={onNewComment}/>
            <CommentError slug={profileSlug}/>
        </Segment>
    );
};

const DeletedUser = ({user}) => {
    return (<>
        <LogoSegment />
        <Segment className="transparent">
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment inverted className="profile view"
                            textAlign="center"
                        >
                            Dieses Profil wurde gelöscht.
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </>);
};

const ProfilePage = () => {
    const authed = useSelector(isAuthed);
    const ownSlug = useSelector(getAuthUserId);
    const [commenting, setCommenting] = useState(false);
    const params = useParams();
    let {slug} = params; // user slug
    let ownProfile = false;
    if (isPresent(slug)) {
        if (slug === ownSlug) {
            ownProfile = true;
        }
    } else {
        slug = ownSlug;
        ownProfile = true;
    }
    const dispatch = useDispatch();
    const isOnline = useSelector(getOnlineUsers).findIndex(
        (u) => u.get("slug") === slug
    ) > -1;
    useEffect(() => {
        dispatch(fetchProfile(slug));
        return () => {
            dispatch(clearProfileComments(slug));
        };
    }, [slug]);
    const profile = useSelector(state => getProfile(state, slug));
    if (!authed) {
        return <Redirect to="/" />;
    }
    if(profile === undefined || profile.get === undefined){
        //TODO: Loading?
        return null;
    }
    if(profile.get('deleted') === true) {
        return (<DeletedUser user={profile} />);
    }
    const positions = Immutable.Map({0:"", 1:"", 2:"", 3:"", 4:"", 5:""})
        .merge(profile.get("cuddling_positions")).takeWhile(
            (v, k) => k >= 0 && k < 6 && isPresent(v));
    const spaces = profile.get("cuddling_space", Immutable.Map()).filter(v => v)
        .map((v, k) => PROFILE_CUDDLING_SPACES[k] ).valueSeq().toArray().join(", ");
    return (<>
        <LogoSegment />
        <Segment className="transparent">
            <Header as="h1" className="cuddle profile" textAlign="center">
                {ownProfile ? "Dein Profil" : (profile.get('nickname')) ?
                        `Profil von ${profile.get('nickname')}` : "Neues Profil"}
            </Header>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Segment inverted className="profile left view">
                            <Image src={profile.get('profile_image_url')}
                                circular className="profile-image"
                            />
                            <div className="profile-name">{profile.get('nickname')}</div>
                            <div className="profile-status">
                                <div className="label">Status:</div>
                                {isOnline?"Online":"Offline"}
                            </div>
                            <div className="profile-location">
                                <div className="label">Ort:</div>
                                {profile.get('location')}
                            </div>
                            <div className="profile-ranking">
                                <div className="label">Bewertungen</div>
                                <Stars count={profile.get('rating')} of={5} size="big"/>
                            </div>
                            <div className="profile-edit-button">
                                {ownProfile ? (
                                    <Button as={NavLink} to="/profile/edit">
                                        Profil Bearbeiten
                                    </Button>
                                ) : (
                                    <Button onClick={() => setCommenting(true)}>
                                        Kuschler*in Bewerten
                                    </Button>
                                )}
                            </div>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Segment inverted className="profile view">
                            <div className="profile-description">
                                <div className="label">Beschreibung</div>
                                <RenderContent
                                    content={profile.get('description') ?? ""}
                                />
                            </div>
                            <div className="profile-cuddling-space">
                                <div className="label">Kuscheln geht:</div>
                                {spaces}
                            </div>
                            <div className="profile-cuddling-type">
                                <div className="label">Kuschelart:</div>
                                <Slider value={profile.get('cuddling_type')}
                                    readOnly showFill={false}
                                >
                                    <Slider.Position value={0} label="Ruhig" />
                                    <Slider.PositionRange from={1} to={8} />
                                    <Slider.Position value={9} label="Aktiv" />
                                </Slider>
                                <Slider value={profile.get('cuddling_touch')}
                                    readOnly showFill={false}
                                >
                                    <Slider.Position value={0} label="Streicheln" />
                                    <Slider.PositionRange from={1} to={8} />
                                    <Slider.Position value={9} label="Kneten" />
                                </Slider>
                                <Slider value={profile.get('cuddling_talk')}
                                    readOnly showFill={false}
                                >
                                    <Slider.Position value={0} label="Schweigen" />
                                    <Slider.PositionRange from={1} to={8} />
                                    <Slider.Position value={9} label="Viel Reden" />
                                </Slider>
                            </div>
                            <div className="profile-favorite-positions">
                                <div className="label">Meine Lieblingspositionen:</div>
                                <Grid columns={3} stackable>
                                    {positions.entrySeq().map(
                                        ([key, pos]) => (
                                        <Grid.Column key={key}>
                                            <div className="profile-position"
                                                title={pos}
                                            >
                                                {pos}
                                            </div>
                                        </Grid.Column>
                                    ))}
                                    {positions.isEmpty() ?
                                        <div className="no-positions">
                                            Nicht angegeben
                                        </div> : null
                                    }
                                </Grid>
                            </div>
                            <div className="profile-chat-button">
                                {!ownProfile?
                                    <Button className="profile" size="large"
                                        as={NavLink}
                                        to={`/chat-to/${profile.get('slug')}`}
                                    >
                                        Jetzt Anschreiben
                                    </Button>
                                :null}
                            </div>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <CommentDialog open={commenting} profileSlug={profile.get('slug')}
                onClose={() => setCommenting(false)}
            />
        </Segment>
        <ProfileComments profileSlug={profile.get('slug')}
            onNewComment={() => setCommenting(true)}
        />
    </>);
};
export default ProfilePage;
