import React, {useCallback, useEffect, useState, } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {Link, useHistory,} from 'react-router-dom';
import {authenticate} from "../actions";

import {isAuthed} from "../selectors";

import {
    Grid, Header, Segment, Form, Button, Message, Checkbox, Transition,
} from "semantic-ui-react";

const LoginPage = ({}) => {
    const dispatch = useDispatch();
    const [shakeToggler, setShakeToggler] = useState(true);
    const [termsAgreed, setTermsAgreed] = useState(false);

    const onLogin = useCallback((ev) => {
        ev.preventDefault();
        if(termsAgreed) {
            const target = ev.target;
            const email = target.email.value;
            const password = target.password.value;
            dispatch(authenticate(email, password));
        } else {
            setShakeToggler((v) => !v);
        }
        return false;
    }, [termsAgreed]);

    const history = useHistory();
    const authed = useSelector(isAuthed);
    useEffect(() => {
        if (authed) {
            history.push("/");
        }
    }, [authed]);

    return (
        <Grid textAlign='center' className="login-form">
            <Grid.Column style={{ maxWidth: 450}}>
                <Segment stacked>
                    <Header as='h2' className="cuddle" textAlign="center">
                        Bei Kuscheldate anmelden
                    </Header>
                    <Form size="large" onSubmit={onLogin} data-cy="login-form">
                        <Form.Input fluid icon="user" iconPosition="left"
                            placeholder="E-Mail" name="email" type="email"
                            data-cy="username" required
                            autoComplete="username" autoFocus />
                        <Form.Input fluid icon="lock" iconPosition="left"
                            placeholder="Passwort" type="password"
                            data-cy="password"
                            name="password" autoComplete="current-password" />
                        <Transition animation="shake" visible={shakeToggler}>
                            <Message size="mini" className="cuddle">
                                <Checkbox label="Ich willige die Nutzung von
                                    Sitzungscookies ein, die für die Dauer einer
                                    Sitzung im Browser gespeichert werden."
                                    data-cy="terms"
                                    onChange={(ev, data) => setTermsAgreed(data.checked)}
                                />
                            </Message>
                        </Transition>
                        <Button className="cuddle" fluid size="large">
                            Anmelden
                        </Button>
                        <div className="session-alt-actions">
                            <Link to="/password-reset" data-cy="password-reset">
                                Passwort vergessen?
                            </Link>
                            <Link to="/register">Lege hier ein Profil an.</Link>
                        </div>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default LoginPage;
