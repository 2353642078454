import React, {useState} from 'react';
import { useSelector } from 'react-redux'

import { appIsLoading, appLoadingError } from '../selectors';

import {
    Dimmer, Header, Icon, Loader, Transition,
} from "semantic-ui-react";

const LoadingScreen = () => {
    const testEnv = window.Cypress !== undefined;
    const [visible, setVisible] = useState(!testEnv);
    const isLoading = useSelector(appIsLoading);
    const loadingError = useSelector(appLoadingError);
    return (
        <Dimmer active={visible} page className="loading-screen">
            <Transition transitionOnMount animation="scale"
                visible={isLoading} onHide={() => setVisible(false)}
            >
                <Header as="h2" icon inverted>
                    <Icon className="fan" loading/>
                    Hallo !
                    <Header.Subheader>Gleich geht's los...</Header.Subheader>
                </Header>
            </Transition>
        </Dimmer>
    );
};

export default LoadingScreen;