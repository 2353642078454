export function connect(consumer, conversationId, handler=null) {
    return consumer.subscriptions.create({
        channel: "MessagesChannel",
        conversation: conversationId,
    }, {
        connected() {
            // Called when the subscription is ready for use on the server
            //console.log("WS connected messages", this);
            if (handler && handler.connected) {
                handler.connected();
            }
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
            //console.log("WS disconnected messages");
            if (handler && handler.disconnected) {
                handler.disconnected();
            }
        },

        received(data) {
            // Called when there's incoming data on the websocket for this channel
            //console.log("WS messages received:", data);
            if (handler && handler.received) {
                handler.received(data);
            }
        },

        sendMessage(text, key) {
            this.perform('speak', {
                text, key,
            });
        },

        update_last_seen_message(msgId) {
            //console.log('ulsm', msgId);
            this.perform('lastSeen', {msgId});
        }
    });
};
