import React from 'react';


const Dashboard = () => {
  return (<div>
      Moderation
  </div>);
};


export default Dashboard;
