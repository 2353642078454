import React, {useContext} from 'react';
import {Route, Switch, NavLink} from "react-router-dom";
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router';
import {EDIT_PAGE_MODE} from "../../constants";
import {Context as ViewModeContext} from "../../contexts/ViewMode";
import {LogoSegment} from "../ui";

import {
    Grid, Menu, Header, Segment,
} from 'semantic-ui-react';

import {
    Dashboard, PageAdmin, UserAdmin, QuotationAdmin, NavigationAdmin, ContentEditor,
} from './';

import {isAdmin} from '../../selectors';

const AdminMenu = () => {
    return (
        <Menu vertical fluid className="admin-menu">
            <Menu.Item exact as={NavLink} to="/admin/">
                Übersicht
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/nav">
                Navigation
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/pages">
                Seiten
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/quotations">
                Zitate
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/users">
                Benutzer
            </Menu.Item>
        </Menu>
    );
};

const AdminPanel = () => {
    return (<>
        <LogoSegment hidden={true} />
        <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h1">
                            Administration
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={4} mobile={16}>
                        <AdminMenu />
                    </Grid.Column>
                    <Grid.Column computer={12} mobile={16}>
                        <Switch>
                            {/* TODO: check auth and mount only necessary routes */}
                            <Route exact path="/admin" component={Dashboard} />
                            <Route path="/admin/pages/:slug?" component={PageAdmin} />
                            <Route path="/admin/users/:slug?" component={UserAdmin} />
                            <Route path="/admin/quotations/:slug?" component={QuotationAdmin} />
                            <Route path="/admin/nav/" component={NavigationAdmin} />
                            {/* TODO: Need a page not found route here */}
                        </Switch>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </>);
};

const AdminApp = () => {
    const admin = useSelector(isAdmin);
    const {mode} = useContext(ViewModeContext);
    if (!admin) {
        // TODO: show not authorized error message somehow?
        return <Redirect to="/" />;
    }
    return (
        mode !== EDIT_PAGE_MODE ? <AdminPanel /> : <ContentEditor />
    );
};

export default AdminApp;
