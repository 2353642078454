import React from 'react';
import {Route, Switch, NavLink} from "react-router-dom";
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router';
import {LogoSegment} from "../ui";

import {
    Grid, Menu, Header, Segment,
} from 'semantic-ui-react';

import {
    Dashboard, TaskPage, ReviewPage,
} from './';

import {isMod} from '../../selectors';

const ModMenu = () => {
    return (
        <Menu vertical fluid className="mod-menu">
            <Menu.Item exact as={NavLink} to="/mods/">
                Übersicht
            </Menu.Item>
            <Menu.Item as={NavLink} to="/mods/tasks">
                Aufgaben
            </Menu.Item>
            <Menu.Item as={NavLink} to="/mods/reviews">
                Bewertungen
            </Menu.Item>
        </Menu>
    );
};

const ModApp = () => {
    const mod = useSelector(isMod);
    if (!mod) {
        // TODO: show not authorized error message somehow?
        return <Redirect to="/" />;
    }
    return (<>
        <LogoSegment hidden={true} />
        <Segment className="mod-panel">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h1">
                            Moderation
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={4} mobile={16}>
                        <ModMenu />
                    </Grid.Column>
                    <Grid.Column computer={12} mobile={16}>
                        <Switch>
                            {/* TODO: check auth and mount only necessary routes */}
                            <Route exact path="/mods" component={Dashboard} />
                            <Route path="/mods/tasks/:slug?" component={TaskPage} />
                            <Route path="/mods/reviews/:slug?" component={ReviewPage} />
                            {/* TODO: Need a page not found route here */}
                        </Switch>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </>);
};

export default ModApp;
