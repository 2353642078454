export function connect(consumer, handler=null) {
    return consumer.subscriptions.create("NotificationChannel", {
        connected() {
            // Called when the subscription is ready for use on the server
            //console.log("WS connected notification", this);
            if (handler && handler.connected) {
                handler.connected();
            }
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
            //console.log("WS disconnected notification");
            if (handler && handler.disconnected) {
                handler.disconnected();
            }
        },

        received(data) {
            // Called when there's incoming data on the websocket for this channel
            //console.log("WS notification received:", data);
            if (handler && handler.received) {
                handler.received(data);
            }
        },
    });
};
