import React, {useContext} from 'react';
import {NavLink, useLocation,} from 'react-router-dom';
import PropTypes from "prop-types";
import ImmutablePropTypes from 'react-immutable-proptypes';
import {connect, useSelector} from 'react-redux'

import {
    Container, Menu, Button, Segment, Icon, Dropdown, Sticky, Label, Image,
    Sidebar, Responsive,
} from "semantic-ui-react";

import {isPresent, canISee} from "../helpers";
import {
    getNavigationItems, isAdmin, getUnreadChats, getAuthUserName, isAuthed,
    getAuth, isMod, getRole, getUnhandledModTasksCount,
} from "../selectors";

import {
    EDIT_NAVIGATION_MODE, EDIT_PAGE_MODE, REGULAR_MODE,
} from "../constants";

import {AdminNavItems} from "./admin";
import {AdminPageMenuItems} from "./admin";
import {Context as ViewModeContext} from "../contexts/ViewMode";

const IncompleteProfileMessage = () => {
    const auth = useSelector(getAuth);
    const location = useLocation();
    if(/\/profile\/(edit|init)/.test(location?.pathname) ||
       !isPresent(auth.get('email')) ||
       auth.get('locatable') && auth.get('nickname') &&
       auth.get('profile_image_url') &&
       !auth.get('profile_image_url').includes("unset_avatar.svg")) {
        return null;
    } else if (/\/profile\/(edit|init)/.test(location?.pathname) ||
        !isPresent(auth.get('email')) ||
        auth.get('locatable') && auth.get('nickname') &&
        auth.get('profile_image_url') &&
        auth.get('profile_image_url').includes("unset_avatar.svg")) {
        return <div className="incomplete-profile-message" data-cy="incomplete-profile">
            <NavLink to="/profile/edit">
                Bitte klicken Sie hier, um Ihr Profilbild hochzuladen.
            </NavLink>
        </div>
    }
    return <div className="incomplete-profile-message" data-cy="incomplete-profile">
        <NavLink to="/profile/init">
            Bitte drück hier, um dein Profil zu vervollständigen.
        </NavLink>
    </div>
};

const ChatMenuItem = () => {
    const chatUnreadCount = useSelector(getUnreadChats);
    return (
        <Menu.Item header as={NavLink} to="/chat" tabIndex="0">
            <Icon name="chat"/>
            {chatUnreadCount > 0 ?
                <Label color="red" floating className="notification-count">
                    {chatUnreadCount}
                </Label> : null
            }
        </Menu.Item>
    );
}

const NavItems = () => {
    const allNavItems = useSelector(getNavigationItems);
    const myrole = useSelector(getRole);
    const navItems = allNavItems.filter(
        m => m.get('active') && m.get('location') === 'top' &&
        canISee(myrole, m.get('visibility')));
    return navItems.map(m => (m.get('path').indexOf('home') >= 0) ? (
        <Menu.Item header name="brand" as={NavLink} to="/" exact
            key={m.get('slug')} tabIndex="0"
        >
            <Image src="/images/logo_header.svg" alt="Kuscheldate"/>
        </Menu.Item>
    ) : (
        <Menu.Item name={m.get('title')} key={m.get('slug')}
            as={NavLink} to={m.get('path')} exact
            tabIndex="0"
        >
            {m.get('title')}
        </Menu.Item>
    ));
};

const UserMenu = () => {
    const admin = useSelector(isAdmin);
    const mod = useSelector(isMod);
    const auth = useSelector(getAuth);
    const modTasks = useSelector(getUnhandledModTasksCount);
    const hasImportant = modTasks > 0;
    const trigger = (
        <span data-cy="usermenu">
            <Icon.Group>
                <Icon name="user" />
                {hasImportant ? <Icon name="exclamation circle" color="red"
                    className="user-header-notification"
                /> : null}
            </Icon.Group><span className="mobile hidden" data-cy="authname">
                {auth.get('nickname')}
            </span>
        </span>
    );
    return (
        <Dropdown trigger={trigger} item className="user-menu">
            <Dropdown.Menu>
                <Dropdown.Item ey="name" className="mobile only name disabled">
                    Hallo {auth.get('nickname')}
                </Dropdown.Item>
                <Dropdown.Item key="profile" as={NavLink} to="/profile" tabIndex="0">
                    Profil
                </Dropdown.Item>
                <Dropdown.Item key="settings" as={NavLink} to="/settings" tabIndex="0">
                    Einstellungen
                </Dropdown.Item>
                {(admin || mod) ? <Dropdown.Divider /> : null}
                {admin ? <Dropdown.Item key="admin" as={NavLink} to="/admin/" tabIndex="0">
                    Administration
                </Dropdown.Item> : null}
                {mod ? <Dropdown.Item key="mod" as={NavLink} to="/mods/" tabIndex="0">
                    Moderation
                    {modTasks > 0 ? <Label color="red" corner="right" size="mini">
                        {modTasks}
                    </Label> : null}
                </Dropdown.Item> : null}
                <Dropdown.Divider />
                <Dropdown.Item key="logout" as={NavLink} to="/logout"
                    tabIndex="0" data-cy="logout"
                >
                    Ausloggen
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const AuthButtons = () => {
    return (
        <Menu.Item position="right" name="account">
            <Menu secondary>
                <Menu.Item as={NavLink} to="/login" tabIndex="0">
                    <Icon name="user outline" /> Anmelden
                </Menu.Item>
            </Menu>
        </Menu.Item>
    );
};

const AccountItem = () => {
    const authed = useSelector(isAuthed);
    const auth = useSelector(getAuth);
    if(authed) {
       if (auth.get('profile_image_url') &&
           !auth.get('profile_image_url').includes("unset_avatar.svg")) {
                return (
                    <Menu.Item position="right" name="account">
                        <Menu className="right" secondary>
                            <ChatMenuItem />
                            <UserMenu />
                        </Menu>
                    </Menu.Item>
                );
        } else {
            return (
                <Menu.Item position="right" name="account">
                    <Menu className="right" secondary>
                        <UserMenu />
                    </Menu>
                </Menu.Item>
            );
        }
    } else {
        return <AuthButtons />
    }
};

const Header = ({stickyRef, onSidebarToggle}) => {
    const {mode} = useContext(ViewModeContext);
    return (
        <Sticky context={stickyRef} className="header-sticky">
            <Segment className="cuddle" inverted as="header">
                <div className="header_wrapper">
                    <Container>
                        <Menu size="large" className="cuddle"
                            inverted borderless
                        >
                            <Menu.Item>
                                <Menu secondary>
                                    <Responsive maxWidth={Responsive.onlyTablet.maxWidth} as={React.Fragment}>
                                        <Menu.Item onClick={onSidebarToggle}>
                                            <Icon name="sidebar" />
                                        </Menu.Item>
                                    </Responsive>
                                    <Responsive {...Responsive.onlyComputer} as={React.Fragment}>
                                        {(mode === EDIT_NAVIGATION_MODE) ?
                                            <AdminNavItems location="top"/> :
                                            (
                                                (mode === EDIT_PAGE_MODE) ?
                                                    <AdminPageMenuItems /> :
                                                    <NavItems/>
                                            )
                                        }

                                    </Responsive>
                                </Menu>
                            </Menu.Item>
                            {(mode === REGULAR_MODE) ? <AccountItem /> : null}
                        </Menu>
                        {(mode === REGULAR_MODE) ? <IncompleteProfileMessage /> : null}
                    </Container>
                </div>
            </Segment>
        </Sticky>
    );
}

const MobileSidebar = ({visible}) => {
    return (
        <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            vertical
            visible={visible}
            className="page-sidebar"
        >
            <NavItems />
        </Sidebar>
    );
}

Header.Sidebar = MobileSidebar;

export default Header;
