import React, {
    useRef, useState, useEffect, useCallback, useMemo,
} from 'react';
import { useSelector, useDispatch, } from 'react-redux'
import {DirectUploadProvider} from 'react-activestorage-provider';

import {
    Form, Button, Icon, Progress, Dimmer, Input,
} from 'semantic-ui-react';

import {
    getAuthToken, getClientAud,
} from '../../selectors';

import {
    setAuthToken,
} from '../../actions';

import {
    isPresent,
} from '../../helpers';

class UploadProvider extends DirectUploadProvider {
    handleUpload = async (files) => {
        this.handleChooseFiles(files)
        return this.handleBeginUpload().catch(() => {
            this.setState({
                uploading: false,
            });
        });
    }

    render(){
        const RenderComp = this.props.render;
        const { fileUploads } = this.state;
        return <RenderComp
            handleChooseFiles={this.handleChooseFiles}
            handleBeginUpload={this.handleBeginUpload}
            handleUpload={this.handleUpload}
            ready={!this.state.uploading}
            uploads={Object.keys(fileUploads).map(key => fileUploads[key])}
            inputRef={this.props.inputRef}
            onChange={this.props.onChange}
        />;
    }
}

export const readImage = (files, cb) => {
    if(files && files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
            cb(e.target.result);
        };
        reader.readAsDataURL(files[0]);
    }
};

const UploadProgress = ({children, disabled, progress, onChange, ongoing, indeterminate, finished}) => {
    if (finished) {
        progress = 100;
        indeterminate = false;
        ongoing = true;
    }
    return (
        <Button as="label" className="imageupload">
            {children}
            <Form.Input
                type="file"
                accept="image/*"
                className="transition hidden"
                disabled={disabled}
                onChange={e => onChange(e.currentTarget.files)}
            />
            <Dimmer active={ongoing} inverted className="fluid">
                <Progress percent={progress} active success={finished}
                    className={indeterminate? "indeterminate" : ""}
                />
            </Dimmer>
        </Button>
    );
};

const UploadRenderer = (children, onChange, withForm) => ({ handleUpload, uploads, ready }) => {
    const indeterminate = uploads.map(o => o.state == "waiting").some(
        (x) => x === true);
    const ongoing = uploads.length > 0;
    const progress = uploads.map(
        o => o.progress !== undefined ? o.progress : 0
    ).reduce(
        (x, prev) => (prev + x), 0
    ) / uploads.length;
    const finished = ongoing && uploads.map(o => o.state == "finished").every(
        (x) => x === true);
    const FormWrapper = withForm ? Form : React.Fragment;
    return (
        <FormWrapper>
            <Form.Field>
                <UploadProgress
                    disabled={!ready}
                    finished={finished}
                    ongoing={ongoing}
                    indeterminate={indeterminate}
                    progress={ongoing ? progress : 0}
                    onChange={(f) => onChange && onChange(f, handleUpload)}
                >
                    {children}
                </UploadProgress>
            </Form.Field>
        </FormWrapper>
    );
};

const Uploader = ({children, onUpload, withForm, onChange}) => {
    const authToken = useSelector(getAuthToken);
    const client_aud = useSelector(getClientAud);
    const dispatch = useDispatch();
    const extractSession = useCallback(({id, file, xhr}) => {
        xhr.addEventListener("readystatechange", (event) => {
            if(xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED){
                const authToken = xhr.getResponseHeader("Authorization");
                if(isPresent(authToken)) {
                    const token = authToken.slice(7);
                    dispatch(setAuthToken(token));
                }
            }
        });
    });
    if (!isPresent(authToken)) return;
    return (
        <DirectUploadProvider
            key="uploader"
            directUploadsPath="/api/v1/direct_uploads"
            headers={{
                "Authorization": `Bearer ${authToken}`,
                "JWT_AUD": client_aud,
            }}
            onSuccess={(e) => onChange && onChange(e)}
            onBeforeBlobRequest={extractSession}
            onBeforeStorageRequest={extractSession}
            render={UploadRenderer(children, onUpload, withForm)}
        />
    );
}

const ImageUpload = ({children, withForm=true, onChange, onPreview, handleChange}) => {
    const handleChangeDefault = (files, uploadFn) => {
        uploadFn(files);
        if(onPreview) {
            readImage(files, (url) => onPreview(url));
        }
    };
    const _handleChange = handleChange || handleChangeDefault;
    return (
        <Uploader withForm={withForm}
            onChange={onChange}
            onUpload={(files, uploadFn) => _handleChange(files, uploadFn)}
        >
            {children}
        </Uploader>
    );
};


const ProxyRenderer = ({inputRef, onChange, ready, uploads, handleUpload}) => {
    //console.log('renderer args', inputRef, ready, uploads);
    useEffect(() => {
        onChange && onChange({
            upload: handleUpload,
            file: null,
            progress: 0,
            finished: true,
            indeterminate: false,
            uploading: false,
            canCrop: false,
            error: false,
            errorMsg: null,
            uploadSids: [],
        });
    }, []);
    const indeterminate = uploads.every(o => o.state === "waiting");
    const ongoing = uploads.length > 0;
    const progress = uploads.length > 0 ? (uploads.map(
        o => o.progress !== undefined ? o.progress : 0
    ).reduce((x, prev) => (prev + x), 0) / uploads.length) : 0;
    const finished = !ongoing || uploads.every(o => o.state === "finished");
    const error = uploads.some(o => o.state === "error");
    useEffect(() => {
        const errorMsg = error && uploads.reduce((msg, o) => {
            if (o.state === "error") {
                if (msg.length > 0) {msg += ", ";}
                msg += `${o.error}`;
            }
            return msg;
        }, "");
        onChange && onChange({
            upload: handleUpload,
            progress,
            uploading: ongoing,
            finished,
            indeterminate,
            canCrop: false,
            error,
            errorMsg,
        });
    }, [progress, ongoing, indeterminate, finished, error]);
    return (
        <Input
            ref={inputRef}
            type="file"
            accept="image/*"
            className="transition hidden"
            disabled={!ready}
            onChange={e => onChange && onChange({
                upload: handleUpload,
                file: e.currentTarget.files[0],
                progress: 0,
                finished: false,
                indeterminate: true,
                uploading: false,
                canCrop: true,
                error: false,
                errorMsg: null,
                uploadSids: [],
            })}
            data-cy="upload-input"
        />
    );
};

export const ImageUploadProxy = ({onChange, inputRef, purpose="default"}) => {
    const authToken = useSelector(getAuthToken);
    const client_aud = useSelector(getClientAud);
    const dispatch = useDispatch();
    const state = useRef({});
    const extractSession = useCallback(({id, file, xhr}) => {
        xhr.addEventListener("readystatechange", (event) => {
            if(xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED){
                const authToken = xhr.getResponseHeader("Authorization");
                if(isPresent(authToken)) {
                    const token = authToken.slice(7);
                    dispatch(setAuthToken(token));
                }
            }
        });
    });
    if (!isPresent(authToken)) return;
    return (
        <UploadProvider
            directUploadsPath={`/api/v1/direct_uploads?purpose=${purpose}`}
            headers={{
                "Authorization": `Bearer ${authToken}`,
                "JWT_AUD": client_aud,
            }}
            onSuccess={(e) => {
                const o = Object.assign({}, state.current,
                    {
                        canCrop: false,
                        file: null,
                        progress: 1,
                        uploading: false,
                        upload: () => null,
                        uploadSids: e,
                        finished: true,
                        error: false,
                        errorMsg: null,
                    }
                );
                state.current = o;
                console.log('_su', e, o);
                onChange && onChange(o);
            }}
            onBeforeBlobRequest={extractSession}
            onBeforeStorageRequest={extractSession}
            render={ProxyRenderer}
            inputRef={inputRef}
            onChange={(e) => {
                const o = Object.assign({}, state.current, e);
                state.current = o;
                console.log('_ch', e, o);
                onChange && onChange(o);
            }}
        />
    );
};

export default ImageUpload;
