import React, {useEffect} from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {useSelector} from "react-redux";

import {Quotation, LogoSegment, RenderContent,} from "./ui";

import {Header, Segment} from 'semantic-ui-react';

import {getPage} from "../selectors";

import {
    isPresent, isDefined,
} from '../helpers';


const Block = ({block, sub}) => {
    const items = [];
    let style = {};
    const subblocks = (block.get('blocks') || Immutable.List()).sortBy(
        sb => sb.get('order')
    );
    if (subblocks !== undefined && subblocks.size > 0) {
        for(const sb of subblocks) {
            items.push(<Block block={sb} sub key={sb.get('slug')}/>);
        }
    }
    if (isDefined(block.get('style'))) {
        const st = (block.get('style') || Immutable.Map()).toJS();
        if(isPresent(st['backgroundImage'])) {
            st['backgroundImage'] = `url("${st['backgroundImage']}")`;
        }
        style = {...style, ...st};
    }
    const cn = block.get('class_names');
    const body = block.get('body');
    return (
        <div className={["cd-block", cn].join(' ')} key={block.get('slug')} style={style}>
            {isDefined(body) ?
                <div className="cd-text">
                    <RenderContent content={body} />
                </div>
            : null}
            {items.length>0?
                <div className="cd-container">
                    {items}
                </div>
            : null}
        </div>
    );
};

const Page = ({slug, quotes=true, logo=true}) => {
    const page = useSelector((state) => getPage(state, slug));
    useEffect(() => {
        if(page && page.get) {
            const top_image_url = page.get('top_image_url');
            const bottom_image_url = page.get('bottom_image_url');
            const el = document.querySelector('.bgPane');
            let bgs = el.style.backgroundImage.split(',');
            if(isPresent(top_image_url)) {
                bgs[0] = `url(${top_image_url})`;
            }
            if(isPresent(bottom_image_url)) {
                bgs[1] = `url(${bottom_image_url})`;
            }
            el.style.backgroundImage = bgs.join(',');
        }
    }, [page]);
    if (!page || !page.get) {
        return null;
    }
    const blocks = (page.get('blocks') || Immutable.List()).sortBy(
        b => b.get('order')
    );
    return (
        <>
            {logo?<LogoSegment />:null}
            {blocks.map((block) => (
                <Block block={block} key={block.get('slug')} />
            ))}
            {quotes?<Quotation />:null}
        </>
    );
}

export default Page;
