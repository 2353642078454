import Immutable from 'immutable';
import {createSelector} from 'reselect'
import {isPresent, randomInt} from "../helpers";

export const getPages = (state) => state.pages;
export const getPage = (state, slug) => getPages(state).get(slug);
export const getMenuItems = (state) => state.menu_items;

export const getAuth = (state) => state.auth;
export const getAuthToken = (state) => getAuth(state).get("token");
export const isAuthed = createSelector(
    [getAuth, getAuthToken],
    (auth, token) => isPresent(auth.get('email')) && isPresent(token)
);
export const getClientAud = (state) => getAuth(state).get("client_aud");
export const isAdmin = createSelector(
    [getAuth, isAuthed],
    (auth, authenticated) => authenticated && auth.get('role') === "admin"
);
export const isMod = createSelector(
    [getAuth, isAuthed],
    (auth, authenticated) => authenticated && (
        auth.get('role') === "admin" || auth.get('role') === "mod")
);
export const getRole = (state) => getAuth(state).get('role');
export const getRegistration = (state) => state.registration;

export const getNavigationItems = createSelector(
    [getMenuItems],
    (menuItems) => menuItems.sortBy(m => m.get('order')).valueSeq().toList()
);

export const getToastMessages = (state) => state.app.get('toastMessages', Immutable.List());
export const getModalMessages = (state) => state.app.get('modalMessages', Immutable.List());
export const appIsLoading = (state) => state.app.get('loading', true);
export const appLoaded = (state) => state.app.get('loaded', false);
export const appLoadingError = (state) => state.app.get('loadingError');

export const getAdminData = (state) => state.admindata;
export const getQuotations = (state) => state.quotations;
export const getQuotationEdit = (state) => getAdminData(state).get('quotationEdit', Immutable.Map());
export const getRandomQuote = (state) => {
    const qs = getQuotations(state).get('result');
    const keys = qs.keySeq();
    const key = keys.get(randomInt(keys.size));
    return key && qs.get(key);
};
export const getMenuEdit = (state) => getAdminData(state).get('menuEdit', Immutable.Map());
export const getPageEdit = (state) => getAdminData(state).get('pageEdit', Immutable.Map());
export const getUserEdit = (state) => getAdminData(state).get('userEdit', Immutable.Map());
export const getUserInvite = (state) => getAdminData(state).get('userInvite', Immutable.Map());
export const getUserDelete = (state) => getAdminData(state).get('userDelete', Immutable.Map());

export const getOnlineUsers = (state) => state.online_users;

export const getConversations = (state) => state.chat.get('conversations');
export const getMessages = (state) => state.chat.get('messages');
export const getUnreadChatCount = (state) => state.chat.get('unreadCount');
export const getChatOnline = (state) => state.chat.get('online');

export const getUnhandledModTasksCount = (state) => state.notifications.get('modTasks');

export const getSearchUsers = (state) => state.search.get('users');
export const getSearchGroups = (state) => state.search.get('groups');
export const getSearchGeolocations = (state) => state.search.get('geolocations');
export const getProfiles = (state) => state.profiles;
export const getProfile = (state, userSlug) => getProfiles(state).get(userSlug);
export const getProfileComments = (state, userSlug) => state.profile_comments.get(userSlug);
export const getProfileImages = (state) => state.profile_images;
export const getProfileEdit = (state) => state.profile_edit;

export const getGroups = (state) => state.groups.get('all');
export const getNewGroup = (state) => state.groups.get('new');
export const getGroup = (state, groupSlug) => getGroups(state).get(groupSlug, Immutable.Map());
export const getGroupComments = (state, groupSlug) => state.group_comments.get(groupSlug, Immutable.Map());

export const getAuthUserId = createSelector(
    [getAuth, isAuthed],
    (auth, isAuth) => isAuth ? auth.get('slug') : null
);

export const getAuthUserName = createSelector(
    [getAuth, isAuthed],
    (auth, isAuth) => isAuth ? auth.get('nickname') : null
);

const conversationMemos = {};
const messageMemos = {};

const genConversationSelector = (slug) => {
    return conversationMemos[slug] = createSelector(
        [getConversations],
        (conv) => conv.find(x => x.get('slug') === slug)
    );
};

const genMessagesSelector = (slug) => {
    return messageMemos[slug] = createSelector(
        [getMessages],
        (messageMap) => messageMap.get(slug, Immutable.List())
    );
};

export const getConversation = (state, slug) => conversationMemos[slug] ?
    conversationMemos[slug](state) :
    genConversationSelector(slug)(state);

export const getConversationMessages = (state, slug) => messageMemos[slug] ?
    messageMemos[slug](state) :
    genMessagesSelector(slug)(state);

export const getUnreadChats = createSelector(
    [getUnreadChatCount],
    (chatCount) => chatCount.reduce((sum, count) => sum + ((count > 0)? 1 : 0), 0)
);

export const getRedirectUrl = (state) => state.redirect;

export const getModTasks = (state) => state.moddata.get('tasks');
export const getModReviews = (state) => state.moddata.get('reviews');
export const getFetchedModReview = (state) => state.moddata.get('fetched_review');

export const getUserAccountData = (state) => state.settings;

export const getPasswordReset = (state) => state.password_reset;
