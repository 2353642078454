import React, {useCallback, useEffect} from 'react';
import Immutable from "immutable";
import { useSelector, useDispatch } from 'react-redux';
import {Link} from 'react-router-dom';

import {acceptInvitation} from "../actions";
import {getRegistration} from "../selectors";
import {isPresent, isDefined, useQuery, fieldErr,} from "../helpers";

import {
    Grid, Header, Segment, Form, Button, Message, Dimmer, Loader,
} from "semantic-ui-react";

const InvitationForm = ({registration, token}) => {
    const dispatch = useDispatch();
    const onRegister = useCallback((ev, d) => {
        ev.preventDefault();
        const t = ev.target;
        const nickname = t.nickname.value;
        const pw = t.pw.value;
        const pwc = t.pwc.value;
        dispatch(acceptInvitation(token, nickname, pw, pwc));
        return false;
    });
    const errors = registration.get('errors');
    const tokenError = fieldErr(errors, 'invitation_token');
    return (<>
        <Header as='h2' className="cuddle" textAlign="center">
            Ein Profil anlegen
        </Header>
        <p>
            Hallo! Du wurdest zu Kuscheldate eingeladen. Bitte vervollständige
            deine Daten, um ein Profil auf Kuscheldate anzulegen.
        </p>
        <Form size="large" onSubmit={onRegister} error={isDefined(errors)}>
            {isPresent(tokenError) ? <Message negative>
                Die Einladung {tokenError}
            </Message>: null}
            <Form.Input fluid icon="user" iconPosition="left"
                placeholder="Benutzername" name="nickname" type="text"
                autoComplete="nickname" autoFocus required
                error={fieldErr(errors, 'nickname')} />
            <Form.Input fluid icon="lock" iconPosition="left"
                placeholder="Passwort" type="password" required
                name="pw" autoComplete="new-password"
                error={fieldErr(errors, 'password')} />
            <Form.Input fluid icon="lock" iconPosition="left"
                placeholder="Passwort wiederholen" type="password" required
                name="pwc"
                autoComplete="confirm-password"
                error={fieldErr(errors, 'password_confirmation')} />
            {isPresent(errors)?<Message error>{errors}</Message>:null}
            <Button className="cuddle" fluid size="large">
                Profil abschließen
            </Button>
        </Form>
    </>);
};

const InvitationDone = ({registration}) => {
    const message = registration.get('message');
    return (
        <Message>
            <Message.Header>Geschafft!</Message.Header>
            <p>{message}</p>
            <Link to="/login">
                Anmelden
            </Link>
        </Message>
    );
};

const InvitationPage = ({}) => {
    const registration = useSelector(getRegistration);
    const query = useQuery();
    const token = query.get('invitation_token');
    const isLoading = registration.get('loading') || false;
    const success = registration.get('success');
    return (
        <Grid textAlign='center'>
            <Grid.Column style={{ maxWidth: 450}}>
                <Segment stacked>
                    {success?
                        <InvitationDone registration={registration} /> :
                        <InvitationForm registration={registration} token={token} />
                    }
                    <Dimmer active={isLoading}>
                        <Loader>Profil wird angelegt</Loader>
                    </Dimmer>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

InvitationPage.defaultProps = {};
InvitationPage.propTypes = {};

export default InvitationPage;
