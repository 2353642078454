import ModApp from "./ModApp";
import Dashboard from "./Dashboard"
import TaskPage from "./TaskPage"
import ReviewPage from "./ReviewPage"

export {
    Dashboard,
    TaskPage,
    ReviewPage,
};

export default ModApp;
