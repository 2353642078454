import Immutable from "immutable";
import {genAUD} from "../helpers";

export const AUTH = Immutable.fromJS({
    email: null,
    nickname: null,
    profile_image_url: null,
    slug: null,
    errorMessage: null,
    role: 'guest',
    token: window.localStorage.session_token,
    client_aud: genAUD(),
});

export const REGISTRATION = Immutable.fromJS({
    loading: false,
    errors: null,
    success: false,
    message: null,
    email: null,
});

export const PROFILES = Immutable.fromJS({
});

export const PROFILE_COMMENTS = Immutable.fromJS({
});

export const PROFILE_IMAGES = Immutable.fromJS({
    loading: false,
    error: null,
    images: [],
    currentImageId: null,
    uploadQuota: 0,
});

export const PROFILE_EDIT = Immutable.fromJS({
    loading: false,
    error: null,
    result: null,
});

export const GROUPS = Immutable.fromJS({
    all: {},
    new: {},
});

export const GROUP_COMMENTS = Immutable.fromJS({
});

export const APP = Immutable.fromJS({
    toastMessages: [],
    modalMessages: [],
    loaded: false,
    loading: true,
    loadingError: null,
});

export const PAGES = Immutable.fromJS({
    home: {
        title: "Wird geladen...",
        loading: true,
        error: null,
        slug: "home",
        menu_item_id: null,
        _internal: true,
    }
});

export const MENU_ITEMS = Immutable.fromJS({
});

export const ONLINE_USERS = Immutable.fromJS([
]);

export const CHAT = Immutable.fromJS({
    conversations: [],
    messages: {},
    users: {},
    unreadCount: {},
    online: false,
});

export const NOTIFICATIONS = Immutable.fromJS({
    modTasks: 0,
});

export const SEARCH = Immutable.fromJS({
    users: {
        loaded: false,
        loading: false,
        error: false,
        result: {},
    },
    groups: {
        loaded: false,
        loading: false,
        error: false,
        result: {},
    },
    geolocations: {
        loaded: false,
        loading: false,
        error: false,
        result: [],
    }
});

export const REDIRECT = "";

export const ADMINDATA = Immutable.fromJS({
    quotationEdit: {
        error: null,
        loading: false,
    },
    menuEdit: {
        entries: {top: [], bottom: []},
        error: null,
        saving: false,
        done: true,
    },
    pageEdit: {
        page: {},
        error: null,
        saving: false,
        done: true,
    },
    userEdit: {
        users: [],
        error: null,
        loading: false,
    },
    userInvite: {
        error: null,
        loading: false,
        success: false,
        message: "",
    },
    userDelete: {
        error: null,
        loading: false,
        success: false,
        message: "",
    }

});

export const QUOTATIONS = Immutable.fromJS({
    loading: false,
    loaded: true,
    error: null,
    result: []
});

export const MODDATA = Immutable.fromJS({
    tasks: {
        loading: false,
        loaded: true,
        error: null,
        result: []
    },
    reviews: {
        loading: false,
        error: null,
        results: []
    },
    fetched_review: {
        loading: false,
        error: null,
        result: {}
    },

});

export const SETTINGS = Immutable.fromJS({
    loading: false,
    error: null,
    termination: {
        status: null,
        error: null,
    },
    password: {
        loading: false,
        error: null,
        success: false,
    },
    notifications: 0,
});

export const PASSWORD_RESET = Immutable.fromJS({
    loading: false,
    error: null,
    message: null,
});