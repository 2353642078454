import React, {useState} from 'react';
import { REGULAR_MODE } from "../constants";

export const Context = React.createContext({
    mode: REGULAR_MODE,
    setMode: () => {},
});

const ViewMode = ({children}) => {
    const [mode, setMode] = useState(REGULAR_MODE);
    return (
        <Context.Provider value={{mode, setMode}}>
            {children}
        </Context.Provider>
    );
};

export default ViewMode;
