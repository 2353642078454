import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {
    Container, Segment, Menu, Image,
} from 'semantic-ui-react';
import {
    EDIT_NAVIGATION_MODE,
} from "../constants";

import {getNavigationItems, getRole} from '../selectors';
import {AdminNavItems} from "./admin";
import {Context as ViewModeContext} from "../contexts/ViewMode";
import {canISee} from '../helpers';

const NavItems = () => {
    const allNavItems = useSelector(getNavigationItems);
    const myrole = useSelector(getRole);
    const navItems = allNavItems.filter(
        m => m.get('active') && m.get('location') === 'bottom' &&
        canISee(myrole, m.get('visibility')));
    return navItems.map(m => (
        <Menu.Item name={m.get('title')} key={m.get('slug')}
            as={NavLink} to={m.get('path')} tabIndex="0"
        >
            {m.get('title')}
        </Menu.Item>
    ));
};

const Footer = () => {
    const {mode} = useContext(ViewModeContext);
    return (
        <Segment className="cuddle" inverted as="footer">
            <Container>
                <Menu secondary stackable inverted fluid>
                    <Menu.Item>
                        <Image src="/images/kd_logo.svg" className="logo_kuscheldate" />
                    </Menu.Item>
                    <Menu.Item className="pages_container">
                        <Menu secondary stackable inverted fluid className="pages_menu">
                            {(mode === EDIT_NAVIGATION_MODE) ? <AdminNavItems location="bottom"/> : <NavItems/>}
                        </Menu>
                    </Menu.Item>
                    <Menu.Item>
                        <Image src="/images/kuschelkiste.png" className="logo_kuschelkiste" />
                    </Menu.Item>
                </Menu>
            </Container>
        </Segment>
    );
}

export default Footer;