import AdminApp from "./AdminApp";
import Dashboard from "./Dashboard";
import PageAdmin from "./PageAdmin";
import UserAdmin from "./UserAdmin";
import QuotationAdmin from "./QuotationAdmin";
import NavigationAdmin from "./NavigationAdmin";
import AdminNavItems from "./AdminNavItems";
import AdminPageMenuItems from "./AdminPageMenuItems";
import ContentEditor from "./ContentEditor";
export {
    Dashboard,
    PageAdmin,
    UserAdmin,
    QuotationAdmin,
    NavigationAdmin,
    AdminNavItems,
    AdminPageMenuItems,
    ContentEditor,
};

export default AdminApp;
