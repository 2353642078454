import React, {useEffect, useState, useRef} from 'react';
import {isPresent} from '../../helpers';

const useFocusDetector = (element) => {
    const [focused, setFocused] = useState(false);
    useEffect(() => {
        const onFocus = (e) => {
            setFocused(true);
        };
        const onBlur = (e) => {
            setFocused(false);
        };
        if(element.current !== null) {
            element.current.addEventListener('focus', onFocus);
            element.current.addEventListener('blur', onBlur);
            element.current.addEventListener('mouseover', onFocus);
            element.current.addEventListener('mouseout', onBlur);
            element.current.addEventListener('touchstart', onFocus);
            document.addEventListener('touchstart', onBlur);
        }
        return () => {
            if(element.current !== null) {
                element.current.removeEventListener('focus', onFocus);
                element.current.removeEventListener('blur', onBlur);
                element.current.removeEventListener('mouseover', onFocus);
                element.current.removeEventListener('mouseout', onBlur);
                element.current.removeEventListener('touchstart', onFocus);
                document.removeEventListener('touchstart', onBlur);
            }
        };
    }, [element.current]);

    return focused;
};

const ToastContainer = ({children}) => {
    return (
        <div className="top right ui toast-container">
            {children}
        </div>
    );
};

const Toast = ({children: content, title, duration=20, onClose}) => {
    const [remaining, setRemaining] = useState(duration * 1000);
    const [closing, setClosing] = useState(false);
    const toastRef = useRef(null);
    const paused = useFocusDetector(toastRef);
    useEffect(() => {
        if(paused || !onClose) return;
        const startTime = Date.now();
        const timerID = setTimeout(() => {
            setClosing(true);
            setTimeout(onClose, 300);
        }, remaining);
        return () => {
            const elapsedTime = Date.now() - startTime;
            if ( elapsedTime > 300) {
                setRemaining(r => Math.max(0, r - elapsedTime));
            }
            clearTimeout(timerID);
        };
    }, [duration, onClose, paused, remaining]);
    return (
        <div className={"floating toast-box compact transition fade up visible " +
            `${closing?'out':'in'}`}
            tabIndex="0"
            style={{display: "table !important"}} ref={toastRef}>
            <div className="cuddle ui toast compact visible">
                {isPresent(title) ? (
                <div className="ui header">
                    {title}
                </div>) : null}
                <div className="content">
                    {content}
                </div>
                <i className="close icon" onClick={onClose}></i>
            </div>
            <div className="ui attached active progress error inverted bottom compact"
                data-percent=""
            >
                <div className="bar down progressing pausable"
                    style={{
                        animationDuration: `${duration}s`,
                        animationPlayState: paused ? "paused" : "running",
                    }}
                />
            </div>
        </div>
    );
};

Toast.Container = ToastContainer;

export default Toast;

