import React, {useCallback, useEffect} from 'react';
import Immutable from "immutable";
import { useSelector, useDispatch } from 'react-redux'
import {Link} from 'react-router-dom';

import {sendConfirmation, sendReconfirmation,} from "../actions";
import {getRegistration} from "../selectors";
import {isPresent, isDefined, useQuery,} from "../helpers";

import {
    Grid, Header, Segment, Form, Button, Message, Dimmer, Loader,
} from "semantic-ui-react";

const Confirmation = ({registration}) => {
    const message = registration.get('message');
    return (
        <Message>
            <Message.Header>E-Mail bestätigen</Message.Header>
            <p>{message}</p>
            <Link to="/login">
                Anmelden
            </Link>
        </Message>
    );
};

const ResendPanel = ({registration}) => {
    const dispatch = useDispatch();
    const errors = registration.get('errors') && registration.get('errors').toJS();
    const email = registration.get('email');
    return (
        <Message error>
            <Message.Header>E-Mail bestätigen</Message.Header>
            {(isDefined(errors) && Object.values(errors).length > 0) ?
                <p>{Object.values(errors)}</p>:
                <p>"Konnte nicht bestätigen. Bitte neu anfordern."</p>
            }
            {isPresent(email)?
                <p>
                    <a onClick={() => dispatch(sendReconfirmation())} style={{cursor: "pointer"}}>
                        Neue Bestätigungs-E-Mail schicken
                    </a>
                </p> : null
            }
            <p>
                <Link to="/">
                    Zurück zur Startseite
                </Link>
            </p>
        </Message>
    );
};

const EmailConfirmationPage = ({}) => {
    const dispatch = useDispatch();
    const registration = useSelector(getRegistration);
    const query = useQuery();
    const token = query.get('confirmation_token');
    useEffect(() => {
        dispatch(sendConfirmation(token));
    }, []);
    const isLoading = registration.get('loading') || false;
    const errors = registration.get('errors');
    return (
        <Grid textAlign='center'>
            <Grid.Column style={{ maxWidth: 450}}>
                <Segment stacked>
                    {isDefined(errors)?
                        <ResendPanel registration={registration} />:
                        <Confirmation registration={registration} />
                    }
                    <Dimmer active={isLoading}>
                        <Loader>E-Mail wird bestätigt</Loader>
                    </Dimmer>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

EmailConfirmationPage.defaultProps = {};
EmailConfirmationPage.propTypes = {};

export default EmailConfirmationPage;
