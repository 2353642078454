import React, {useEffect, useState, useRef} from 'react';
import { NavLink, Switch, Route, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router';
import {BackIcon, LogoSegment} from './ui';

import {
    Segment, Header, Grid, Menu, Button, Form, Responsive, Transition,
    Container, Modal,
} from 'semantic-ui-react';


const AccountDeletedPage = () => {
    return (<>
        <LogoSegment />
        <Segment className="cuddle_bright user-deleted-page" textAlign="center">
            <h3 className="my-3">
                Auf Wiedersehen!
            </h3>
            <p>
                Dein Account wurde gelöscht und deine Sitzung wurde beendet.
            </p>
            <p>
                Wir danken dir, dass wir für eine gewisse Zeit Teil deines
                Lebens sein durften und hoffen, dass wir dein Leben
                zum Besseren verändert haben und positiv in deiner
                Erinnerung verbleiben.
            </p>
            <p>
                Bitte empfehle uns an deine Freunde und Bekannte weiter.
            </p>
            <p>
                Vielen lieben Dank! <br />
                Dein Kuscheldate Team.
            </p>
        </Segment>
    </>);
};

export default AccountDeletedPage;