import React, {useCallback, useEffect, } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {Link,} from 'react-router-dom';
import {
    sendResetPasswordInstructions, clearResetPassword, sendNewPassword,
} from "../actions";

import {isAuthed, getPasswordReset} from "../selectors";

import {isPresent, useQuery, isDefined, fieldErr} from "../helpers";

import {
    Grid, Header, Segment, Form, Button, Message, Checkbox, Transition, Container,
    Dimmer, Loader,
} from "semantic-ui-react";

const AlreadyLoggedIn = () => {
    return (
        <Segment stacked>
            <Message error>
                Du bist bereits eingeloggt. Du kannst dein
                Passwort <Link to="/settings/login">hier</Link> ändern.
            </Message>
        </Segment>
    );
};

const SendInstructionsView = ({}) => {
    const dispatch = useDispatch();
    const state = useSelector(getPasswordReset);
    const isLoading = state.get('loading');
    const errors = state.get('error');
    const successMessage = state.get('message');

    const onSubmit = useCallback((ev) => {
        ev.preventDefault();
        if(isLoading) return false;
        const target = ev.target;
        const email = target.email.value;
        dispatch(sendResetPasswordInstructions(email));
        return false;
    }, [isLoading]);

    useEffect(() => {
        return () => {
            dispatch(clearResetPassword());
        };
    }, []);

    if(useSelector(isAuthed)) {
        return <AlreadyLoggedIn />;
    }

    return (
        <Segment stacked>
            <Header as='h2' className="cuddle" textAlign="center">
                Passwort zurücksetzen
            </Header>
            {isPresent(successMessage)?<Message data-cy="instructions-sent">
                <Message.Header>Erster Schritt geglückt!</Message.Header>
                <p>{successMessage}</p>
            </Message>:<div>
                <Container fluid textAlign="left">
                    <p>
                        Du hast dein Passwort vergessen? Kein Problem!
                        Hiermit kannst du dein Passwort zurücksetzen.
                    </p>
                    <p>
                        Bitte gib die E-Mail Adresse deines Profils an, um
                        den Rücksetzungslink zu erhalten.
                    </p>
                </Container>
                <br />
                <Form size="large" onSubmit={onSubmit} error={isDefined(errors)}
                    data-cy="reset-password-form"
                >
                    <Form.Input fluid icon="user" iconPosition="left"
                        placeholder="E-Mail" name="email" type="email"
                        data-cy="email"
                        autoComplete="username" autoFocus
                        error={fieldErr(errors, 'email')} />
                    <Button className="cuddle" fluid size="large" data-cy="send-instructions">
                        Anleitung per E-Mail schicken
                    </Button>
                </Form>
                {isPresent(errors)?<Message error>
                    <Message.Header>Schritt gescheitert ☹️ </Message.Header>
                    <p>{errors}</p>
                </Message>:null}
            </div>}
            <Dimmer active={isLoading} inverted>
                <Loader active />
            </Dimmer>
        </Segment>
    );
};

const SetNewPasswordView = ({token}) => {
    const dispatch = useDispatch();
    const state = useSelector(getPasswordReset);
    const isLoading = state.get('loading');
    const errors = state.get('error');
    const message = state.get('message');

    const tokenError = fieldErr(errors, 'reset_password_token');

    const onSubmit = useCallback((ev) => {
        ev.preventDefault();
        if(isLoading) return false;
        const target = ev.target;
        const pw = target.pw.value;
        const pwc = target.pwc.value;
        dispatch(sendNewPassword(token, pw, pwc));
        return false;
    }, [isLoading, token]);

    useEffect(() => {
        return () => {
            dispatch(clearResetPassword());
        };
    }, []);

    const isLoggedIn = useSelector(isAuthed);
    if(!isPresent(message) && isLoggedIn) {
        return <AlreadyLoggedIn />;
    }

    return (
        <Segment stacked>
            {isPresent(message)?<Message data-cy="password-changed">
                <Message.Header>Passwort erfolgreich geändert!</Message.Header>
                <p>{message}</p>
                <p><Link to="/profile">Zu meinem Profil</Link></p>
            </Message>:<div>
                <Header as='h2' className="cuddle" textAlign="center">
                    Passwort ändern
                </Header>
                <Form size="large" onSubmit={onSubmit} error={isDefined(errors)}
                    data-cy="set-new-password-form"
                >
                    {isPresent(tokenError) ? <Message error size="tiny">
                        Der Link zum Zurücksetzen des Passworts {tokenError}
                    </Message>: null}
                    <Form.Input fluid icon="lock" iconPosition="left" required
                        placeholder="Neues Passwort" type="password"
                        name="pw" autoComplete="new-password" autoFocus
                        data-cy="password"
                        error={fieldErr(errors, 'password')} />
                    <Form.Input fluid icon="lock" iconPosition="left" required
                        placeholder="Neues Passwort wiederholen" type="password"
                        name="pwc" autoComplete="confirm-password"
                        data-cy="password-confirmation"
                        error={fieldErr(errors, 'password_confirmation')} />
                    {isPresent(errors)?<Message error>{errors}</Message>:null}
                    <Button className="cuddle" fluid size="large" data-cy="change-password">
                        Ändern
                    </Button>
                </Form>
            </div>}
        </Segment>
    );
};

const ResetPasswordPage = ({}) => {
    const query = useQuery();
    const token = query.get("reset_password_token");
    return (
        <Grid textAlign='center' className="reset-password-form">
            <Grid.Column style={{ maxWidth: 450}}>
                {isPresent(token) ?
                    <SetNewPasswordView token={token} /> :
                    <SendInstructionsView />
                }
            </Grid.Column>
        </Grid>
    );
}

export default ResetPasswordPage;