import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router';
import { NavLink, useParams, Route, Switch, useHistory } from 'react-router-dom';
import { ConfirmButton, BackIcon, LogoSegment } from './ui';

import {
    Grid, Menu, Comment, Form, Button, Segment, Message, Input, TextArea, Icon,
    Popup, Visibility, Label, Loader, Responsive, Header, Transition,
} from 'semantic-ui-react';

import {
    isAuthed, getConversations, getMessages, getAuthUserId, getConversation,
    getConversationMessages, getSearchUsers, getUnreadChatCount, getProfile,
    getChatOnline,
} from '../selectors';

import {
    sendChatMessage, openChatConversation, closeChatConversation,
    createConversation, updateLastSeenMessage, fetchProfile, removeConversation,
    deleteChat
} from "../actions";

import {
    timeSince, DivRef, isPresent, isDefined,
} from "../helpers";

const makeConversationTitle = (conv, curUserId, link=false) => {
    const participants = conv.get('participants');
    let user = null;
    if(participants && participants.size > 1) {
        const otherParticipants = participants.filterNot(
            u => u.get('slug') === curUserId
        );
        user = otherParticipants.first();
    } else if(participants && participants.size > 0) {
        user = participants.first()
    }

    if (user === null) {
        // should not happen, as conversation without participants
        // won't be sent to current user
        return "Chat leer";
    } else {
        return (link?
            <NavLink to={`/profile/${user.get('slug')}`}>
                {user.get("nickname")}
            </NavLink>: user.get('nickname')
        );
    }
}

const ConversationList = ({className}) => {
    const conversations = useSelector(getConversations);
    const unreadCountMap = useSelector(getUnreadChatCount);
    const curUserId = useSelector(getAuthUserId);
    // TODO: empty list message
    return (
        <div {...{className}}>
            <Header attached="top" as="h3" className="chat-conv-header">
                Unterhaltungen
            </Header>
            <Menu vertical attached="top" className="chat-conv-list" fluid
                attached="bottom"
            >
                {conversations.map(conv => {
                    const unreadCount = unreadCountMap.get(conv.get('slug'), 0) || 0;
                    return (
                    <Menu.Item
                        as={NavLink}
                        key={conv.get('slug')}
                        to={`/chat/${conv.get('slug')}`}
                    >
                        {makeConversationTitle(conv, curUserId)}
                        {unreadCount > 0 ? <Label basic color="red">
                            {unreadCount}
                        </Label> : null}
                    </Menu.Item>
                    );
                })}
            </Menu>
        </div>
    );
};

const ChatMessage = ({message: msg, rightUser, cid}) => {
    const dispatch = useDispatch();
    const right = msg.get('author', rightUser) === rightUser;
    const undelivered = msg.get("undelivered", false);
    const inTransit = msg.has("transitKey") && !undelivered;
    return (
        <div
            className={["ui", "chat-message", right?"right":""].join(' ')}
            onClick={()=>undelivered && dispatch(sendChatMessage(
                msg.get("text"), {conversationId: cid}, msg.get("transitKey")))
            }
        >
            <div className="ui chat-text">
                {inTransit ? <Loader inline size="tiny" active/> : null}
                {msg.get('text')}
            </div>
            {undelivered ?
                <div className="ui chat-error">
                    <Icon name="warning circle"/> Möglicherweise
                    nicht zugestellt. Tippe, um erneut zu versenden.
                </div> :
                <div className="ui chat-meta">{timeSince(msg.get('created_at'))}</div>
            }
        </div>
    );
};

const ConversationEmptyContent = () => {
    return (
        <Message>
            <Message.Header>
                Wähle ein Gespräch aus
            </Message.Header>
            <p>
                Du kannst im linken Bereich ein Gespräch auswählen und
                weiter mit der Person schreiben oder ein neues Gespräch
                über das Profil der jeweiligen Person anfangen.
            </p>
        </Message>
    );
}

const ConversationHeader = ({conversationId}) => {
    const conversation = useSelector(state => getConversation(state, conversationId));
    const curUserId = useSelector(getAuthUserId);
    const dispatch = useDispatch();
    const history = useHistory()

    const onDelete = () => {
        dispatch(deleteChat(conversationId));
        dispatch(removeConversation(conversationId));
        history.push("/chat");
    }

    return (
        <Menu borderless fluid attached='top' className="chat-header">
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as={React.Fragment}>
                <Menu.Item as={BackIcon} to="/chat/" />
            </Responsive>
            <Menu.Item header as='h3'>
                {isDefined(conversation) ?
                    makeConversationTitle(conversation, curUserId, true) :
                    null
                }
            </Menu.Item>
            <Menu.Item className="right">
                <ConfirmButton icon="trash" basic color="red" title="Löschen" 
                onClick={onDelete}
                />
            </Menu.Item>
        </Menu>
    );
};

const ConversationRecipientInput = ({slug, onChange}) => {
    const user = useSelector(state => getProfile(state, slug));
    const dispatch = useDispatch();
    useEffect(() => {
        if(!isDefined(user)) {
            dispatch(fetchProfile(slug));
        }
    }, [user]);
    if(!isDefined(user)) return null;
    const name = user.get('nickname', 'Unbekannt');
    return (
        <Menu borderless fluid attached='top' className="chat-header">
            <Menu.Item header as='h3'>
                {name}
            </Menu.Item>
        </Menu>
    );
};

const ConversationStream = ({conversationId}) => {
    const commentRef = useRef(null);
    const messages = useSelector(state => getConversationMessages(state, conversationId));
    const userId = useSelector(getAuthUserId);
    const dispatch = useDispatch();
    const isOnline = useSelector(getChatOnline);

    useEffect(() => {
        const ref = commentRef.current;
        if (!ref) return;
        try {
            ref.scrollTo({top: ref.scrollHeight, behavior: 'smooth'});
        } catch (e) {
            ref.scrollTo(0, ref.scrollHeight);
        }
        const lastMessageId = messages && messages.last() && messages.last().get("slug");
        if (lastMessageId) {
            dispatch(updateLastSeenMessage(conversationId, lastMessageId));
        }
    }, [messages, commentRef]);

    return (
        <Segment as={DivRef} attached className="chat-content" forwardedRef={commentRef}>
            {messages.map((m, i) => (
                <ChatMessage
                    message={m}
                    rightUser={userId}
                    key={m.get('slug')}
                    index={i}
                    cid={conversationId}
                />
            ))}
            {isOnline ? null : <div className="chat-offline">
                <Icon name="warning" />
                Es besteht keine Verbindung zu Kuscheldate mehr.<br />
                Bitte habe ein wenig Geduld, bis die Verbindung zurückkehrt.
            </div>}
        </Segment>
    );
};

const ConversationMessageInput = ({action, actionParams={}}) => {
    const [text, setText] = useState("");
    const dispatch = useDispatch();
    const sendMessage = useCallback((msg, params) => {
        dispatch(action(msg, params));
        // IMPROVE: save until delivery confirmed
        setText("");
    }, []);
    const rows = Math.max(1, text.split(/\r\n|\r|\n/).length);

    return (
        <Form className="bottom attached chat-input-form"
            onSubmit={()=>sendMessage(text, actionParams)}
        >
            <Input action fluid>
                <TextArea rows={rows} value={text} onInput={(e)=>setText(e.target.value)} />
                <Button primary icon='send' type="submit" />
            </Input>
        </Form>
    );
};

const ConversationContent = ({className, slug: _slug}) => {
    const [slug, setSlug] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        if(isPresent(slug)){
            dispatch(openChatConversation(slug));
        }
        return () => {
            if(isPresent(slug)){
                dispatch(closeChatConversation(slug));
            }
        };
    }, [slug]);
    useEffect(() => {
        if(isPresent(_slug)) {
            setSlug(_slug);
        }
    }, [_slug]);

    if (!isPresent(slug)) return null;

    return (
        <Comment.Group minimal {...{className}}>
            <ConversationHeader conversationId={slug} />
            <ConversationStream conversationId={slug} />
            <ConversationMessageInput action={sendChatMessage}
                actionParams={{conversationId: slug}}
            />
        </Comment.Group>
    );
};

const NewChatContent = ({presetUserId}) => {
    const [recipient, setRecipient] = useState(presetUserId);
    useEffect(() => {
        setRecipient(presetUserId);
    }, [presetUserId]);
    return (
        <Comment.Group minimal>
            <ConversationRecipientInput
                slug={recipient}
                onChange={setRecipient}
            />
            <ConversationStream />
            <ConversationMessageInput
                action={createConversation}
                actionParams={{recipient}}
            />
        </Comment.Group>
    );
};

const MobileStackView = ({slug}) => {
    return (
        <div className="mobile-stack">
            <Route path="/chat/:slug"
                children={({match}) => (
                    <Transition visible={match !== null} animation="slide left">
                        <ConversationContent slug={match?.params?.slug} />
                    </Transition>
                )}
            />
            <Route path="/chat/" exact
                children={({match}) => (
                    <Transition visible={match !== null} animation="slide right">
                        <ConversationList />
                    </Transition>
                )}
            />
        </div>
    );
};

const ChatPage = () => {
    const authed = useSelector(isAuthed);
    const {slug} = useParams(); // conversation slug
    if (!authed) {
        return <Redirect to="/" />;
    }
    return (
        <>
            <LogoSegment />
            <Grid columns={2} className="chat-page">
                <Grid.Row stretched>
                    <Responsive
                        minWidth={Responsive.onlyTablet.minWidth}
                        as={Grid.Column}
                        width={5}
                    >
                        <ConversationList />
                    </Responsive>
                    <Responsive
                        minWidth={Responsive.onlyTablet.minWidth}
                        as={Grid.Column}
                        width={11}
                    >
                        <Switch>
                            <Route path="/chat/:slug"
                                render={({match: {params: {slug}}}) =>
                                    <ConversationContent slug={slug} />
                                }
                            />
                            <Route path="/chat/"
                                render={() => <ConversationEmptyContent />}
                            />
                        </Switch>
                    </Responsive>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}
                        as={Grid.Column}
                        width={16}
                    >
                        <MobileStackView slug={slug} />
                    </Responsive>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default ChatPage;

const NewChatPage = () => {
    const authed = useSelector(isAuthed);
    const {slug} = useParams(); // prefilled user slug
    if (!authed) {
        return <Redirect to="/" />;
    }
    return (
        <>
            <LogoSegment />
            <Grid columns={2} className="chat-page">
                <Grid.Row stretched>
                    <Responsive
                        minWidth={Responsive.onlyTablet.minWidth}
                        as={Grid.Column}
                        width={5}
                    >
                        <ConversationList />
                    </Responsive>
                    <Responsive
                        minWidth={Responsive.onlyTablet.minWidth}
                        as={Grid.Column}
                        width={11}
                    >
                        <NewChatContent presetUserId={slug} />
                    </Responsive>
                    <Responsive {...Responsive.onlyMobile}
                        as={Grid.Column}
                        width={16}
                    >
                        <NewChatContent presetUserId={slug} />
                    </Responsive>
                </Grid.Row>
            </Grid>
        </>
    );
};

export {NewChatPage};
