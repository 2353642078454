import React, {useEffect, useState, useContext, useRef,} from 'react';
import Immutable from 'immutable';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    DraftailEditor, ToolbarButton, BLOCK_TYPE, INLINE_STYLE, ENTITY_TYPE,
    serialiseEditorStateToRaw, createEditorStateFromRaw,
} from "draftail";
import { EditorState, AtomicBlockUtils, RichUtils, } from "draft-js";
import {
    sortableContainer, sortableElement, sortableHandle,
} from "react-sortable-hoc";

import {Quotation, LogoSegment} from "../ui";
import {
    Button, Icon, Ref, Sticky, Dropdown, Modal, Form, Dimmer,
} from "semantic-ui-react";

import { getPageEdit, } from '../../selectors';
import {
    addNewPageBlock, deletePageBlock, insertNewPageBelowBlock,
    insertNewPageAboveBlock, movePageBlock, setPageBlockContent,
    undeletePageBlock, setPageBlockProps,
} from '../../actions';
import {
    isDefined, isPresent, createEditorStateFromHTML, getButtonLabel,
    getButtonTitle,
} from '../../helpers';

import {REGULAR_MODE, EDITOR_BR_TYPE} from "../../constants";
import {Context as ViewModeContext} from "../../contexts/ViewMode";



const ToolbarDefaults = (props) => {
    const {
        inlineStyles, currentStyles, toggleInlineStyle,
        blockTypes, currentBlock, toggleBlockType,
        enableHorizontalRule, addHR,
        enableLineBreak, addBR,
        entityTypes, onRequestSource,
    } = props;
    return (<>
        <ToolbarGroup key="styles">
            {inlineStyles.map((t) => (
                <ToolbarButton
                    key={t.type}
                    name={t.type}
                    active={currentStyles.has(t.type)}
                    label={getButtonLabel(t.type, t)}
                    title={getButtonTitle(t.type, t)}
                    icon={t.icon}
                    onClick={toggleInlineStyle}
                />
            ))}
        </ToolbarGroup>
        <ToolbarGroup key="blocks">
            {blockTypes.map((t) => (
                <ToolbarButton
                    key={t.type}
                    name={t.type}
                    active={currentBlock === t.type}
                    label={getButtonLabel(t.type, t)}
                    title={getButtonTitle(t.type, t)}
                    icon={t.icon}
                    onClick={toggleBlockType}
                />
            ))}
        </ToolbarGroup>
        <ToolbarGroup key="hr-br">
            {enableHorizontalRule ? (
                <ToolbarButton
                    name={ENTITY_TYPE.HORIZONTAL_RULE}
                    onClick={addHR}
                    label={getButtonLabel(
                        ENTITY_TYPE.HORIZONTAL_RULE,
                        enableHorizontalRule,
                    )}
                    title={getButtonTitle(
                        ENTITY_TYPE.HORIZONTAL_RULE,
                        enableHorizontalRule,
                    )}
                    icon={
                        typeof enableHorizontalRule !== "boolean"
                        ? enableHorizontalRule.icon
                        : null
                    }
                />
            ) : null}
            {enableLineBreak ? (
                <ToolbarButton
                    name={EDITOR_BR_TYPE}
                    onClick={addBR}
                    label={getButtonLabel(EDITOR_BR_TYPE, enableLineBreak)}
                    title={getButtonTitle(EDITOR_BR_TYPE, enableLineBreak)}
                    icon={
                        typeof enableLineBreak !== "boolean" ? enableLineBreak.icon : null
                    }
                />
            ) : null}
        </ToolbarGroup>
        <ToolbarGroup key="entities">
            {entityTypes.map((t) => (
                <ToolbarButton
                    key={t.type}
                    name={t.type}
                    onClick={onRequestSource}
                    label={getButtonLabel(t.type, t)}
                    title={getButtonTitle(t.type, t)}
                    icon={t.icon}
                />
            ))}
        </ToolbarGroup>
    </>);
};

const ToolbarGroup = ({children}) => {
    const hasChildren = React.Children.toArray(children).some((c) => c !== null);
    return hasChildren ? (
        <div className="Draftail-ToolbarGroup">{children}</div>
    ) : null;
};

const CuddleToolbar = ({editorRef, ...props}) => {
    const {getEditorState, onChange, block, blockPath, controls} = props;
    const style={};
    if(editorRef.current) {
        style['width'] = editorRef.current.clientWidth;
        style['left'] = editorRef.current.getBoundingClientRect()['x'] || 0;
    }
    return (
        <Sticky className="sticky-toolbar" context={editorRef.current}
            offset={100} styleElement={style}>
            <div className="Draftail-Toolbar" role="toolbar">
                <ToolbarDefaults {...props} />
                <ToolbarGroup>
                    {controls.map((Control, i) => (
                        <Control
                            key={i}
                            getEditorState={getEditorState}
                            onChange={onChange}
                            block={block}
                            blockPath={blockPath}
                        />
                    ))}
                </ToolbarGroup>
            </div>
        </Sticky>
    );
};

export const EntityImage = (props) => {
    const {blockProps} = props;
    const [dimmed, setDimmed] = useState(false);
    const {entity, onRemoveEntity, onEditEntity, entityType} = blockProps;
    const {src, alt, width, height} = entity.getData();
    const el = (
        <img className="cd-image" src={src} alt={alt} title={alt} style={{
            width: width || 'auto',
            height: height || 'auto',
        }} />
    );
    return ((entityType && entityType.source)?
        <Dimmer.Dimmable dimmed={dimmed}
            onMouseEnter={() => setDimmed(true)} onMouseLeave={() => setDimmed(false)}
        >
            {el}
            <Dimmer active={dimmed}>
                <Button icon="edit" onClick={onEditEntity} basic inverted/>
                <Button icon="delete" onClick={onRemoveEntity} basic inverted negative/>
            </Dimmer>
        </Dimmer.Dimmable> : el
    );
};

const EntityImageSource = (props) => {
    const {
        onClose, onComplete, editorState, entityKey, entity, entityType,
    } = props;
    const [value, setValue] = useState(() => (entity && entity.getData()) || {
        src: "",
        alt: "",
        width: "auto",
        height: "auto",
    });
    const content = editorState.getCurrentContent();
    return (
        <Modal onClose={onClose} open={true}>
            <Modal.Header>Bild</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input label="URL" value={value.src}
                        onChange={(ev, data) => setValue({...value, src: data.value})}
                    />
                    <Form.Input label="Titel" value={value.alt}
                        onChange={(ev, data) => setValue({...value, alt: data.value})}
                    />
                    <Form.Input label="Breite" value={value.width}
                        onChange={(ev, data) => setValue({...value, width: data.value})}
                    />
                    <Form.Input label="Höhe" value={value.height}
                        onChange={(ev, data) => setValue({...value, height: data.value})}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Abbrechen</Button>
                <Button positive onClick={() => {
                    let nextState;
                    if (entity && entityKey) {
                        const nextContent = content.mergeEntityData(entityKey, value);
                        nextState = EditorState.push(editorState, nextContent, "apply-entity");
                    } else {
                        const contentWithEntity = content.createEntity(
                            entityType.type, "MUTABLE", value);
                        nextState = AtomicBlockUtils.insertAtomicBlock(
                            editorState,
                            contentWithEntity.getLastCreatedEntityKey(),
                            " "
                        );
                    }
                    onComplete(nextState);
                }}>
                    {(entity && entityKey)? "Ändern" : "Einfügen"}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const EXTERNAL_REGEX = new RegExp('([^:\/].*:\/\/)(.*)', 'i');
export const EntityLink = (props) => {
    const {entityKey, contentState, children, onEdit, onRemove} = props;
    const entity = contentState.getEntity(entityKey);
    let {href} = entity.getData();
    if (!isPresent(href)) {
        return children;
    }
    const external = href[0] !== '/';
    if (external) {
        if(!EXTERNAL_REGEX.test(href)) {
            href = `http://${href}`;
        }
        return (
            <a href={href} target="_blank" className="cd-link">
                {children}
            </a>
        );
    } else {
        return (
            <NavLink exact to={href} className="cd-link">
                {children}
            </NavLink>
        );
    }
};

const EntityLinkSource = (props) => {
    const {
        onClose, onComplete, editorState, entityKey, entity, entityType,
    } = props;
    let href = "";
    try {
        href = entity.getData().href;
    } catch (e){}
    const [value, setValue] = useState({
        href: href,
    });
    const content = editorState.getCurrentContent();
    return (
        <Modal onClose={onClose} open={true}>
            <Modal.Header>Link</Modal.Header>
            <Modal.Content>
                <p>
                    Bitte gebe die URL worauf der ausgewählte Text verlinken soll.
                    Um auf andere Kuscheldate-Seiten zu verlinken, sollte die relative
                    Darstellung beginnend mit einem "/" verwendet werden.
                </p>
                <Form>
                    <Form.Input label="URL" value={value.href}
                        onChange={(ev, data) => setValue(
                            {...value, href: data.value.replace(/\s/g, "")}
                        )}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Abbrechen</Button>
                <Button positive onClick={() => {
                    const contentWithEntity = content.createEntity(
                        entityType.type, "MUTABLE", value);
                    const nextState = RichUtils.toggleLink(
                        editorState,
                        editorState.getSelection(),
                        contentWithEntity.getLastCreatedEntityKey()
                    );
                    onComplete(nextState);
                }}>
                    Speichern
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const DragHandle = sortableHandle(
    () => <Icon className="drag-handle grip horizontal" />
);

const SortableVItem = sortableElement(
    (props) => <EditBlock {...props} />
);

const SortableVContainer = sortableContainer(
    ({children}) => <div>{children}</div>
);

const SortableHItem = sortableElement(
    (props) => <EditBlock {...props} />
);

const SortableHContainer = sortableContainer(
    ({children}) => <div className="cd-container">{children}</div>
);

const DeleteBlockControl = ({getEditorState, blockPath}) => {
    const dispatch = useDispatch();
    return (
        <ToolbarButton icon={<Icon name="trash" />}
            title="Ausgewählten Bereich löschen"
            onClick={() => dispatch(deletePageBlock(blockPath))}
        />
    );
};

const AddAboveBlockControl = ({getEditorState, blockPath}) => {
    const dispatch = useDispatch();
    return (
        <ToolbarButton icon={<Icon name="caret square up outline" />}
            title="Neuen Bereich vor Auswahl anlegen"
            onClick={() => dispatch(insertNewPageAboveBlock(blockPath))}
        />
    );
};

const AddBelowBlockControl = ({getEditorState, blockPath}) => {
    const dispatch = useDispatch();
    return (
        <ToolbarButton icon={<Icon name="caret square down outline" />}
            title="Neuen Bereich nach Auswahl anlegen"
            onClick={() => dispatch(insertNewPageBelowBlock(blockPath))}
        />
    );
};

const AddSubblockControl = ({getEditorState, blockPath}) => {
    const dispatch = useDispatch();
    return (blockPath.length === 1)?(
        <ToolbarButton icon={<Icon name="columns" />}
            title="Neuen Unterbereich anlegen"
            onClick={() => dispatch(addNewPageBlock(blockPath))}
        />
    ):null;
};

const STYLE_OPTIONS = [
    { key: 'st', text: "Transparent", value: 'cd-default'},
    { key: 'sl', text: "Hell", value: 'cd-lighter'},
    { key: 'sd', text: "Dunkel", value: 'cd-darker'},
];

const ALIGN_OPTIONS = [
    { key: 'al', text: "Linksbündig", value: 'DraftEditor-alignLeft'},
    { key: 'ac', text: "Zentriert", value: 'DraftEditor-alignCenter'},
    { key: 'ar', text: "Rechtsbündig", value: 'DraftEditor-alignRight'},
];

const BlockStyleControl = ({getEditorState, blockPath, block}) => {
    const ed = getEditorState();
    const dispatch = useDispatch();
    const classN = (block.get('class_names') || "").split(' ');
    const styleIndex = STYLE_OPTIONS.findIndex(x => classN.includes(x.value));
    const styleValue = styleIndex === -1 ? null : STYLE_OPTIONS[styleIndex].value;
    const alignIndex = ALIGN_OPTIONS.findIndex(x => classN.includes(x.value));
    const alignValue = alignIndex === -1 ? null : ALIGN_OPTIONS[alignIndex].value;
    const setClass = (value, options) => {
        let nameItems = classN.slice();
        const idx = nameItems.findIndex(x => options.findIndex(i=> i.value === x) !== -1);
        if (idx === -1) {
            nameItems.push(value);
        } else {
            nameItems[idx] = value;
        }
        const classString = nameItems.join(" ");
        dispatch(setPageBlockProps(blockPath, {class_names: classString}));
    };
    return (<>
        <Dropdown className='icon inverted' icon="paint brush"
            button basic compact
            options={STYLE_OPTIONS}
            value={styleValue}
            onChange={(ev, data) => {
                ev.preventDefault();
                setClass(data.value, STYLE_OPTIONS);
            }}
            onMouseDown={(ev, data) => {
                ev.preventDefault();
            }}
        />
        <Dropdown className='icon inverted' icon="align center"
            button basic compact
            options={ALIGN_OPTIONS}
            value={alignValue}
            onChange={(ev, data) => {
                ev.preventDefault();
                setClass(data.value, ALIGN_OPTIONS);
            }}
            onMouseDown={(ev, data) => {
                ev.preventDefault();
            }}
        />
        </>
    );
};

const HeaderIcon = ({level=1}) => {
    return (
        <Icon.Group className="pair">
            <Icon name="header" />
            <Icon>{level}</Icon>
        </Icon.Group>
    );
};

const EditSubblocks = ({subblocks, editorRef, blockPath, editingBlock, setEditingBlock}) => {
    const dispatch = useDispatch();
    const blocks = (subblocks || Immutable.List()).sortBy(
        sb => sb.get('order')
    );
    if (blocks.isEmpty()) return null;
    return (
        <SortableHContainer axis="x" lockAxis="x" useDragHandle
            onSortEnd={({oldIndex, newIndex}) =>
                dispatch(movePageBlock(oldIndex, newIndex, blockPath))
            }
        >
            {blocks.map((block, pos) => (
                <SortableHItem block={block} index={pos} pos={pos}
                    key={block.get('slug')} editorRef={editorRef} parentPath={blockPath}
                    editingBlock={editingBlock} setEditingBlock={setEditingBlock}
                />
            ))}
        </SortableHContainer>
    );
};

const EditBlock = ({block, editorRef, parentPath=[], editingBlock, setEditingBlock}) => {
    const [editorState, setEditorState] = useState(() => {
        const rawContent = block.get('body');
        if (typeof rawContent === "object") {
            return createEditorStateFromRaw(rawContent);
        } else if (typeof rawContent === "string") {
            return createEditorStateFromHTML(rawContent);
        }
        return null;
    });
    const dispatch = useDispatch();
    const slug = block.get('slug');
    const blockPath = parentPath.concat(slug);
    const blocks = block.get('blocks');
    const customClasses = block.get('class_names') || '';
    return (<div className={["cd-block", customClasses,
        ((editingBlock === slug)?'editing':"")].join(' ')}>
        <DragHandle />
        {(block.get('_delete') === true) ? (
            <div className="cd-actions">
                <Button icon='dont' compact color='red' title="Doch nicht löschen"
                    onClick={() => dispatch(undeletePageBlock(blockPath))}
                />
            </div>
        ) : (<>
            <div className="cd-text">
                <DraftailEditor
                    editorState={editorState}
                    topToolbar={(props) => <CuddleToolbar {...props}
                        editorRef={editorRef} block={block} blockPath={blockPath} />
                    }
                    enableHorizontalRule
                    enableLineBreak
                    spellCheck
                    maxListNesting={2}
                    onChange={(nextState) => {
                        setEditorState(nextState);
                        const content = serialiseEditorStateToRaw(nextState);
                        dispatch(setPageBlockContent(blockPath, content));
                    }}
                    onFocus={() => setEditingBlock(slug)}
                    blockTypes={[
                        { type: BLOCK_TYPE.UNSTYLED, icon: <Icon name="paragraph" />},
                        { type: BLOCK_TYPE.HEADER_ONE, icon: <HeaderIcon level={1} />},
                        { type: BLOCK_TYPE.HEADER_TWO, icon: <HeaderIcon level={2} />},
                        { type: BLOCK_TYPE.HEADER_THREE, icon: <HeaderIcon level={3} />},
                        { type: BLOCK_TYPE.HEADER_FOUR, icon: <HeaderIcon level={4} />},
                        { type: BLOCK_TYPE.HEADER_FIVE, icon: <HeaderIcon level={5} />},
                        { type: BLOCK_TYPE.HEADER_SIX, icon: <HeaderIcon level={6} />},
                        { type: BLOCK_TYPE.UNORDERED_LIST_ITEM, icon: <Icon name="list ul" /> },
                        { type: BLOCK_TYPE.ORDERED_LIST_ITEM, icon: <Icon name="list ol" /> },
                        { type: BLOCK_TYPE.BLOCKQUOTE, icon: <Icon name="quote right" /> },
                        { type: BLOCK_TYPE.CODE, icon: <Icon name="code" /> },
                    ]}
                    inlineStyles={[
                        { type: INLINE_STYLE.BOLD, icon: <Icon name="bold" /> },
                        { type: INLINE_STYLE.ITALIC, icon: <Icon name="italic" /> },
                        { type: INLINE_STYLE.UNDERLINE, icon: <Icon name="underline" /> },
                        { type: INLINE_STYLE.STRIKETHROUGH, icon: <Icon name="strikethrough" /> },
                    ]}
                    entityTypes={[
                        { type: ENTITY_TYPE.IMAGE, block: EntityImage,
                            source: EntityImageSource, icon: <Icon name="image" />,
                            attributes: ['src', 'alt', 'width', 'height'], description: "Bild",
                        },
                        { type: ENTITY_TYPE.LINK, decorator: EntityLink,
                            source: EntityLinkSource, icon: <Icon name="linkify" />,
                            attributes: ['href'], description: "Link",
                        }
                    ]}
                    controls={[
                        DeleteBlockControl, AddAboveBlockControl,
                        AddBelowBlockControl, AddSubblockControl,
                        BlockStyleControl,
                    ]}
                />
            </div>
            <EditSubblocks subblocks={blocks} editorRef={editorRef} blockPath={blockPath}
                editingBlock={editingBlock} setEditingBlock={setEditingBlock}
            />
        </>)}
    </div>);
};

const NewBlock = () => {
    const dispatch = useDispatch();
    return (
        <div className="cd-block">
            <div className="cd-actions">
                <Button icon="plus" basic color="green"
                    title="Neuen Bereich anlegen"
                    onClick={() => dispatch(addNewPageBlock())}
                />
            </div>
        </div>
    );
};

const NEW_BLOCK = Immutable.fromJS({
    slug: "_new",
    body: "",
});

const ContentEditor = () => {
    //const match = useRouteMatch("/admin/pages/:slug");
    //const params = match && match.params["slug"];
    const editorRef = useRef(null);
    const pageEdit = useSelector(getPageEdit);
    const [editingBlock, setEditingBlock] = useState(null)
    const page = pageEdit.get('page');
    const blocks = page.get('blocks');
    const dispatch = useDispatch();
    const {setMode} = useContext(ViewModeContext);
    const editDone = pageEdit.get('done');
    useEffect(() => {
        if(editDone) {
            setMode(REGULAR_MODE);
        }
    }, [pageEdit]);
    useEffect(() => {
        if(page && page.get) {
            const top_image_url = page.get('top_image_url');
            const bottom_image_url = page.get('bottom_image_url');
            const el = document.querySelector('.bgPane');
            let bgs = el.style.backgroundImage.split(',');
            if(isPresent(top_image_url)) {
                bgs[0] = `url(${top_image_url})`;
            }
            if(isPresent(bottom_image_url)) {
                bgs[1] = `url(${bottom_image_url})`;
            }
            el.style.backgroundImage = bgs.join(',');
        }
    }, [page]);
    if(editDone) return null;
    return (
        <>
            <LogoSegment />
            <Ref innerRef={editorRef}>
                <div className="page-edit-segment">
                    <SortableVContainer axis="y" lockAxis="y" useDragHandle
                        useWindowAsScrollContainer
                        onSortEnd={({oldIndex, newIndex}) =>
                            dispatch(movePageBlock(oldIndex, newIndex))
                        }
                    >
                        {blocks.size > 0 ? (
                            blocks.map((block, pos) => (
                                <SortableVItem block={block} index={pos} pos={pos}
                                    key={block.get('slug')} editorRef={editorRef}
                                    editingBlock={editingBlock} setEditingBlock={setEditingBlock}
                                />
                            ))
                        ) : (
                            <NewBlock />
                        )}
                    </SortableVContainer>
                </div>
            </Ref>
            <Quotation />
        </>
    );
};

export default ContentEditor;