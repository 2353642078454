import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {
    Menu, Dimmer, Form, Button, Message, Input, Popup, Header,
} from "semantic-ui-react";

import {getPageEdit,} from "../../selectors";
import {
    cancelPageEdit, savePageEdit, clearPageEditError, setPageEditPage,
} from "../../actions";
import { isPresent, } from "../../helpers";
import { VISIBILITY_OPTIONS } from "../../constants";
import ImageUpload from '../ui/ImageUpload';

const AdminPageMenuItems = ({}) => {
    const dispatch = useDispatch();
    const pageEdit = useSelector(getPageEdit);
    const saving = pageEdit.get('saving') || false;
    const errorMsg = pageEdit.get('error') || "";
    const page = pageEdit.get('page');
    return (
        <>
            <Dimmer active={isPresent(errorMsg)}>
                <div className="ui inverted red text">
                    Ups! Es ist ein Fehler aufgetreten: {errorMsg} {'\u00A0'}
                    <Button icon='close' basic compact circular inverted
                        onClick={() => dispatch(clearPageEditError())}
                    />
                </div>
            </Dimmer>
            <Menu.Item fitted>
                <Form inverted size="small">
                    <Form.Field control={Input} id="page-title"
                        label="Titel" inline required value={page.get('title')}
                        onChange={(ev, data) => dispatch(
                            setPageEditPage(page.set('title', data.value))
                        )}
                    />
                </Form>
            </Menu.Item>
            <Menu.Item fitted>
                <Form inverted size="small">
                    <Form.Select id="page-access" scrolling
                        label="Zugriff" inline required value={page.get('authorized_role')}
                        options={Object.values(VISIBILITY_OPTIONS)}
                        onChange={(ev, data) => dispatch(
                            setPageEditPage(page.set('authorized_role', data.value))
                        )}
                    />
                </Form>
            </Menu.Item>
            <Menu.Item fitted>
                <Popup trigger={<Button icon="images outline" inverted basic
                        title="Hintergrundbilder"/>}
                    on="click"
                    position="bottom center"
                    flowing
                >
                    <Header as="h2">Hintergrundbilder</Header>
                    <div style={{
                        display: 'inline-block',
                        width:"200px",
                        position: 'relative',
                    }}>
                        <ImageUpload withForm={false}
                            onChange={(imgIds) => imgIds && imgIds[0] && dispatch(
                                setPageEditPage(page.set('top_image', imgIds[0]))
                            )}
                            onPreview={(url) => url && dispatch(
                                setPageEditPage(page.set('top_image_url', url))
                            )}
                        >
                            <Button as="a" className="upload image-upload" style={{
                                backgroundImage: `url(${page.get('top_image_url')})`
                            }}>
                                <div className="text">Hintergrund oben</div>
                            </Button>
                        </ImageUpload>
                    </div>
                    <div style={{
                        display: 'inline-block',
                        width:'200px',
                        marginLeft:'0.5em',
                        position: 'relative',
                    }}>
                        <ImageUpload withForm={false}
                            onChange={(imgIds) => imgIds && imgIds[0] && dispatch(
                                setPageEditPage(page.set('bottom_image', imgIds[0]))
                            )}
                            onPreview={(url) => url && dispatch(
                                setPageEditPage(page.set('bottom_image_url', url))
                            )}
                        >
                            <Button as="a" className="upload image-upload" style={{
                                backgroundImage: `url(${page.get('bottom_image_url')})`
                            }}>
                                <div className="text">Hintergrund unten</div>
                            </Button>
                        </ImageUpload>
                    </div>
                </Popup>
            </Menu.Item>
            <Menu.Item fitted>
                <Button inverted className="cuddle"
                    onClick={() => !saving && dispatch(savePageEdit())}
                    loading={saving}
                >
                    Speichern
                </Button>
            </Menu.Item>
            <Menu.Item fitted>
                <Button inverted
                    onClick={() => !saving && dispatch(cancelPageEdit())}
                    disabled={saving}
                >
                    Abbrechen
                </Button>
            </Menu.Item>
        </>
    )
};

export default AdminPageMenuItems;