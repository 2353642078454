import React from 'react';
import {useDispatch} from 'react-redux'
import {Modal, Button} from 'semantic-ui-react';

import {isPresent} from "../../helpers";

const ModalDialog = ({title, onClose, actions, children: content}) => {
    const defActionComps = [
        <Button className="cuddle" onClick={onClose}>OK</Button>,
    ];
    let actionComps = defActionComps;
    const dispatch = useDispatch();
    if (actions && actions.size > 0) {
        actionComps = actions.map(a => (
            <Button className="cuddle" onClick={() => {
                const aDesc = a.get(1);
                onClose && onClose();
                aDesc && (aDesc !== -1) && dispatch(aDesc.toJS());
            }}
            >
                {a.get(0)}
            </Button>
        ));
    }
    return (
        <Modal defaultOpen onClose={onClose} size='tiny'>
            {isPresent(title)?<Modal.Header>{title}</Modal.Header>:null}
            <Modal.Content>
                <Modal.Description>
                    {content}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {actionComps}
            </Modal.Actions>
        </Modal>
    );
};

export default ModalDialog;
